// third-party import
import moment from "moment";

// project-import
import __posts from "./jsons/__posts.json";
import __posts_news from "./jsons/__posts_news.json";
import __postsGallery from "./jsons/__postsGallery.json";
import __postsVideo from "./jsons/__postsVideo.json";
import __postsAudio from "./jsons/__postsAudio.json";
import { DEMO_CATEGORIES, DEMO_CATEGORIES_V1 } from "./taxonomies";
import { PostDataType } from "./types";
import { DEMO_AUTHORS, DEMO_AUTHORS_V1 } from "./authors";
import { readingTime } from "utils/post";

// FOR MAIN DEMO
const DEMO_POSTS = (__posts || []).map((post, index): PostDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const categories = (post?.categoriesId || []).map(
    (id) => DEMO_CATEGORIES.filter((taxonomy) => taxonomy.id === id)[0]
  );

  return {
    ...post,
    id: `DEMO_POSTS_${index + 1}`,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    categories: [categories[0]],
  } as PostDataType;
});

// FOR MAIN DEMO
const DEMO_POSTS_NEWS = (__posts_news || []).map((post, index): PostDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const categories = (post?.categoriesId || []).map(
    (id) => DEMO_CATEGORIES_V1.filter((taxonomy) => taxonomy.id === id)[0]
  );

  return {
    ...post,
    id: `DEMO_POSTS_NEWS_${index + 1}`,
    author: DEMO_AUTHORS_V1.filter((user) => user.id === post.authorId)[0],
    categories: [categories[0]],
  } as PostDataType;
});

// FOR POST TYPE GALLERY
const DEMO_POSTS_GALLERY = (__postsGallery || []).map((post, index): PostDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const categories = (post?.categoriesId || []).map(
    (id) => DEMO_CATEGORIES.filter((taxonomy) => taxonomy.id === id)[0]
  );

  return {
    ...post,
    id: `DEMO_POSTS_GALLERY_${index + 1}`,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    categories: [categories[0]],
  } as PostDataType;
});

// FOR POST TYPE VIDEO
const DEMO_POSTS_VIDEO = (__postsVideo || []).map((post, index): PostDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const categories = (post?.categoriesId || []).map(
    (id) => DEMO_CATEGORIES.filter((taxonomy) => taxonomy.id === id)[0]
  );

  return {
    ...post,
    id: `DEMO_POSTS_VIDEO_${index + 1}`,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    categories: [categories[0]],
  } as PostDataType;
});

// FOR POST TYPE AUDIO
const DEMO_POSTS_AUDIO = (__postsAudio || []).map((post, index): PostDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const categories = (post?.categoriesId || []).map(
    (id) => DEMO_CATEGORIES.filter((taxonomy) => taxonomy.id === id)[0]
  );

  return {
    ...post,
    id: `DEMO_POSTS_AUDIO_${index + 1}`,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    categories: [categories[0]],
  } as PostDataType;
});

const DEMO_POSTS_MAIN = (posts: any[], categories: any[], authors: any[]) => {
  try {
    return (posts || []).map((post, index): PostDataType => {
      //  ##########  GET CATEGORY BY CAT ID ######## //
      const filteredCategories = (post?.categoriesId || []).map(
        (id: any) => categories.filter((taxonomy: any) => taxonomy.id === id)[0]
      );

      return {
        ...post,
        id: `${post.id}`,
        author: authors.filter((user) => user.id === post.authorId)[0],
        date: moment(post.date).format("ll"),
        readingTime: readingTime(post?.desc),
        categories: filteredCategories && filteredCategories.length && filteredCategories[0] ? [filteredCategories[0]] : [],
      } as PostDataType;
    });
  } catch (error) {
    throw error;
  }
}

export {
  DEMO_POSTS,
  DEMO_POSTS_AUDIO,
  DEMO_POSTS_GALLERY,
  DEMO_POSTS_VIDEO,
  DEMO_POSTS_NEWS,
  DEMO_POSTS_MAIN
};
