import React from "react";

// project-import	
import Link from "components/Link";
import { GoogleAnalyticsEnum, triggerFunction } from "utils/googleAnalytics";

// assets
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";
import LogoSvg from "./LogoSvg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
}) => {
  return (
    <Link
      href="/"
      className="ttnc-logo inline-block text-primary-6000 flex-shrink-0"
      onClick={() =>
        triggerFunction(
          GoogleAnalyticsEnum.BUTTON,
          GoogleAnalyticsEnum.HEADER_LOGO
        )
      }
    >
      <LogoSvg />
    </Link>
  );
};

export default Logo;
