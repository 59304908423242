import React, { FC } from "react";

// project-import
import Heading from "components/Heading/Heading";
import { PostDataType } from "data/types";
import Card11 from "components/Card11/Card11";
import Card9 from "components/Card9/Card9";
import { DEMO_POSTS_MAIN } from "data/posts";
import { Route } from "routers/types";
import { useCategoryContext } from "context/categoryContext";
import { useAuthorContext } from "context/authorContext";
import { usePostContext } from "context/postContext";
import LoadingVideo from "components/LoadingVideo/LoadingVideo";
import Card11Skeleton from "components/Skeleton/Card11Skeleton";
import Card9Skeleton from "components/Skeleton/Card9Skeleton";

// third-party
import { useTranslation } from "react-i18next";

export interface SingleRelatedPostsProps {
  relatedPosts?: PostDataType[];
  moreFromAuthorPosts?: PostDataType[];
}

const SingleRelatedPosts: FC<SingleRelatedPostsProps> = ({
  relatedPosts = [],
  moreFromAuthorPosts = [],
}) => {
  const {t} = useTranslation();
  // read store state
  const { isLoading: isCatLoading, categories } = useCategoryContext();
  const { isLoading: isAuthorLoading, authors } = useAuthorContext();
  const { isLoading: isPostLoading, posts } = usePostContext();
  const isLoading = isCatLoading || isAuthorLoading || isPostLoading;
  const allPosts = DEMO_POSTS_MAIN(posts || [], categories || [], authors || []);

  // CONSTRUCT DATA

  let demoRelated: PostDataType[] = relatedPosts;
  if (demoRelated && !demoRelated.length) {
    demoRelated = allPosts.filter(
      (_, i) => i >= 0 && i < 4
    );
    // make differnt href demo, for user can click
    demoRelated = demoRelated.map((item, index) => ({
      ...item,
      href: (item.href + index) as Route,
    }));
  }

  let demoMoreFromAuthor: PostDataType[] = moreFromAuthorPosts;
  if (demoMoreFromAuthor && !demoMoreFromAuthor.length) {
    demoMoreFromAuthor = allPosts.filter(
      (_, i) => i >= 4 && i < 8
    );
    // make differnt href demo, for user can click
    demoMoreFromAuthor = demoMoreFromAuthor.map((item, index) => ({
      ...item,
      href: (item.href + index + "-") as Route,
    }));
  }

  return (
    <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-28">
      {/* RELATED  */}
      <div className="container">
        {isLoading &&
          <LoadingVideo />
        }
        <div>
          <Heading
            className="mb-10 text-neutral-900 dark:text-neutral-50"
            desc=""
          >
            {t("related_posts")}
          </Heading>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {demoRelated.map((post) => (
              <>
                {isLoading ? <Card11Skeleton /> :
                  <Card11 key={post.id} post={post} />}
              </>
            ))}
          </div>
        </div>

        {/* MORE FROM AUTHOR */}
        <div className="mt-20">
          <Heading
            className="mb-10 text-neutral-900 dark:text-neutral-50"
            desc=""
          >
           {t("more_from_author")}
          </Heading>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {demoMoreFromAuthor.map((post) => (
              <>
                {isLoading ? <Card9Skeleton /> :
                  <Card9 key={post.id} post={post} />
                }
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleRelatedPosts;
