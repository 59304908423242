// project-imports
import { FAILURE, SUCCESS } from "constants/utils";
import { ActionType } from "../constants/actions";

export const initialState = {
  isLoading: false,
  error: null,
  currentMember: {},
  newMember: {},
  status: null,
  loginType: null,
};

const memberReducer = (
  state = initialState,
  action: { type: ActionType; payload: any }
) => {
  if (action.type === ActionType.GET_MEMBER_LOGIN_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      currentMember: {},
      error: null
    };
  }
  if (action.type === ActionType.GET_MEMBER_LOGIN_SUCCESS) {
    localStorage.setItem(
      process.env.REACT_APP_USER_LOCALSTORAGE_KEY as string,
      action.payload.response.token
    );

    return {
      ...state,
      isLoading: false,
      currentMember: action.payload.response,
      loginType: action.payload.type,
      error: null
    };
  }
  if (action.type === ActionType.GET_MEMBER_LOGIN_FAILURE) {
    localStorage.removeItem(
      process.env.REACT_APP_USER_LOCALSTORAGE_KEY as string
    );

    return {
      ...state,
      isLoading: false,
      error: action.payload
    };
  }
  if (action.type === ActionType.GET_MEMBER_REGISTER_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      newMember: {},
      error: null
    };
  }
  if (action.type === ActionType.GET_MEMBER_REGISTER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      newMember: action.payload,
      error: null
    };
  }
  if (action.type === ActionType.GET_MEMBER_REGISTER_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload
    };
  }
  if (action.type === ActionType.GET_MEMBER_LOGOUT_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null
    };
  }
  if (action.type === ActionType.GET_MEMBER_LOGOUT_SUCCESS) {
    localStorage.removeItem(
      process.env.REACT_APP_USER_LOCALSTORAGE_KEY as string
    );
    return {
      ...state,
      isLoading: false,
      currentMember: null,
      error: null
    };
  }
  if (action.type === ActionType.GET_MEMBER_LOGOUT_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload
    };
  }
  if (action.type === ActionType.CHECK_MEMBER_LOGIN_STATUS_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null
    };
  }
  if (action.type === ActionType.CHECK_MEMBER_LOGIN_STATUS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      currentMember: { ...action.payload.response },
      loginType: action.payload.type,
      error: null
    };
  }
  if (action.type === ActionType.CHECK_MEMBER_LOGIN_STATUS_FAILURE) {
    localStorage.removeItem(
      process.env.REACT_APP_USER_LOCALSTORAGE_KEY as string
    );
    return {
      ...state,
      isLoading: false,
      currentMember: null,
      error: action.payload
    };
  }
  if (action.type === ActionType.MEMBER_FORGOT_PASSWORD_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
      status: null,
    };
  }
  if (action.type === ActionType.MEMBER_FORGOT_PASSWORD_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      currentMember: null,
      error: null,
      status: SUCCESS
    };
  }
  if (action.type === ActionType.RESET_FORGOT_PASSWORD_STATUS) {
    return {
      ...state,
      status: null
    };
  }
  if (action.type === ActionType.MEMBER_FORGOT_PASSWORD_FAILURE) {
    return {
      ...state,
      isLoading: false,
      currentMember: null,
      error: action.payload,
      status: FAILURE
    };
  }
  if (action.type === ActionType.MEMBER_RESET_PASSWORD_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
      status: null,
    };
  }
  if (action.type === ActionType.MEMBER_RESET_PASSWORD_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      currentMember: null,
      error: null,
      status: SUCCESS
    };
  }
  if (action.type === ActionType.MEMBER_RESET_PASSWORD_FAILURE) {
    return {
      ...state,
      isLoading: false,
      currentMember: null,
      error: action.payload,
      status: FAILURE
    };
  }
  return state;
};

export default memberReducer;
