import React, { useContext, useEffect, createContext, ReactNode } from 'react';

// third-party
import { useDispatch, useSelector } from "react-redux";

// project-imports
import { ActionType } from '../constants/actions';
import { fetchCategories } from 'api/api';
import { showToastAlert } from 'utils/sweetAlert';

// Define the shape of the category state
interface CategoryState {
    isLoading: boolean;
    categories: any[]; // Replace `any[]` with your actual category type
    error: string | null;
}

// Define the shape of the context value
interface CategoryContextType extends CategoryState {
    getAllCategories: (domain?: string) => Promise<void>;
}

// Define props for the provider
interface CategoryProviderProps {
    children: ReactNode;
    store: any
}

// Initialize the context
export const CategoryContext = createContext<CategoryContextType | undefined>(undefined);

export const CategoriesProvider: React.FC<CategoryProviderProps> = ({ children }) => {
    const { GET_CATEGORY_BEGIN, GET_CATEGORY_SUCCESS, GET_CATEGORY_FAILURE } = ActionType;
    // Reading state from store
    const state = useSelector((state: { category: CategoryState }) => state.category);

    // Reading dispatch from Store
    const dispatch = useDispatch();

    const getAllCategories = async (domain?: string): Promise<void> => {
        dispatch({ type: GET_CATEGORY_BEGIN, payload: true });

        try {
            let response: any[] = []; // Replace with the actual API response type
            response = await fetchCategories("") || [];
            dispatch({ type: GET_CATEGORY_SUCCESS, payload: response });
            // showToastAlert('Success', 'Category data fetched successfully!', { icon: "success" });
        } catch (error: any) {
            console.error("Error fetching categories:", error);
            dispatch({ type: GET_CATEGORY_FAILURE, payload: error?.message });
            showToastAlert('Error', 'filded to fetch category.Please try again later.', { icon: "error" });
        }
    };

    useEffect(() => {
        getAllCategories();
    }, []);

    return (
        <CategoryContext.Provider value={{ ...state, getAllCategories }
        }>
            {children}
        </CategoryContext.Provider>
    );
};

// Custom hook for using the context
export const useCategoryContext = (): CategoryContextType => {
    const context = useContext(CategoryContext);
    if (!context) {
        throw new Error("useCategoriesContext must be used within a CategoriesProvider");
    }
    return context;
};
