import React, { FC } from "react";

//project imports
import TagsSkeleton from "./TagsSkeleton";
import SingleCommentFormSkeleton from "./SingleCommentFormSkeleton";
import CommentCardSkeleton from "./CommentCardSkeleton";
import SingleContentDemoSkeleton from "./SingleContentDemoSkeleton";
import SingleAuthor from "app/(singles)/SingleAuthor";

export interface SkeletonProps {
    className?: string;
}

const SingleContentSkeleton: FC<SkeletonProps> = ({ className = "" }) => {
    return (
        <div className="relative animate-pulse">
            <div className="nc-SingleContent space-y-10">
                {/* ENTRY CONTENT */}
                <div
                    id="single-entry-content"
                    className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
                >
                    <SingleContentDemoSkeleton />
                </div>

                {/* TAGS */}
                <div className="max-w-screen-md mx-auto flex flex-wrap">
                    {[1, 2, 3, 4, 5, 6, 7, 6, 7, 8, 9, 10].map((item) => (
                        <TagsSkeleton className="mr-2 mb-2" />
                    ))}
                </div>

                {/* AUTHOR */}
                <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
                <div className="max-w-screen-md mx-auto ">
                    <SingleAuthor />
                </div>

                {/* COMMENT FORM */}
                <div
                    id="comments"
                    className="scroll-mt-20 max-w-screen-md mx-auto pt-5"
                >
                    <h3 className="h-20 w-64 bg-slate-200 rounded-md">
                    </h3>
                    <SingleCommentFormSkeleton />
                </div>

                {/* COMMENTS LIST */}
                <div className="max-w-screen-md mx-auto">
                    <ul className="nc-SingleCommentLists space-y-5">
                        {[1, 2, 3, 4].map((_, index) => (
                            <CommentCardSkeleton key={index} />
                        ))}

                        <div className="w-full h-20 bg-slate-200 rounded-md"></div>
                    </ul>
                </div>
            </div>
            {/* scroll top */}
            {/* <div
                className={`sticky mt-8 bottom-8 z-40 justify-center ${showLikeAndCommentSticky ? "flex" : "hidden"
                    }`}
            >
                <div className="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1.5 flex items-center justify-center space-x-2 text-xs">
                    <PostCardLikeAction
                        className="px-3 h-9 text-xs"
                        likeCount={totalLikes}
                        liked={isLiked}
                        isLoading={isLoading}
                        isDisabled={isLikeDisabled}
                        handleLikeClick={handleLikeBtnClick} />

                    <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>
                    <PostCardCommentBtn
                        isATagOnSingle
                        className={` flex px-3 h-9 text-xs`}
                    />
                    <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>

                    <button
                        className={`w-9 h-9 items-center justify-center bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-100 rounded-full ${isShowScrollToTop ? "flex" : "hidden"
                            }`}
                        onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                    >
                        <ArrowUpIcon className="w-4 h-4" />
                    </button>

                    <button
                        ref={progressRef}
                        className={`w-9 h-9 items-center justify-center ${isShowScrollToTop ? "hidden" : "flex"
                            }`}
                        title="Go to top"
                    >
                        %
                    </button>
                </div>
            </div> */}
        </div>
    );
};

export default SingleContentSkeleton;
