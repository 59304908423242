import axios, { AxiosResponse } from 'axios';

// project-imports
import { ContactEnquiry } from 'context/enquiryContext';
import { NewsletterSubscription } from 'context/newsletterContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

// Define the structure of the API responses
interface ApiResponse<T> {
    message: string;
    data: T;
}

// Define the Post, Category, and Author interfaces
// interface Post {
//     id: number;
//     title: string;
//     content: string;
// }

interface Category {
    id: number;
    name: string;
    href: string;
    thumbnail: string;
    count: number;
    color: string;
}

interface Author {
    id: number;
    firstName: string;
    lastName: string;
    displayName: string;
    email: string;
    avatar: string;
}

interface Enquiry {
    id: string;
    name: string;
    email: string;
    message: string;
    createdAt: string;
    updatedAt: string;
}

interface Newsletter {
    id: string;
    email: string;
    subscribedAt: string;
    createdAt: string;
    updatedAt: string;
}

export interface LoginRequest {
    email: string;
    password: string;
}

export interface LoginResponse {
    id: string;
    fullName: string;
    email: string;
}

export interface RegisterRequest {
    fullName: string;
    email: string;
    password: string;
}

export interface RegisterResponse {
    id: string;
    fullName: string;
    email: string;
}

export interface ForgotPasswordRequest {
    email: string;
}

export interface ResetPasswordRequest {
    token: string;
    newPassword: string;
}

export interface ResetPasswordValidationRequest {
    newPassword: string;
    confirmNewPassword: string;
}

// Function to call the login API
export const login = async (user: LoginRequest): Promise<LoginResponse | null> => {
    try {
        const { email, password } = user;
        const response: AxiosResponse<LoginResponse> = await axios.post(
            `${API_BASE_URL}/authorized-users/login`,
            { email, password },
            {
                headers: { "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data ?? null; // Return token or null if undefined
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};

export const googleLogin = async (payload: any): Promise<LoginResponse | null> => {
    try {
        const response: AxiosResponse<LoginResponse> = await axios.post(
            `${API_BASE_URL}/authorized-users/google-login`,
            { ...payload },
            {
                headers: { "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data ?? null; // Return token or null if undefined
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};

// Function to call the register API
export const register = async (userData: RegisterRequest): Promise<RegisterResponse | null> => {
    try {
        const response: AxiosResponse<RegisterResponse> = await axios.post(
            `${API_BASE_URL}/authorized-users/register`,
            userData,
            {
                headers: { "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );

        // Return new member data or null
        return response.data ?? null;
    } catch (error) {
        console.error('Error during registration:', error);
        throw error;
    }
};

export const getCheckAuthStatus = async () => {
    try {
        const response: AxiosResponse<LoginResponse> = await axios.get(
            `${API_BASE_URL}/authorized-users/check-login-status`, {
            withCredentials: true,
            headers: { "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
        }
        );
        // Return new member data or null
        return response.data ?? null;
    } catch (error) {
        console.error('Error during checking  auth status:', error);
        throw error;
    }
};

export const postForgotPassword = async (payload: ForgotPasswordRequest) => {
    try {
        const response: AxiosResponse<string> = await axios.post(
            `${API_BASE_URL}/authorized-users/forgot-password`, payload, {
            headers: { "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
        }
        );

        // Return forgot password data or null
        return response.data ?? null;
    } catch (error) {
        console.error('Error during checking  auth status:', error);
        throw error;
    }
};

export const postResetPassword = async (payload: ResetPasswordRequest) => {
    try {
        const response: AxiosResponse<string> = await axios.post(
            `${API_BASE_URL}/authorized-users/reset-password`, payload, {
            headers: { "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
        }
        );

        // Return reset password data or null
        return response.data ?? null;
    } catch (error) {
        console.error('Error during checking  auth status:', error);
        throw error;
    }
};

// Function to fetch categories (requires token for authentication)
export const fetchCategories = async (token: string): Promise<Category[] | null> => {
    try {
        const response: AxiosResponse<Category[]> = await axios.get(
            `${API_BASE_URL}/category`,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );

        return response.data ?? null; // Return categories or null if undefined
    } catch (error) {
        console.error('Error fetching categories:', error);
        return null;
    }
};

// Function to fetch authors (requires token for authentication)
export const fetchAuthors = async (token: string): Promise<Author[] | null> => {
    try {
        const response: AxiosResponse<Author[]> = await axios.get(
            `${API_BASE_URL}/author`,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data ?? null; // Return authors or null if undefined
    } catch (error) {
        console.error('Error fetching authors:', error);
        return null;
    }
};

// Function to create enquiry (requires token for authentication)
export const createEnquiry = async (token: string, payload: ContactEnquiry): Promise<Enquiry | null> => {
    try {
        const response: AxiosResponse<ApiResponse<Enquiry>> = await axios.post(
            `${API_BASE_URL}/enquiry`,
            payload,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data?.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error submitting enquiry:', error);
        throw error;
    }
};

// Function to subscribe to newsletter
export const subscribeNewsletter = async (token: string, payload: NewsletterSubscription): Promise<Newsletter | null> => {
    try {
        const response: AxiosResponse<ApiResponse<Newsletter>> = await axios.post(
            `${API_BASE_URL}/newsletter-subscribe`,
            payload,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data?.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error subscribing to newsletter:', error);
        throw error;
    }
};

// Function to unsubscribe to newsletter
export const unsubscribeNewsletter = async (token: string, payload: NewsletterSubscription): Promise<Newsletter | null> => {
    try {
        const response: AxiosResponse<ApiResponse<Newsletter>> = await axios.post(
            `${API_BASE_URL}/newsletter-unsubscribe`,
            payload,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data?.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error unsubscribing to newsletter:', error);
        throw error;
    }
};