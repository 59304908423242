
// third-party
import axios, { AxiosResponse } from 'axios';

// project-imports
import { Post, PostBookmark, PostLike, PostComment, PostReport } from 'context/postContext';
import { PostLikeDataType, PostReportDataType } from 'data/types';

axios.defaults.withCredentials = true;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Define the structure of the API responses
interface ApiResponse<T> {
    message: string;
    data: T;
}

// Function to fetch posts (requires token for authentication)
export const fetchPosts = async (token: string, language: string): Promise<Post[] | null> => {
    try {
        const response: AxiosResponse<Post[]> = await axios.get(
            `${API_BASE_URL}/post?language=${language}`,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error fetching posts:', error);
        return null;
    }
};

// Function to add like to post (requires token for authentication)
export const updatePostLike = async (token: string, id: string, payload: PostLikeDataType[]): Promise<Post[] | null> => {
    try {
        const response: AxiosResponse<ApiResponse<Post[]>> = {
            ...await axios.put(
                `${API_BASE_URL}/post/like/${id}`,
                payload,
                {
                    headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
                }
            )
        }
        return response.data?.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};

// Function to add bookmark to post (requires token for authentication)
export const updatePostBookmark = async (token: string, id: string, payload: PostBookmark[]): Promise<Post[] | null> => {
    try {
        const response: AxiosResponse<ApiResponse<Post[]>> = await axios.put(
            `${API_BASE_URL}/post/bookmark/${id}`,
            payload,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data?.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};

// Function to fetch posts (requires token for authentication)
export const fetchAllCommentsByPostId = async (token: string, postId: string | number): Promise<Post[] | null> => {
    try {
        const response: AxiosResponse<Post[]> = await axios.get(
            `${API_BASE_URL}/comments/post/${postId}`,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error fetching posts:', error);
        // return null;
        throw error;
    }
};

// Function to add comment to post (requires token for authentication)
export const insertPostComment = async (token: string, postId: string | number, payload: PostComment): Promise<PostComment | null> => {
    try {
        const response: AxiosResponse<ApiResponse<PostComment>> = await axios.post(
            `${API_BASE_URL}/comments/post/${postId}`,
            payload,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data?.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};

// Function to update comment to post (requires token for authentication)
export const putPostComment = async (token: string, commentId: string, payload: PostComment): Promise<PostComment | null> => {
    try {
        const response: AxiosResponse<ApiResponse<PostComment>> = await axios.put(
            `${API_BASE_URL}/comments/${commentId}`,
            payload,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data?.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};

// Function to delete comment to post (requires token for authentication)
export const delPostComment = async (token: string, commentId: string): Promise<PostComment | null> => {
    try {
        const response: AxiosResponse<ApiResponse<PostComment>> = await axios.delete(
            `${API_BASE_URL}/comments/${commentId}`,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data?.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};

// Function to add reply to the comment of the post (requires token for authentication)
export const insertPostCommentReply = async (token: string, parentCommentId: string, payload: PostComment): Promise<PostComment | null> => {
    try {
        const response: AxiosResponse<ApiResponse<PostComment>> = await axios.post(
            `${API_BASE_URL}/comments/reply/${parentCommentId}`,
            payload,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data?.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};

// Function to add reply to the comment of the post (requires token for authentication)
export const updatePostCommentReply = async (token: string, commentId: string, payload: PostComment): Promise<PostComment | null> => {
    try {
        const response: AxiosResponse<ApiResponse<PostComment>> = await axios.put(
            `${API_BASE_URL}/comments/reply/${commentId}`,
            payload,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data?.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};

// Function to add reply to the comment of the post (requires token for authentication)
export const updatePostCommentLikeSingle = async (token: string, commentId: string, payload: PostLike): Promise<PostComment | null> => {
    try {
        const response: AxiosResponse<ApiResponse<PostComment>> = await axios.put(
            `${API_BASE_URL}/comments/like/${commentId}`,
            payload,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data?.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};

// Function to add reply to the comment of the post (requires token for authentication)
export const updatePostCommentLike = async (token: string, commentId: string, payload: PostLikeDataType[]): Promise<PostComment | null> => {
    try {
        const response: AxiosResponse<ApiResponse<PostComment>> = {
            ...await axios.put(
                `${API_BASE_URL}/comments/like/${commentId}`,
                payload,
                {
                    headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
                }
            )
        }
        console.log("192:::response", response);
        return response.data?.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};

// Function to report the post (requires token for authentication)
export const submitPostReport = async (token: string, postId: string, payload: PostReportDataType): Promise<PostReport | null> => {
    try {
        const response: AxiosResponse<ApiResponse<PostReport>> = await axios.post(
            `${API_BASE_URL}/reports/post/${postId}`,
            payload,
            {
                headers: { Authorization: `Bearer ${token}`, "x-api-key": process.env.REACT_APP_REQUEST_API_KEY }
            }
        );
        return response.data?.data ?? null; // Return posts or null if undefined
    } catch (error) {
        console.error('Error reporting the post:', error);
        throw error;
    }
};