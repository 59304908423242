import React, { FC } from "react";

//project imports
import Card18Skeleton from "./Card18Skeleton";
import HeadingSkeleton from "./HeadingSkeleton";

export interface SkeletonProps {
    className?: string;
}

const SectionMagazine11Skeleton: FC<SkeletonProps> = ({ className = "" }) => {
    return (
        <div className={`nc-SectionMagazine11 relative ${className}`}>
            <HeadingSkeleton />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 sm:gap-4 md:gap-7">
                {[1, 2, 3].map((cate, i) =>
                    <div key={i} className={`flex flex-col space-y-4`}>
                        <div className="flex items-center justify-between">
                            <div
                                className="uppercase tracking-wide rounded-none px-4 py-1.5 bg-slate-200"
                            ></div>
                            <div className="flex items-center text-xs text-neutral-500">
                                <span className="w-8 h-2 bg-slate-200"></span>
                                <div className="w-6 h-6 ml-1.5 bg-slate-200"></div>
                            </div>
                        </div>
                        <Card18Skeleton />
                        <ul className="space-y-3">
                            {[1, 2, 3, 4]
                                .map((post, i) => (
                                    <li key={i}>
                                        <div className="nc-card-title flex items-start font-medium space-x-4">
                                            <div
                                                className="w-2.5 h-2.5 !p-0 rounded flex-shrink-0 mt-2 bg-slate-200"
                                            ></div>
                                            <div className="flex w-32 h-4 bg-slate-200 rounded-3">
                                            </div>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SectionMagazine11Skeleton;
