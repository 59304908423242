import React, { FC } from "react";

//project imports
import Card11Skeleton from "./Card11Skeleton";
import HeadingSkeleton from "./HeadingSkeleton";
import MySliderSkeleton from "./MySliderSkeleton";
import SectionSubscribe2Skeleton from "./SectionSubscribe2Skeleton";

export interface SkeletonProps {
    className?: string;
}

const PageAuthorSkeleton: FC<SkeletonProps> = ({ className = "" }) => {
    return (
        <div className={`nc-PageAuthor animate-pulse`}>

            {/* HEADER */}
            <div className="w-full animate-pulse">
                <div className="relative w-full h-40 md:h-60 2xl:h-72">
                    <div
                        className="absolute inset-0 object-cover w-full h-full bg-slate-200"
                    />
                </div>
                <div className="container -mt-10 lg:-mt-16">
                    <div className="relative bg-white dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row">
                        <div className="w-32 lg:w-40 flex-shrink-0 mt-12 sm:mt-0">
                            <div className="wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold rounded-full w-20 h-20 text-xl lg:text-2xl lg:w-36 lg:h-36 ring-4 ring-white dark:ring-0 shadow-2xl z-0">
                                <div
                                    className="object-cover absolute inset-0 w-full h-full rounded-full bg-slate-200"
                                />
                            </div>
                        </div>

                        {/*  */}
                        <div className="pt-5 md:pt-1 lg:ml-6 xl:ml-12 flex-grow">
                            <div className="max-w-screen-sm space-y-3.5 ">
                                <h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
                                    <span className="w-32 h-6 bg-slate-200 rounded-lg"></span>
                                    <div
                                        className="ml-2 w-6 h-6 sm:w-7 sm:h-7 xl:w-8 xl:h-8 bg-slate-200 rounded-full"
                                    />
                                </h2>
                                <span className="block bg-slate-200 w-full h-20 rounded-lg">
                                </span>
                                <div
                                    className="flex items-center text-xs font-medium space-x-2.5 cursor-pointer bg-slate-200 h-6 w-32 rounded-lg truncate"
                                >
                                </div>
                                <nav
                                    className={`nc-SocialsList flex space-x-3 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
                                >
                                    {[1, 2, 3, 4].map((item, i) => (
                                        <div
                                            key={i}
                                            className={`block w-7 h-7`}
                                        >
                                            <div className="bg-slate-200"></div>
                                        </div>
                                    ))}
                                </nav>
                            </div>
                        </div>

                        {/*  */}
                        <div className="absolute md:static left-5 right-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex justify-end">
                            <div
                                className="px-4 py-1 md:py-2.5 h-8 w-20 md:!h-10 sm:px-6 lg:px-8 bg-slate-200 rounded-lg"
                            ></div>

                            <div className="mx-2">
                                <div
                                    className="flex-shrink-0 flex items-center justify-center h-10 w-10 bg-slate-200 rounded-full" />
                            </div>

                            <div className="h-10 w-10 bg-slate-200 rounded-full" />
                        </div>
                    </div>
                </div>
            </div>

            {/* ====================== END HEADER ====================== */}
            <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
                <main>
                    {/* TABS FILTER */}
                    <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
                        <div className="sm:space-x-2">
                            {[1, 2, 3].map((item, index) => (
                                <span
                                    key={index}
                                    className={`inline-flex w-32 h-10 items-center bg-slate-200 justify-center rounded-full bg-primary-6000 text-white`}
                                >
                                </span>
                            ))}
                        </div>
                        <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
                        <div className="flex justify-end bg-slate-200 w-40 h-10 rounded-full">
                        </div>
                    </div>

                    {/* LOOP ITEMS */}
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
                        {[1, 2, 3, 4, 5].map((post, index) => (
                            <Card11Skeleton key={index} />
                        ))}
                    </div>

                    {/* PAGINATION */}
                    <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                        <nav
                            className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
                        >
                            {[1, 2, 3].map((page, index) =>
                                <span
                                    key={index}
                                    className={`inline-flex w-11 h-11 items-center bg-slate-200 justify-center rounded-full bg-primary-6000 text-white`}
                                >
                                </span>
                            )}
                        </nav>
                        <div className="bg-slate-200 w-40 h-10 rounded-full"></div>
                    </div>
                </main>
                {/* === SECTION 5 === */}
                <div className="relative py-16">
                    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
                        <HeadingSkeleton isCenter={true} />
                        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 sm:gap-6 md:gap-8">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                                <div
                                    className={`nc-CardCategory2 relative flex flex-col items-center justify-center text-center px-3 py-5 sm:p-6 bg-white dark:bg-neutral-900 rounded-3xl transition-colors ${className}`}
                                >
                                    <div
                                        className={`object-cover relative flex-shrink-0 w-20 h-20 bg-slate-200 rounded-full shadow-lg overflow-hidden z-0`}
                                    //   sizes="80px"
                                    />
                                    <div className="mt-3">
                                        <div className={`text-base font-semibold w-20 h-6 bg-slate-200 rounded-lg`}></div>
                                        <span
                                            className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400 w-20 h-6 bg-slate-200 rounded-lg`}
                                        >
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="text-center mx-auto mt-10 md:mt-16">
                        <div className="bg-slate-200 w-40 h-10 rounded-full"></div>
                    </div>
                </div>

                {/* === SECTION 5 === */}
                <div className={`nc-SectionSliderNewAuthors ${className}`}>
                    <HeadingSkeleton isCenter/>
                    <MySliderSkeleton itemPerRow={5} data={[1,2,3,4,5]} />
                </div>

                 {/* SUBCRIBES */}
                 <SectionSubscribe2Skeleton />
            </div>
        </div>
    );
};

export default PageAuthorSkeleton;
