import React, { FC } from "react";

// project-imports
import NavigationItem from "./NavigationItem";
import { MEGAMENU_TEMPLATES_LS } from "data/navigation";

// third-party
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
}

const Navigation: FC<Props> = ({ className = "flex" }) => {
  const { t } = useTranslation();

  return (
    <ul className={`nc-Navigation items-center ${className}`}>
      {MEGAMENU_TEMPLATES_LS.map((item) => (
        <NavigationItem key={item.id} menuItem={{ ...item, name: t(item.name) }} />
      ))}
    </ul>
  );
};

export default Navigation;
