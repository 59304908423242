import React, { useEffect } from "react";

// third-party
import { BrowserRouter, Route, Routes } from "react-router-dom";

// project-imports
import { Page } from "./types";
import Footer from "components/Footer/Footer";
import MusicPlayer from "components/MusicPlayer/MusicPlayer";
import PageArchive from "app/(archives)/archive/page";
import PageAuthor from "app/author/page";
import PageSingle from "app/(singles)/(default)/single/page";
import PageSingleAudio from "app/(singles)/(default)/single-audio/page";
import PageSingleVideo from "app/(singles)/(default)/single-video/page";
import PageAbout from "app/about/page";
import PageDisclaimer from "app/disclaimer/page";
import PageContact from "app/(others)/contact/page";
import PageLogin from "app/(others)/login/page";
import PageSignUp from "app/(others)/signup/page";
import PageForgotPass from "app/(others)/forgot-pass/page";
import PageResetPass from "app/(others)/reset-pass/page";
import Page404 from "app/not-found";
import PageHomeDemo6 from "app/(home)/home-6/page";
import SiteHeader from "app/SiteHeader";
import { useMemberContext } from "context/memberContext";

export const pages: Page[] = [
  { path: "/", component: PageHomeDemo6 },

  // archives page -------------------------------------------------------
  { path: "/archive/*", component: PageArchive },
  { path: "/author/*", component: PageAuthor },

  // single page -------------------------------------------------------
  { path: "/single/*", component: PageSingle },
  { path: "/single-audio/*", component: PageSingleAudio },
  { path: "/single-video/*", component: PageSingleVideo },

  // search -------------------------------------------------------
  // { path: "/search", component: PageSearch },
  // { path: "/search-2", component: PageSearchV2 },

  // other pages -------------------------------------------------------
  { path: "/about", component: PageAbout },
  { path: "/disclaimer", component: PageDisclaimer },
  { path: "/contact", component: PageContact },
  { path: "/login", component: PageLogin },
  { path: "/signup", component: PageSignUp },
  { path: "/forgot-pass", component: PageForgotPass },
  { path: "/reset-pass/:token", component: PageResetPass },
];

const MyRoutes = () => {
  const { currentMember, checkLoginStatus, isLoading } = useMemberContext();

  // Track page view for Login Page
  useEffect(() => {

    async function getLoginStatus() {
      try {
        if (currentMember && Object.keys(currentMember).length && !isLoading) {
          checkLoginStatus();
        }
      }
      catch (e) {
        console.log(e);
      }
    }

    getLoginStatus();
  }, []);

  return (
    <BrowserRouter>
      <SiteHeader />

      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>

      <Footer />
      <MusicPlayer />
    </BrowserRouter>
  );
};

export default MyRoutes;
