// project-imports
import { PostCommentDataType, PostDataType } from "data/types";
import {
  ActionType
} from "../constants/actions";
import { CommentType } from "components/CommentCard/CommentCard";
import { POST_LANGUAGES, processComments } from "utils/post";
import { comment } from "postcss";

export const initialState = {
  isLoading: false,
  error: null,
  posts: [],
  comments: [],
  allComments: [],
  recentComment: {},
  recentReport: {},
  language: "Hinglish"
};

const postReducer = (state = initialState, action: { type: ActionType; payload: any; }) => {
  if (action.type === ActionType.GET_POST_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
    };
  }

  if (action.type === ActionType.GET_POST_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      posts: JSON.parse(JSON.stringify(action.payload)),
      error: null,
    };
  }

  if (action.type === ActionType.GET_POST_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  }

  if (action.type === ActionType.CHANGE_POST_LANGUAGE_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
    };
  }

  if (action.type === ActionType.CHANGE_POST_LANGUAGE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      language: action.payload,
      error: null,
    };
  }
  if (action.type === ActionType.CHANGE_POST_LANGUAGE_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
      language: POST_LANGUAGES.HINGLISH
    };
  }

  if (action.type === ActionType.POST_ADD_LIKE_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
    };
  }

  if (action.type === ActionType.POST_ADD_LIKE_SUCCESS) {
    console.log("43=>", action.payload);

    const existingPosts: PostDataType[] = state.posts || [];
    // Create a new array to avoid mutating the original state.posts
    const updatedPosts = existingPosts.map((post) => {
      if (action.payload && post.id === action.payload?.id) {
        return { ...post, like: action?.payload?.like };
      }
      return post;
    });
    return {
      ...state,
      isLoading: false,
      posts: [...updatedPosts], // Use the new array
      error: null,
    };
  }
  if (action.type === ActionType.POST_ADD_LIKE_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  }
  if (action.type === ActionType.POST_ADD_BOOKMARK_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
    };
  }
  if (action.type === ActionType.POST_ADD_BOOKMARK_SUCCESS) {
    console.log("43=>", action.payload);
    const existingPosts: PostDataType[] = state.posts || [];
    // Create a new array to avoid mutating the original state.posts
    const updatedPosts = existingPosts.map((post) => {
      if (action.payload && post.id === action.payload?.id) {

        return { ...post, ...action.payload };
      }
      return post;
    });
    return {
      ...state,
      isLoading: false,
      posts: [...updatedPosts],
      error: null,
    };
  }
  if (action.type === ActionType.POST_ADD_BOOKMARK_FAILURE) {
    return Object.assign({}, state, {
      isLoading: false,
      error: action.payload,
    });
  }

  if (action.type === ActionType.GET_ALL_COMMENTS_BY_POST_ID_BEGIN) {
    return Object.assign({}, state, {
      isLoading: action.payload,
      error: null,
    });
  }
  if (action.type === ActionType.GET_ALL_COMMENTS_BY_POST_ID_SUCCESS) {
    const allComments = processComments(action.payload);
    return Object.assign({}, state, {
      isLoading: false,
      comments: allComments,
      allComments: action.payload,
      error: null,
    });
  }

  if (action.type === ActionType.GET_ALL_COMMENTS_BY_POST_ID_FAILURE) {
    return Object.assign({}, state, {
      isLoading: false,
      error: action.payload,
    });
  }

  if (action.type === ActionType.POST_ADD_COMMENT_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
    };
  }

  if (action.type === ActionType.POST_ADD_COMMENT_SUCCESS) {
    let existingComments: CommentType[] = state.allComments || [];
    let updatedComments = existingComments.map(comment =>
      comment.id === action.payload.id ? { ...action.payload } : { ...comment }
    );

    if (action?.payload?.id) {
      updatedComments.push([...updatedComments], action.payload);
    }

    // Clone the updated array and process comments (ensure deep cloning)
    const processedComments = processComments([...updatedComments]);

    // find post index by post id
    let existingPosts: PostDataType[] = state.posts || [];
    const index = existingPosts.findIndex(post => post?.id === action?.payload?.postId);

    // find comments associated with the current post
    const postComments = processedComments.filter(comment => comment?.postId === action?.payload?.postId);

    // update existing comments
    existingPosts = [
      ...existingPosts.slice(0, index),
      {
        ...existingPosts[index],
        commentCount: postComments.length
      },
      ...existingPosts.slice(index + 1)
    ];

    return {
      ...state,
      isLoading: false,
      comments: processedComments,
      allComments: updatedComments,
      posts: [...existingPosts],
      error: null,
    };
  }

  if (action.type === ActionType.POST_ADD_COMMENT_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  }

  if (action.type === ActionType.POST_DELETE_COMMENT_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
    };
  }

  if (action.type === ActionType.POST_DELETE_COMMENT_SUCCESS) {
    console.log("169=>", action.payload);
    let existingComments: PostCommentDataType[] = state.allComments || [];
    console.log("existingComments", existingComments);
    let updatedComments: PostCommentDataType[] = existingComments.filter(comment =>
      (comment.id !== action.payload.id && comment.parentCommentId !== action.payload.id)
    );
    const processedComments = processComments(updatedComments);

    if (action?.payload?.id) {
      updatedComments.push(...updatedComments, action.payload);
    }

    // find post index by post id
    let existingPosts: PostDataType[] = state.posts || [];
    const index = existingPosts.findIndex(post => post?.id === action?.payload?.postId);

    // find comments associated with the current post
    const postComments = processedComments.filter(comment => comment?.postId === action?.payload?.postId);

    // update existing comments
    existingPosts = [
      ...existingPosts.slice(0, index),
      {
        ...existingPosts[index],
        commentCount: postComments.length
      },
      ...existingPosts.slice(index + 1)
    ];

    return {
      ...state,
      isLoading: false,
      comments: processedComments,
      allComments: updatedComments,
      posts: [...existingPosts],
      error: null,
    };
  }
  
  if (action.type === ActionType.POST_DELETE_COMMENT_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  }

  if (action.type === ActionType.POST_UPDATE_COMMENT_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
    };
  }
  if (action.type === ActionType.POST_UPDATE_COMMENT_SUCCESS) {
    console.log("43=>", action.payload);
    const existingComments: PostCommentDataType[] = state.allComments || [];
    console.log("existingComments", existingComments);
    const updatedComments = (action.payload ? [action.payload] : []).reduce((acc, item) => {
      const index = acc.findIndex((post: PostCommentDataType) => post.id === item.id);
      if (index !== -1) {
        acc[index] = item;
      } else {
        acc.push(item);
      }
      return acc;
    }, [...existingComments]);
    const processedComments = processComments(updatedComments);
    return {
      ...state,
      isLoading: false,
      comments: processedComments,
      allComments: updatedComments,
      error: null,
    };
  }
  if (action.type === ActionType.POST_UPDATE_COMMENT_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  }

  if (action.type === ActionType.POST_ADD_COMMENT_REPLY_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
    };
  }

  if (action.type === ActionType.POST_ADD_COMMENT_REPLY_SUCCESS) {
    const existingComments: PostCommentDataType[] = state.allComments || [];
    const updatedComments = existingComments.map(comment =>
      comment.id === action.payload.id
        ? { ...comment, ...action.payload }
        : comment
    );

    if (action?.payload?.id) {
      updatedComments.push(action.payload);
    }

    const processedComments = processComments(updatedComments);
    return {
      ...state,
      isLoading: false,
      comments: [...processedComments],
      allComments: [...updatedComments],
      error: null,
    };
  }

  if (action.type === ActionType.POST_ADD_COMMENT_REPLY_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  }

  if (action.type === ActionType.POST_ADD_COMMENT_LIKE_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
    };
  }

  if (action.type === ActionType.POST_ADD_COMMENT_LIKE_SUCCESS) {
    console.log("43=>", action.payload);
    // Ensure existing comments are handled safely
    const existingComments: PostCommentDataType[] = state.allComments || [];
    // Update specific comment
    const updatedComments = existingComments.map((comment) =>
      action.payload && comment.id === action.payload.id
        ? { ...comment, ...action.payload }
        : comment
    );
    // Process comments (assume this is a helper function for additional transformations)
    const processedComments = processComments(updatedComments);
    // Return the new state
    return {
      ...state,
      isLoading: false,
      comments: [...processedComments],
      allComments: [...updatedComments],
      error: null,
    };
  }
  if (action.type === ActionType.POST_ADD_COMMENT_LIKE_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  }

  if (action.type === ActionType.POST_ADD_REPORT_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
    };
  }
  if (action.type === ActionType.POST_ADD_REPORT_SUCCESS) {

    return {
      ...state,
      isLoading: false,
      recentReport: action.payload,
      error: null,
    };
  }
  if (action.type === ActionType.POST_ADD_REPORT_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  }

  return state;
};

export default postReducer;
