import React, { FC } from "react";

export interface SkeletonProps {
    className?: string;
    mainClass?: string;
}

const SingleHeaderSkeleton: FC<SkeletonProps> = ({ className = "animate-pulse", mainClass = "text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-5xl dark:text-neutral-100", }) => {
    return (
        <div className={`nc-SingleHeader ${className}`}>
            <div className="space-y-5">
                <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
                    <div
                        className={`nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 ${className}`}>
                        <div className="flex items-center space-x-2">
                            <div
                                className="h-10 w-10 bg-slate-200 sm:h-11 sm:w-11 text-xl rounded-full"
                            ></div>
                        </div>
                        <div className="ml-3">
                            <div className="flex items-center">
                                <div className="block  h-8 w-20 bg-slate-200 rounded-md font-semibold"></div>

                                <>
                                    <span className="mx-2 font-semibold">·</span>
                                    <div className="ml-0 h-8 w-20 bg-slate-200 rounded-md">
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                    <div className=" flex flex-row space-x-5">
                        <div className=" h-8 w-8 bg-slate-200 rounded-md"></div>
                        <div className="w-1 h-8 border-r border-neutral-200"></div>
                        <div className=" h-8 w-8 bg-slate-200 rounded-md"></div>
                        <div className=" h-8 w-8 bg-slate-200 rounded-md"></div>
                        <div className=" h-8 w-8 bg-slate-200 rounded-md"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleHeaderSkeleton;
