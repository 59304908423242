import React, { FC, useEffect, useRef } from "react";

// project-imports
import NcModal from "components/NcModal/NcModal";
import PageLogin from "app/(others)/login/page";

export interface ProblemPlan {
  name: string;
  label: string;
}

export interface ModalReportItemProps {
  show: boolean;
  onCloseModalLogin: () => void;
}

const ModalLogin: FC<ModalReportItemProps> = ({
  show,
  onCloseModalLogin,
}) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const element: HTMLTextAreaElement | null = textareaRef.current;
        if (element) {
          (element as HTMLTextAreaElement).focus();
        }
      }, 400);
    }
  }, [show]);

  const renderContent = () => {
    return (
      <PageLogin hideSubscribe={true} onClose={onCloseModalLogin} />
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalLogin}
      contentExtraClass="max-w-screen-md"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle="Login to comment"
    />
  );
};

export default ModalLogin;