import React, { FC, useState } from "react";

// third-party
import { useTranslation } from "react-i18next";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

// project-import	
import ButtonCircle from "components/Button/ButtonCircle";
import Badge from "components/Badge/Badge";
import Input from "components/Input/Input";
import Image from "components/Image";
import { GoogleAnalyticsEnum, triggerFunction } from "utils/googleAnalytics";
import { NewsletterSubscription, useNewsletterContext } from "context/newsletterContext";
import { useMemberContext } from "context/memberContext";
import ModalLogin from "components/ModalLogin/ModalLogin";

// assets
import rightImg from "images/SVG-subcribe2.png";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {

  const { t } = useTranslation();

  // reading store data
  const { subscribe } = useNewsletterContext();
  const { currentMember } = useMemberContext();

  //stat variables
  const [newMember, setNewMember] = useState<NewsletterSubscription>({ email: "" });
  const [valdiationError, setValidationError] = useState<NewsletterSubscription>({ email: "" });
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const member = localStorage.getItem(process.env.REACT_APP_USER_LOCALSTORAGE_KEY as string) ? currentMember : null;

  const openModalLogin = () => setIsLoggedOut(true);
  const closeModalLogin = () => setIsLoggedOut(false);

  // handle change function
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name: String = "";
    try {
      let value = e.target.value;
      name = e.target.name;
      let member: NewsletterSubscription = newMember;
      if (name === "email") {
        member.email = value;
      }
      setValidationError(checkValidation(member, name));
      setNewMember(member);
    } catch (error: any) {
      console.log(`Member registeration ${name} error:`, error.message);
      throw error;
    }
  }

  const checkValidation = (member: NewsletterSubscription, name: String) => {
    try {
      let validationErrorObj: NewsletterSubscription = { email: "" };
      if (name === "all" || name === "email") {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (member && !member.email) {
          validationErrorObj.email = t("email_error_1");
        }
        else if (member && member.email && !emailRegex.test(member.email)) {
          validationErrorObj.email = t("email_error_2");
        } else {
          validationErrorObj.email = "";
        }
      }

      setValidationError(validationErrorObj);
      return validationErrorObj;
    } catch (error: any) {
      console.log('Member Validation Error:', error.message);
      throw error;
    }
  }

  const handleSubscribeClick = () => {
    try {

      // checkin if user has logged in or not
      if (!member) {
        return openModalLogin();
      }

      // Validation check for all fields
      let formIsValid = true;
      const validationErrros = checkValidation(newMember, "all");

      if (validationErrros.email) {
        formIsValid = false;
      }

      if (formIsValid) {
        const newMemberClone: NewsletterSubscription = newMember
        // Call subscribe action
        subscribe(newMemberClone);
        setNewMember({ email: '' });
      }
    } catch (error: any) {
      console.log('Member Subscription Error:', error.message);
      throw error;
    }
  }

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row items-center ${className}`}
    >
      <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">{t("join_our_newsletter")}</h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          {t("newsletter_desc")}
        </span>
        <ul className="space-y-5 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {t("newsletter_list_1")}
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {t("newsletter_list_2")}
            </span>
          </li>
        </ul>
        <div className="mt-10 relative max-w-sm">
          <Input
            placeholder="example@example.com"
            type="email"
            name="email"
            value={newMember.email}
            onChange={handleChange}
          />

          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-1 dark:bg-neutral-300 dark:text-black"
            onClick={() => {
              triggerFunction(
                GoogleAnalyticsEnum.BUTTON,
                GoogleAnalyticsEnum.SUBSCRIBE_NEWSLETTER
              );
              handleSubscribeClick();
            }
            }
          >
            <ArrowRightIcon className="w-5 h-5 " />
          </ButtonCircle>
        </div>
        {valdiationError && valdiationError.email && (
          <p className="text-red-500 text-xs mt-1">{valdiationError.email}</p>
        )}
      </div>
      <div className="flex-grow">
        <Image
          alt="subsc"
          sizes="(max-width: 768px) 100vw, 50vw"
          src={rightImg}
        />
      </div>
      <ModalLogin
        show={isLoggedOut}
        onCloseModalLogin={closeModalLogin}
      />
    </div>
  );
};

export default SectionSubscribe2;
