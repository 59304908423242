import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// project-imports
import Image from "components/Image";
import ButtonPlayMusicPlayer from "components/ButtonPlayMusicPlayer";
import { DEMO_POSTS_MAIN } from "data/posts";
import SingleTitle from "app/(singles)/SingleTitle";
import SingleMetaAction2 from "app/(singles)/SingleMetaAction2";
import Layout from "../layout";
import { useMemberContext } from "context/memberContext";
import { useCategoryContext } from "context/categoryContext";
import { useAuthorContext } from "context/authorContext";
import { usePostContext } from "context/postContext";
import { PostDataType } from "data/types";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import Link from "components/Link";

// assets
import iconPlaying from "images/icon-playing.gif";

const PageSingleAudio = () => {

  // local stat 
  const [post, setPost] = useState<PostDataType>({} as PostDataType);

  const location = useLocation();
  const { currentMember } = useMemberContext();
  const currentUserId = currentMember && (currentMember.id || "");

  // read store state
  const { isLoading: isCatLoading, categories } = useCategoryContext();
  const { isLoading: isAuthorLoading, authors } = useAuthorContext();
  const { getAllCommentsByPostId, isLoading: isPostLoading, posts } = usePostContext();
  const isLoading = isCatLoading || isAuthorLoading || isPostLoading;

  useEffect(() => {
    try {
      const pathName = decodeURIComponent(window.location.pathname);
      const lastItem: string = pathName.substring(pathName.lastIndexOf('/') + 1);

      if (lastItem) {
        const allPosts = DEMO_POSTS_MAIN(posts || [], categories || [], authors || []);
        const filteredPosts: PostDataType[] = allPosts.filter((item) => (item.title || "").toLowerCase().replaceAll("?", "").replaceAll(",", "").replaceAll("’", " ").replaceAll(":", "").replaceAll(".", "") === (lastItem || "").replaceAll("-", " ").toLowerCase());
        if (filteredPosts && filteredPosts.length) {

          const postCopy = { ...filteredPosts[0] };

          if (postCopy.id) {
            getAllCommentsByPostId(currentUserId, postCopy.id);
          }

          setPost(postCopy);
        }
      }
    } catch (error: any) {
      console.log("57:Error while updating the single post:", error?.message);
      throw error;
    }
  }, [location, posts]);

  const renderIcon = (playing: boolean) => {
    if (playing) {
      return <Image className="w-7" src={iconPlaying} alt="" />;
    }

    return (
      <svg className="w-11 h-11" fill="currentColor" viewBox="0 0 24 24">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M18.25 12L5.75 5.75V18.25L18.25 12Z"
        ></path>
      </svg>
    );
  };

  const renderButtonPlay = (playing: boolean) => {
    return (
      <div
        className={`aspect-w-1 aspect-h-1 rounded-full overflow-hidden z-10 shadow-2xl group cursor-pointer`}
      >
        {post && post.featuredImage &&
          <Image
            className={`w-full h-full object-cover transition-transform z-0 nc-animation-spin rounded-full ${playing ? "playing" : ""
              }`}
            src={post?.featuredImage}
            alt="audio"
          />}

        <div className="bg-neutral-900 bg-blend-multiply bg-opacity-75 rounded-full"></div>
        <div className="flex items-center justify-center">
          <div className="text-white bg-black bg-blend-multiply bg-opacity-50 w-20 h-20 border-2 border-neutral-300 rounded-full flex items-center justify-center ">
            {renderIcon(playing)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout post={post} isLoading={isLoading}>
      <div className={`relative pt-8 lg:pt-16`}>
        {/* Overlay */}
        <div className="bg-primary-50 dark:bg-neutral-800 absolute top-0 inset-x-0 h-60 w-full"></div>

        {/* SINGLE_AUDIO HEADER */}
        <header className="relative container ">
          <div className="bg-white dark:bg-neutral-900 shadow-2xl px-5 py-7 md:p-11 rounded-2xl md:rounded-[40px] flex flex-col sm:flex-row items-center justify-center space-y-10 sm:space-y-0 sm:space-x-11">
            <div className="w-1/2 sm:w-1/4 flex-shrink-0">
              <ButtonPlayMusicPlayer
                renderChildren={renderButtonPlay}
                post={post || {} as PostDataType}
              />
            </div>
            <div className="flex flex-col space-y-5">
              <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
                <div>
                  <CategoryBadgeList
                    itemClass="!px-3"
                    categories={post ? post.categories || [] : []}
                  />
                </div>
                <Link href={post?.author?.href || ""} className="flex mt-2.5 relative">
                  <span className="text-neutral-500 dark:text-neutral-400">
                    {post?.author?.displayName || ""}
                  </span>
                  <span className="mx-2">·</span>
                  <span className="font-normal truncate">{post?.date || ""}</span>
                </Link>
              </div>
              <SingleTitle title={post?.title || ""} />
              <span className="hidden lg:block text-lg text-neutral-500 dark:text-neutral-400">
                <div className="line-clamp-2 break-all">
                  {post?.excerpt || ""}
                </div>
              </span>
              <SingleMetaAction2 post={post} />
            </div>
          </div>
        </header>
      </div>
    </Layout>
  );
};

export default PageSingleAudio;
