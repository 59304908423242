import React, { FC, useEffect } from "react";

// project-import
import Link from "components/Link";
import { useAuthorContext } from "context/authorContext";
import LoadingVideo from "components/LoadingVideo/LoadingVideo";
import { GoogleAnalyticsEnum, pageView } from "utils/googleAnalytics";
import SingleAuthorSkeleton from "components/Skeleton/SingleAuthorSkeleton";
import Avatar from "components/Avatar/Avatar";
import { PostAuthorType } from "data/types";

// third-party
import { useTranslation } from "react-i18next";

export interface SingleAuthorProps {
  author?: PostAuthorType;
  onClick?: (name: string) => void;
}

const SingleAuthor: FC<SingleAuthorProps> = ({ author, onClick = () => { } }) => {

  const { t } = useTranslation();
  const { isLoading: isAuthorLoading, authors } = useAuthorContext();

  if (!author) {
    author = authors[0];
  }
  useEffect(() => {
    // Tracking page view for PAGE SEARCH page on Google Analytics
    pageView(window.location.pathname, GoogleAnalyticsEnum.POST_DETAIL_PAGE_VIEW + "-" + GoogleAnalyticsEnum.SINGLE_AUTOHOR + "-" + author?.displayName);
  }, [])

  return (
    <>
      {isAuthorLoading ? <SingleAuthorSkeleton /> :
        <div className="nc-SingleAuthor flex">
          {/* LoadingVideo if isAuthorLoading is true  */}

          {isAuthorLoading &&
            <LoadingVideo />
          }
          <Link href={author?.href || ""} onClick={() => onClick(author?.displayName || "")}>
            <Avatar
              imgUrl={author?.avatar}
              userName={author?.displayName}
              sizeClass="h-12 w-12 text-lg sm:text-xl sm:h-24 sm:w-24"
            />
          </Link>
          <div className="flex flex-col ml-3 sm:ml-5">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              {t("WRITTEN_BY")}
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <Link href={author?.href || "#"}>{t((author?.displayName || "").replace((author?.displayName || ""), "author_main_desk"))}</Link>
            </h2>
            <span className="block mt-1 text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              {t((author?.desc || "").replace((author?.desc || ""), "author_desc"))}
              <Link
                className="text-primary-6000 font-medium ml-1"
                href={author?.href || "#"}
              >
                {t("Read_more")}
              </Link>
            </span>
          </div>
        </div>
      }
    </>
  );
};

export default SingleAuthor;
