import { ActionType } from "../constants/actions";

export const initialState = {
  isLoading: false,
  error: null,
  subscription: {}
};

const newsletterReducer = (
  state = initialState,
  action: { type: ActionType; payload: any }
) => {
  if (
    action.type === ActionType.SUBSCRIBE_NEWSLETTER_BEGIN ||
    action.type === ActionType.UNSUBSCRIBE_NEWSLETTER_BEGIN
  ) {
    return {
      ...state,
      isLoading: action.payload,
      error: null
    };
  }
  if (
    action.type === ActionType.SUBSCRIBE_NEWSLETTER_SUCCESS ||
    action.type === ActionType.UNSUBSCRIBE_NEWSLETTER_SUCCESS
  ) {
    return {
      ...state,
      isLoading: false,
      subscription: action.payload,
      error: null
    };
  }
  if (
    action.type === ActionType.SUBSCRIBE_NEWSLETTER_FAILURE ||
    action.type === ActionType.UNSUBSCRIBE_NEWSLETTER_FAILURE
  ) {
    return {
      ...state,
      isLoading: false,
      error: action.payload
    };
  }

  return state;
};

export default newsletterReducer;
