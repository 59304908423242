import Swal, { SweetAlertGrow, SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

// Utility function to show a custom SweetAlert message
export const showMessage = (
  title: string,
  message: string,
  icon: SweetAlertIcon = 'info',
  confirmButtonText: string = 'OK',
  showCancelButton: boolean = false,
  options: SweetAlertOptions = {}
): Promise<SweetAlertResult> => {  // Specify SweetAlertResult as return type
  const swalOptions: SweetAlertOptions = {
    title,
    text: message,
    icon,
    confirmButtonText,
    showCancelButton,
    cancelButtonText: 'Cancel',
    position: 'center',
    allowOutsideClick: false,
    timer: 0,
    ...options,
  };

  return Swal.fire(swalOptions);
};

// Utility function to show a dynamic Toast Alert
export const showToastAlert = (
  title: string,
  message?: string,
  options: {
    icon?: SweetAlertIcon;
    position?: 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end' | 'center';
    background?: string;
    color?: string;
    iconColor?: string;
    timer?: number;
    timerProgressBar?: boolean;
    showConfirmButton?: boolean;
    grow?: SweetAlertGrow;
    showCloseButton?: boolean
  } = {}
): void => {
  const defaultOptions = {
    ...options,
  };

  Swal.fire({
    position: defaultOptions.position || "bottom-right",
    icon: defaultOptions.icon,
    title: title,
    text: message,
    showConfirmButton: defaultOptions.showConfirmButton,
    timer: defaultOptions.timer || 10000,
    toast: true,
    background: defaultOptions.background,
    color: defaultOptions.color,
    iconColor: defaultOptions.iconColor,
    timerProgressBar: defaultOptions.timerProgressBar || true,
    grow: defaultOptions.grow || "row",
    showCloseButton: defaultOptions.showCloseButton || true
  }).then(() => {
  });
};
