import React, { useEffect, useState } from "react";

//project imports
import { variants } from "utils/animationVariants";
import PrevBtn from "components/NextPrev/PrevBtn";
import NextBtn from "components/NextPrev/NextBtn";

//third party
import { useWindowSize } from "react-use";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import { useSwipeable } from "react-swipeable";

export interface MySliderProps<T> {
    className?: string;
    itemPerRow?: number;
    data: T[];
    arrowBtnClass?: string;
}

export default function MySliderSkeleton<T>({
    className = "",
    itemPerRow = 5,
    data,
    arrowBtnClass = "top-1/2 -translate-y-1/2",
}: MySliderProps<T>) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState(0);
    const [numberOfItems, setNumberOfitem] = useState(0);

    const windowWidth = useWindowSize().width;
    useEffect(() => {
        if (windowWidth < 320) {
            return setNumberOfitem(1);
        }
        if (windowWidth < 500) {
            return setNumberOfitem(itemPerRow - 3);
        }
        if (windowWidth < 1024) {
            return setNumberOfitem(itemPerRow - 2);
        }
        if (windowWidth < 1280) {
            return setNumberOfitem(itemPerRow - 1);
        }

        setNumberOfitem(itemPerRow);
    }, [itemPerRow, windowWidth]);

    function changeItemId(newVal: number) {
        if (newVal > currentIndex) {
            setDirection(1);
        } else {
            setDirection(-1);
        }
        setCurrentIndex(newVal);
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (currentIndex < data?.length - 1) {
                changeItemId(currentIndex + 1);
            }
        },
        onSwipedRight: () => {
            if (currentIndex > 0) {
                changeItemId(currentIndex - 1);
            }
        },
        trackMouse: true,
    });

    if (!numberOfItems) {
        return <div></div>;
    }

    return (
        <div className={`nc-MySlider ${className}`}>
            <MotionConfig
                transition={{
                    x: { type: "spring", stiffness: 300, damping: 30 },
                    opacity: { duration: 0.2 },
                }}
            >
                <div className={`relative flow-root`} {...handlers}>
                    <div className={`flow-root overflow-hidden rounded-xl`}>
                        <motion.ul
                            initial={false}
                            className="relative whitespace-nowrap -mx-2 xl:-mx-4 "
                        >
                            <AnimatePresence initial={false} custom={direction}>
                                {data.map((item, index) => (
                                    <motion.li
                                        className={`relative inline-block px-2 xl:px-4 whitespace-normal`}
                                        custom={direction}
                                        initial={{
                                            x: `${(currentIndex - 1) * -100}%`,
                                        }}
                                        animate={{
                                            x: `${currentIndex * -100}%`,
                                        }}
                                        variants={variants(200, 1)}
                                        key={index}
                                        style={{
                                            width: `calc(1/${numberOfItems} * 100%)`,
                                        }}
                                    >
                                        {/* {Author} */}
                                        <div
                                            className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-white dark:bg-neutral-800 rounded-3xl ${className}`}
                                        >
                                            <div className="relative flex-shrink-0 ">
                                                <div>
                                                    <div
                                                        className="flex aspect-w-7 max-w-[600px] aspect-h-5 w-full h-0 bg-slate-100"
                                                    />
                                                </div>
                                                <div className="absolute top-3 inset-x-3 flex">
                                                    <div className=" py-1 px-4 rounded-full flex items-center justify-center leading-none text-xs font-medium"
                                                    >
                                                        <div className="w-10 h-5 text-yellow-600 ml-3 bg-slate-200" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="-mt-8 m-8 text-center z-10 ">
                                                <div
                                                    className="ring-2 ring-grey-800 w-16 h-16 text-2xl rounded-full bg-slate-300  flex items-center justify-center"
                                                />
                                                <div className="mt-3">
                                                    <h2 className={`text-base font-medium`}>
                                                        <span className="line-clamp-1 w-full h-6 rounded-lg bg-slate-300"></span>
                                                    </h2>
                                                    <span
                                                        className={`block mt-1 w-full h-6 rounded-lg bg-slate-300`}
                                                    >
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </motion.li>
                                ))}
                            </AnimatePresence>
                        </motion.ul>
                    </div>

                    {currentIndex ? (
                        <PrevBtn
                            onClick={() => changeItemId(currentIndex - 1)}
                            className={`w-9 h-9 xl:w-12 xl:h-12 text-lg absolute -left-3 xl:-left-6 z-[1] ${arrowBtnClass}`}
                        />
                    ) : null}

                    {data.length > currentIndex + numberOfItems ? (
                        <NextBtn
                            onClick={() => changeItemId(currentIndex + 1)}
                            className={`w-9 h-9 xl:w-12 xl:h-12 text-lg absolute -right-3 xl:-right-6 z-[1] ${arrowBtnClass}`}
                        />
                    ) : null}
                </div>
            </MotionConfig>
        </div>
    );
}
