import React, { FC } from "react";

// project-imports
import Tag from "components/Tag/Tag";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import { TaxonomyType } from "data/types";
import { DEMO_TAGS } from "data/taxonomies";
import { GoogleAnalyticsEnum, triggerFunction } from "utils/googleAnalytics";

// third-party
import { useTranslation } from "react-i18next";

const tagsDemo = DEMO_TAGS.filter((_, i) => i < 11);

export interface WidgetTagsProps {
  className?: string;
  tags?: TaxonomyType[];
  isLoading?: boolean;
}

const WidgetTags: FC<WidgetTagsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  tags = tagsDemo,
  isLoading = false,
}) => {

  const { t } = useTranslation();

  const handleTagClick = (tagName: string) => {
    triggerFunction(GoogleAnalyticsEnum.HOME, GoogleAnalyticsEnum.SECTION_LATEST_POST_PAGE + "-" + GoogleAnalyticsEnum.CATEGORY_TAG_LINK_CLICK + "-" + tagName)
  }

  return (
    <div className={`nc-WidgetTags rounded-3xl overflow-hidden ${className}`}>
      <WidgetHeading1
        title={`💡 ${t("more_tags")}`}
        viewAll={{ label: "", href: "" }}
        isLoading={isLoading}
      />
      <div className="flex flex-wrap p-4 xl:p-5">
        {tags && tags.length ?
          tags.filter((item) => item.count !== 0).map((tag) => (
            <Tag className="mr-2 mb-2" key={tag.id} tag={tag} isLoading={isLoading} onClick={handleTagClick} />
          ))
          :
          <span>{t("no_data_found")}</span>
        }
      </div>
    </div>
  );
};

export default WidgetTags;
