import React, { FC, ReactNode } from "react";

// project-import
import SingleContent from "../SingleContent";
import SingleRelatedPosts from "../SingleRelatedPosts";
import { PostDataType } from "data/types";
import SingleContentSkeleton from "components/Skeleton/SingleContentSkeleton";

export interface LayoutProps {
  children: ReactNode;
  post?: PostDataType;
  isLoading?: boolean;
}

const Layout: FC<LayoutProps> = ({
  children,
  post,
  isLoading = false,
}) => {
  
  return (
    <div>
      {children}
      {/* SINGLE MAIN CONTENT */}
      <div className="container mt-10">
        {isLoading ? <SingleContentSkeleton /> :
          <SingleContent post={post} isLoading={isLoading} />}
      </div>

      {/* RELATED POSTS */}
      <SingleRelatedPosts />
    </div>
  );
};

export default Layout;
