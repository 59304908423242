import React, { FC } from "react";

// project-import
import Image from "components/Image";
import { PostDataType } from "data/types";
import SingleContentDemoSkeleton from "components/Skeleton/SingleContentDemoSkeleton";

// third-party
import { useTranslation } from "react-i18next";

export interface SingleContentProps {
  post?: PostDataType;
  isLoading?: boolean;
}

const SingleContentDemo: FC<SingleContentProps> = ({
  post,
  isLoading = false,
}) => {

  const {t} = useTranslation();
  
  return (
    <>
      {isLoading ? <SingleContentDemoSkeleton /> :
        <>
          {/* THIS IS THE DEMP CONTENT - YOU CAN USE MARKDOWN, JSON ...*/}
          {post && post.desc &&
            <div dangerouslySetInnerHTML={{ __html: post && (post.desc || "").replace(/(<? *script)/gi, 'illegalscript') }} >
            </div>
          }
          {/* <p>
            {`${t("illustration_content")}`}
          </p> */}
          <figure>
            <Image
              src={post ? post.featuredImage : ""}
              alt="nc blog"
              sizes="(max-width: 1024px) 100vw, 1240px"
              className="rounded-2xl"
              width={1635}
              height={774}
            />
            <figcaption>
              {post && post.featuredImageCaption}
            </figcaption>
          </figure>

        </>}
    </>
  );
};

export default SingleContentDemo;
