import React, { useContext, createContext, ReactNode } from 'react';

// third-party
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

// project-imports
import { ActionType } from '../constants/actions';
import { createEnquiry } from 'api/api';
import { showToastAlert } from 'utils/sweetAlert';


// Define the shape of the enquiry state
interface EnquiryState {
    isLoading: boolean;
    enquiry: any; // Replace `any[]` with your actual enquiry type
    error: string | null;
}

// Define the interface of the PostBookmark schema
export interface ContactEnquiry {
    name: string;
    email: string;
    message: string;
}

// Define the shape of the context value
interface EnquiryContextType extends EnquiryState {
    submitEnquiry: (enquiry: ContactEnquiry) => Promise<void>;
}

// Define props for the provider
interface EnquiryProviderProps {
    children: ReactNode;
    store: any
}

// Initialize the context
export const EnquiryContext = createContext<EnquiryContextType | undefined>(undefined);

export const EnquiryProvider: React.FC<EnquiryProviderProps> = ({ children }) => {
    const { t } = useTranslation();
    const { SUBMIT_ENQUIRY_BEGIN, SUBMIT_ENQUIRY_SUCCESS, SUBMIT_ENQUIRY_FAILURE } = ActionType;
    // Reading state from store
    const state = useSelector((state: { enquiry: EnquiryState }) => state.enquiry);

    // Reading dispatch from Store
    const dispatch = useDispatch();

    const submitEnquiry = async (enquiry: ContactEnquiry): Promise<void> => {
        dispatch({ type: SUBMIT_ENQUIRY_BEGIN, payload: true });

        try {
            let response: any = {}; // Replace with the actual API response type
            response = await createEnquiry("", enquiry) || {};
            dispatch({ type: SUBMIT_ENQUIRY_SUCCESS, payload: response });
            showToastAlert(`${t("Success")}`, `${t("enquiry_submit_success")}`, { icon: "success" });
        } catch (error: any) {
            console.error("Error fetching authors:", error);
            dispatch({ type: SUBMIT_ENQUIRY_FAILURE, payload: error?.message });
            showToastAlert(`${t("Error")}`, `${t("enquiry_submit_error")}`, { icon: "error" });
        }
    };

    return (
        <EnquiryContext.Provider value={{ ...state, submitEnquiry }
        }>
            {children}
        </EnquiryContext.Provider>
    );
};

// Custom hook for using the context
export const useEnquiryContext = (): EnquiryContextType => {
    const context = useContext(EnquiryContext);
    if (!context) {
        throw new Error("useEnquiryContext must be used within a EnquiryProvider");
    }
    return context;
};
