// project-imports
import { PostCommentDataType } from "data/types";

const processComments = (comments: PostCommentDataType[]) => {
    try {
        const commentMap = new Map<string | number, PostCommentDataType>();
        // Clone the comments and initialize an empty replies array
        const clonedComments = comments.map(comment => ({
            ...comment,
            replies: [], // Initialize to avoid mutation
        }));
        // Populate the map with cloned comments
        clonedComments.forEach(comment => {
            commentMap.set(comment.id!, comment);
        });
        // Attach replies to the **top-level parent** instead of nesting
        clonedComments.forEach(comment => {
            if (comment.parentCommentId) {
                let topLevelParent = commentMap.get(comment.parentCommentId);
                // Find the highest-level parent
                while (topLevelParent?.parentCommentId) {
                    topLevelParent = commentMap.get(topLevelParent.parentCommentId);
                    // comment.content = `<b>@${topLevelParent?.userName}</b> ${comment.content}`;
                }
                // Attach all replies to the top-level parent
                if (topLevelParent && topLevelParent.replies) {
                    topLevelParent.replies.push(comment);
                }
            }
        });
        // Return only top-level comments (those without `parentCommentId`)
        return clonedComments.filter(comment => !comment.parentCommentId);
    } catch (error: any) {
        console.log("Error while processing comments:", error?.message);
        throw error;
    }
};

const HINDI = "Hindi";
const HINGLISH = "Hinglish";
const ENGLISH = "English";

const POST_LANGUAGES = {
    HINDI, HINGLISH, ENGLISH
}

const readingTime = (article: string) => {
    try {
        const text = extractContent(article);
        const wpm = 200;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time;
    } catch (error: any) {
        console.log("Error while calculating reading time : ", error?.message);
        throw error;
    }
}
const extractContent = (s: string) => {
    try {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
    } catch (error: any) {
        console.log("Error while extracting contents from html desc: ", error?.message);
        throw error;
    }
};

export {
    processComments,
    POST_LANGUAGES,
    readingTime,
    extractContent
}