import React, { useContext, useEffect, createContext, ReactNode } from 'react';

// third-party
import { useDispatch, useSelector } from "react-redux";

// project-imports
import { ActionType } from '../constants/actions';
import { fetchPosts, updatePostLike, updatePostBookmark, insertPostComment, updatePostCommentLike, insertPostCommentReply, putPostComment, delPostComment, fetchAllCommentsByPostId, submitPostReport } from "api/post";
import { showToastAlert } from 'utils/sweetAlert';
import { PostLikeDataType, PostReportDataType } from 'data/types';
import { POST_LANGUAGES } from 'utils/post';

// Define the shape of the post state
interface PostState {
    isLoading: boolean;
    posts: any[]; // Replace `any[]` with your actual post type
    comments: any[];
    allComments: any[];
    recentComment: any;
    recentReport: any;
    language: string;
    error: string | null;
}

// Define the interface of the PostLike schema
export interface Post {
    id: number;
    title: string;
    content: string;
}

// Define the interface of the PostLike schema
export interface PostLike {
    count: number;
    isLiked: boolean;
}

// Define the interface of the PostBookmark schema
export interface PostBookmark {
    count: Number;
    isBookmarked: Boolean;
}

// Define the interface of the PostComment schema
export interface PostComment {
    id?: string;
    postId: string | number;
    userId: string;
    userName: string;
    content: string;
    parentCommentId?: string | null;
    likes: PostLikeDataType[];
    createdAt?: string;
    updatedAt?: string;
}

// Define the interface of the PostReport schema
export interface PostReport {
    id?: string;
    postId: string | number;
    userId: string;
    reason: string;
    content: string;
    createdAt?: string;
    updatedAt?: string;
}

// Define the shape of the context value
interface PostContextType extends PostState {
    getAllPosts: (language: string) => Promise<void>;
    addPostLike: (token: string, postId: string, like: PostLikeDataType[]) => Promise<void>;
    addPostBookmark: (token: string, postId: string, bookmark: PostBookmark[]) => Promise<void>;
    getAllCommentsByPostId: (token: string, postId: string | number) => Promise<void>;
    addPostComment: (token: string, postId: string | number, comment: PostComment) => Promise<void>;
    updatePostComment: (token: string, commentId: string, comment: PostComment) => Promise<void>;
    deletePostComment: (token: string, commentId: string) => Promise<void>;
    addPostCommentReply: (token: string, parentCommentId: string, comment: PostComment) => Promise<void>;
    updatePostCommentReply: (token: string, parentCommentId: string, comment: PostComment) => Promise<void>;
    addPostCommentLike: (token: string, commentId: string, likeData: PostLikeDataType[]) => Promise<void>;
    addPostReport: (token: string, postId: string, reportData: PostReportDataType) => Promise<void>;
    changePostLanguage: (language: string) => Promise<void>;
}

// Define props for the provider
interface PostProviderProps {
    children: ReactNode;
    store: any
}

// Initialize the context
export const PostContext = createContext<PostContextType | undefined>(undefined);

export const PostProvider: React.FC<PostProviderProps> = ({ children }) => {
    const { GET_POST_BEGIN, GET_POST_SUCCESS, GET_POST_FAILURE, POST_ADD_LIKE_BEGIN, POST_ADD_LIKE_SUCCESS, POST_ADD_LIKE_FAILURE, POST_ADD_BOOKMARK_BEGIN, GET_ALL_COMMENTS_BY_POST_ID_BEGIN, GET_ALL_COMMENTS_BY_POST_ID_SUCCESS, GET_ALL_COMMENTS_BY_POST_ID_FAILURE, POST_ADD_BOOKMARK_SUCCESS, POST_ADD_BOOKMARK_FAILURE, POST_ADD_COMMENT_BEGIN, POST_ADD_COMMENT_SUCCESS, POST_ADD_COMMENT_FAILURE, POST_UPDATE_COMMENT_BEGIN, POST_UPDATE_COMMENT_SUCCESS, POST_UPDATE_COMMENT_FAILURE, POST_DELETE_COMMENT_BEGIN, POST_DELETE_COMMENT_SUCCESS, POST_DELETE_COMMENT_FAILURE, POST_ADD_COMMENT_REPLY_BEGIN, POST_ADD_COMMENT_REPLY_SUCCESS, POST_ADD_COMMENT_REPLY_FAILURE, POST_ADD_COMMENT_LIKE_BEGIN, POST_ADD_COMMENT_LIKE_SUCCESS, POST_ADD_COMMENT_LIKE_FAILURE, POST_ADD_REPORT_BEGIN, POST_ADD_REPORT_SUCCESS, POST_ADD_REPORT_FAILURE, CHANGE_POST_LANGUAGE_BEGIN, CHANGE_POST_LANGUAGE_SUCCESS } = ActionType;
    // Reading state from store
    const state = useSelector((state: { post: PostState }) => state.post);

    // Reading dispatch from Store
    const dispatch = useDispatch();

    const getAllPosts = async (language: string): Promise<void> => {
        dispatch({ type: GET_POST_BEGIN, payload: true });

        try {
            let response: any[] = []; // Replace with the actual API response type
            response = await fetchPosts("", language) || [];
            dispatch({ type: CHANGE_POST_LANGUAGE_SUCCESS, payload: language });
            dispatch({ type: GET_POST_SUCCESS, payload: response });
            // showToastAlert('Success', 'Posts data fetched successfully!', { icon: "success" });
        } catch (error: any) {
            console.error("Error fetching posts:", error);
            dispatch({ type: GET_POST_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Failed to fetch posts. Please try again later.', { icon: "error" });
        }
    };

    const changePostLanguage = async (language: string): Promise<void> => {
        dispatch({ type: CHANGE_POST_LANGUAGE_BEGIN, payload: true });

        try {
            let response: any[] = []; // Replace with the actual API response type
            response = await fetchPosts("", language) || [];
            dispatch({ type: GET_POST_SUCCESS, payload: response });
            dispatch({ type: CHANGE_POST_LANGUAGE_SUCCESS, payload: language });
            // showToastAlert('Success', 'Posts data fetched successfully!', { icon: "success" });
        } catch (error: any) {
            console.error(`Error fetching posts by languag ${language}:`, error);
            dispatch({ type: GET_POST_FAILURE, payload: error?.message });
            showToastAlert('Error', `Failed to fetch posts by language ${language}. Please try again later.`, { icon: "error" });
        }
    };

    const addPostLike = async (token: string, postId: string, like: PostLikeDataType[]): Promise<void> => {
        dispatch({ type: POST_ADD_LIKE_BEGIN, payload: true });

        try {
            let response: any[] = []; // Replace with the actual API response type
            response = { ...await updatePostLike(token, postId, like) || [] }
            dispatch({ type: POST_ADD_LIKE_SUCCESS, payload: response });
            showToastAlert('Success', 'Posts liked successfully!', { icon: "success" });
        } catch (error: any) {
            console.error("Error adding like to post:", error);
            dispatch({ type: POST_ADD_LIKE_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Failed to like the post. Please try again later.', { icon: "error" });
        }
    };

    const addPostBookmark = async (token: string, postId: string, bookmark: PostBookmark[]): Promise<void> => {
        dispatch({ type: POST_ADD_BOOKMARK_BEGIN, payload: true });

        try {
            let response: any[] = []; // Replace with the actual API response type
            response = await updatePostBookmark(token, postId, bookmark) || [];
            dispatch({ type: POST_ADD_BOOKMARK_SUCCESS, payload: response });
            showToastAlert('Success', 'Posts bookmarked successfully!', { icon: "success" });
        } catch (error: any) {
            console.error("Error adding bookmark to post:", error);
            dispatch({ type: POST_ADD_BOOKMARK_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Failed to bookmark the post. Please try again later.', { icon: "error" });
        }
    };

    const getAllCommentsByPostId = async (token: string, postId: string | number): Promise<void> => {
        dispatch({ type: GET_ALL_COMMENTS_BY_POST_ID_BEGIN, payload: true });

        try {
            let response: any[] = []; // Replace with the actual API response type
            response = await fetchAllCommentsByPostId(token, postId) || [];
            dispatch({ type: GET_ALL_COMMENTS_BY_POST_ID_SUCCESS, payload: [...response] });
            // showToastAlert('Success', 'All comments for the current post fetched successfully!', { icon: "success" });
        } catch (error: any) {
            console.error("Error fetching posts:", error);
            dispatch({ type: GET_ALL_COMMENTS_BY_POST_ID_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Failed to fetch post comments. Please try again later.', { icon: "error" });
        }
    };

    const addPostComment = async (token: string, postId: string | number, comment: PostComment): Promise<void> => {
        dispatch({ type: POST_ADD_COMMENT_BEGIN, payload: true });

        try {
            let response: any = {};
            response = await insertPostComment(token, postId, comment) || {};
            dispatch({ type: POST_ADD_COMMENT_SUCCESS, payload: response });
            showToastAlert('Success', 'Comment added to post successfully!', { icon: "success" });
        } catch (error: any) {
            console.error("Error adding comments to post:", error);
            dispatch({ type: POST_ADD_COMMENT_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Failed to add comment to the post. Please try again later.', { icon: "error" });
            throw error;
        }
    };

    const updatePostComment = async (token: string, commentId: string, comment: PostComment): Promise<void> => {
        dispatch({ type: POST_UPDATE_COMMENT_BEGIN, payload: true });

        try {
            let response: any = {}; // Replace with the actual API response type
            response = await putPostComment(token, commentId, comment) || {};
            dispatch({ type: POST_UPDATE_COMMENT_SUCCESS, payload: response });
            showToastAlert('Success', 'Comment updated successfully!', { icon: "success" });
        } catch (error: any) {
            console.error("Error updating comment to post:", error);
            dispatch({ type: POST_UPDATE_COMMENT_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Failed to update comment. Please try again later.', { icon: "error" });
        }
    };

    const deletePostComment = async (token: string, commentId: string): Promise<void> => {
        dispatch({ type: POST_DELETE_COMMENT_BEGIN, payload: true });

        try {
            let response: any = {}; // Replace with the actual API response type
            response = await delPostComment(token, commentId) || {};
            dispatch({ type: POST_DELETE_COMMENT_SUCCESS, payload: response });
            showToastAlert('Success', 'Comment deleted successfully!', { icon: "success" });
        } catch (error: any) {
            console.error("Error deleting comment to post:", error);
            dispatch({ type: POST_DELETE_COMMENT_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Failed to delete comment. Please try again later.', { icon: "error" });
        }
    };

    const addPostCommentReply = async (token: string, parentCommentId: string, commentReply: PostComment): Promise<void> => {
        dispatch({ type: POST_ADD_COMMENT_REPLY_BEGIN, payload: true });

        try {
            let response: any = {}; // Replace with the actual API response type
            response = await insertPostCommentReply(token, parentCommentId, commentReply) || {};
            dispatch({ type: POST_ADD_COMMENT_REPLY_SUCCESS, payload: response });
            showToastAlert('Success', 'Reply to post comment added successfully!', { icon: "success" });
        } catch (error: any) {
            console.error("Error adding comment reply to post:", error);
            dispatch({ type: POST_ADD_COMMENT_REPLY_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Failed to add reply to the post comment. Please try again later.', { icon: "error" });
        }
    };

    const updatePostCommentReply = async (token: string, commentId: string, commentReply: PostComment): Promise<void> => {
        dispatch({ type: POST_ADD_COMMENT_REPLY_BEGIN, payload: true });

        try {
            let response: any = {}; // Replace with the actual API response type
            response = await insertPostCommentReply(token, commentId, commentReply) || {};
            dispatch({ type: POST_ADD_COMMENT_REPLY_SUCCESS, payload: response });
            showToastAlert('Success', 'Reply to post comment updated successfully!', { icon: "success" });
        } catch (error: any) {
            console.error("Error updating reploy:", error);
            dispatch({ type: POST_ADD_COMMENT_REPLY_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Failed to update reply to the post comment. Please try again later.', { icon: "error" });
        }
    };

    const addPostCommentLike = async (token: string, commentId: string, likeData: PostLikeDataType[]): Promise<void> => {
        dispatch({ type: POST_ADD_COMMENT_LIKE_BEGIN, payload: true });
        try {
            let response: any = {}; // Replace with the actual API response type

            response = { ...await updatePostCommentLike(token, commentId, likeData) || [] };
            response &&
                dispatch({ type: POST_ADD_COMMENT_LIKE_SUCCESS, payload: response });
            showToastAlert('Success', 'You have liked the Comment!', { icon: "success" });
        } catch (error: any) {
            console.error("Error adding like to comment:", error?.message);
            dispatch({ type: POST_ADD_COMMENT_LIKE_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Failed to like the Comment. Please try again later.', { icon: "error" });
        }
    };

    const addPostReport = async (token: string, postId: string, reportData: PostReportDataType): Promise<void> => {
        dispatch({ type: POST_ADD_REPORT_BEGIN, payload: true });
        try {
            let response: any = {}; // Replace with the actual API response type
            response = await submitPostReport(token, postId, reportData) || [];
            dispatch({ type: POST_ADD_REPORT_SUCCESS, payload: response });
            showToastAlert('Success', 'Your report has been submitted! Thank you, we will get back to you.', { icon: "success" });
        } catch (error: any) {
            console.error("Error reporting the post:", error);
            dispatch({ type: POST_ADD_REPORT_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Failed to submit your report. Please try again later.', { icon: "error" });
        }
    };

    useEffect(() => {
        getAllPosts(state?.language || POST_LANGUAGES.HINGLISH);
    }, []);

    return (
        <PostContext.Provider value={{ ...state, getAllPosts, changePostLanguage, addPostLike, addPostBookmark, getAllCommentsByPostId, addPostComment, updatePostComment, addPostCommentReply, addPostCommentLike, updatePostCommentReply, deletePostComment, addPostReport }
        }>
            {children}
        </PostContext.Provider>
    );
};

// Custom hook for using the context
export const usePostContext = (): PostContextType => {
    const context = useContext(PostContext);
    if (!context) {
        throw new Error("usePostContext must be used within a PostProvider");
    }
    return context;
};
