import React, { FC } from "react";

export interface SkeletonProps {
    className?: string;
}

const ContactUsSkeleton: FC<SkeletonProps> = ({ className = "mt-2 animate-pulse" }) => {
    return (
        <>
            <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-28 animate-pulse">
                <div className="block text-sm mt-2 h-10 w-full bg-slate-200 rounded-lg"></div>
                <span className="block text-sm mt-2 h-6 w-full bg-slate-200 rounded-lg">
                </span>
            </header>

            <div className="grid gap-8 lg:grid-cols-2 animate-pulse">
                <div className="max-w-sm space-y-6">
                    {[1, 2].map((item, index) => (
                        <div key={index}>
                            <h3 className="uppercase w-64 h-4 bg-slate-200 rounded-xl">
                            </h3>
                            <span className="block mt-2 w-full h-10 bg-slate-200 rounded-xl">
                            </span>
                        </div>
                    ))}
                    <div>
                        <h3 className="uppercase w-20 h-6 bg-slate-200 rounded-xl">
                        </h3>
                        <nav
                            className={`nc-SocialsList flex space-x-3 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
                        >
                            {[1, 2, 3, 4,].map((item, i) => (
                                <div
                                    key={i}
                                >
                                    <div className="w-8 h-8 bg-slate-200 rounded-xl"></div>
                                </div>
                            ))}
                        </nav>
                    </div>
                </div>
                <div className="border border-neutral-100 dark:border-neutral-700 lg:hidden"></div>
                <div>
                    <form className="grid grid-cols-1 gap-6" action="#" method="post">
                        <label className="block">
                            <div className="w-64 h-10 bg-slate-200 rounded-xl"></div>

                            <div className="mt-1 w-full h-10 bg-slate-200 rounded-xl" />
                        </label>
                        <label className="block">
                            <div className="w-64 h-10 bg-slate-200 rounded-xl"></div>

                            <div className="mt-1 w-full h-10 bg-slate-200 rounded-xl" />
                        </label>
                        <label className="block">
                            <div className="w-64 h-10 bg-slate-200 rounded-xl"></div>

                            <div className="mt-1 w-full h-32 bg-slate-200 rounded-xl" />
                        </label>
                        <div className="w-full h-10 bg-slate-200 rounded-xl"></div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ContactUsSkeleton;
