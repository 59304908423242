import React, { FC } from "react";

//project imports
import MainNav2Logged from "./MainNav2Logged";

export interface HeaderProps { }

const Header: FC<HeaderProps> = () => {
  return (
    <div className="nc-Header sticky top-0 w-full z-40">
      <MainNav2Logged />
    </div>
  );
};

export default Header;
