import React, { FC, useEffect } from "react";

// project-imports
import { PostDataType } from "data/types";
import Badge from "components/Badge/Badge";
import { GoogleAnalyticsEnum, pageView } from "utils/googleAnalytics";

// third-party
import { useTranslation } from "react-i18next";

export interface CategoryBadgeListProps {
  className?: string;
  itemClass?: string;
  categories: PostDataType["categories"];
  onClick?: (name: string) => void;
}

const CategoryBadgeList: FC<CategoryBadgeListProps> = ({
  className = "flex flex-wrap space-x-2",
  itemClass,
  categories,
  onClick = () => { }
}) => {
  const { t } = useTranslation();

  // Tracking page view for CategoryBadgeList page on Google Analytics
  useEffect(() => {
    try {
      pageView(window.location.pathname, GoogleAnalyticsEnum.CATEGORY_BADGE_LIST_PAGE);
    } catch (error) {
      console.log("25::CategoryBadgeList:: Error for tracking page view", error);
    }
  }, []);

  return (
    <div
      className={`nc-CategoryBadgeList ${className}`}
      data-nc-id="CategoryBadgeList"
    >
      {(categories || []).map((item, index) => (
        <Badge
          className={itemClass}
          key={index}
          name={t((item?.name || "").replaceAll(" ", "_").replaceAll("-", "_"))}
          href={item.href}
          color={item.color as any}
          onClick={onClick}
        />
      ))}
    </div>
  );
};

export default CategoryBadgeList;
