import React, { FC } from "react";

// project-imports
import NcBookmark from "../NcBookmark/NcBookmark";
import { PostDataType } from "data/types";

// third-party
import { useTranslation } from "react-i18next";

export interface PostCardSaveActionProps {
  className?: string;
  bookmarkClass?: string;
  readingTime?: number;
  hidenReadingTime?: boolean;
  post?: PostDataType;
}

const PostCardSaveAction: FC<PostCardSaveActionProps> = ({
  className = "",
  bookmarkClass,
  hidenReadingTime = true,
  readingTime = 3,
  post,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`nc-PostCardSaveAction flex items-center space-x-2 text-xs text-neutral-700 dark:text-neutral-300 ${className}`}
    >
      {!hidenReadingTime && !!readingTime && (
        <span>{readingTime} {t("min_read")}</span>
      )}

      <NcBookmark containerClassName={bookmarkClass} post={post} />
    </div>
  );
};

export default PostCardSaveAction;
