// src/i18n.ts or src/i18next.js
// third-party
import i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      language: "Language",
      home: "Home",
      about: "About",
      contact: "Contact",
      demo: "Demo",
      disclaimer: "Disclaimer",
      disclaimer_list_1: `<strong>Educational Purpose Only:</strong> The information provided on <strong>Finance Optimize</strong> is for educational and informational purposes only and should not be considered as financial, investment, or legal advice.`,
      disclaimer_list_2: `<strong>Not a Professional Advisory Service:</strong> We are not certified financial planners, advisors, or investment brokers. Any decisions made based on the content of this website are solely at the user's discretion and risk. For personalized financial advice, please consult a licensed professional.`,
      disclaimer_list_3: `<strong>Investment Risks:</strong> All investments carry risk, including the potential loss of principal. Past performance is not indicative of future results. Readers should conduct their own research or seek advice from a professional before making any investment decisions.`,
      disclaimer_list_4: `<strong>Accuracy of Information:</strong> While we strive to ensure accuracy, we do not guarantee the completeness, reliability, or suitability of the information provided. Finance Optimize shall not be held liable for any errors, omissions, or delays in the information provided.`,
      disclaimer_list_5: `<strong>Affiliate Links & Advertisements:</strong> This website may include affiliate links, advertisements, or sponsored content. These do not influence our opinions or recommendations. We recommend conducting your own research before engaging with any advertised products or services.`,
      disclaimer_list_6: `<strong>Local Laws & Regulations:</strong> Financial practices and laws vary by region. It is the user's responsibility to ensure compliance with applicable financial regulations.`,
      disclaimer_list_7: `By using this website, you agree to the terms of this disclaimer and understand that Finance Optimize assumes no liability for any loss, damages, or consequences resulting from the use of its content.`,
      disclaimer_quote: `This content is for informational purposes only and not professional financial advice. Consult an expert before making financial decisions.`,
      listen_to_audio_blogs: "Listen to Audio Blogs",
      click_on_music_icon_and_enjoy_music_or_podcast: "Click on music icon and enjoy blog or podcast",
      more_articles: "More Articles",
      latest_articles: "Latest Articles",
      discover_the_most_outstanding_articles_in_all_topics_of_finance: "Discover the most outstanding articles in all topics of finance.",
      more_tags: "More tags",
      trending_topic: "Trending topic",
      discover_authors: "Discover Authors",
      popular_posts: "Popular Posts",
      all_rights_reserved: " All rights reserved.",
      submit: "Submit",
      cancel: "Cancel",
      reply: "Reply",
      edit: "Edit",
      report: "Report",
      delete: "Delete",
      processing: "Processing",
      related_posts: "Related posts",
      more_from_author: "More from author",
      join_our_newsletter: "Join our newsletter 🎉",
      newsletter_desc: "Read and share new perspectives on just about any topic. Everyone’s welcome.",
      newsletter_list_1: "Early Access to Blogs",
      newsletter_list_2: "Tools & Resources",
      contact_us: "Contact us",
      contact_us_desk: "Drop us message and we will get back for you.",
      full_name: "Full name",
      email: "Email",
      address: "Address",
      email_address: "Email address",
      message: "Message",
      send_message: "Send Message",
      follow: "Follow",
      articles: "Articles",
      favorites: "Favorites",
      saved: "Saved",
      most_recent: "Most Recent",
      curated_by_admin: "Curated by Admin",
      most_appreciated: "Most Appreciated",
      most_discussed: "Most Discussed",
      most_viewed: "Most Viewed",
      show_me_more: "Show Me More",
      top_trending_topics: "Top trending topics",
      top_trending_topics_desc: "Discover over 100 topics",
      top_elite_authors: "Top elite authors",
      top_elite_authors_desc: "Discover our elite writers",
      sign_up: "Sign up",
      sign_up_desc: "Welcome to our blog magazine Community",
      Password: "Password",
      sign_up_caption: "Already have an account?",
      sign_in: "Sign In",
      forgot_password: "Forgot password",
      forgot_password_caption: "Go back for",
      forgot_password_subtitle: "Welcome to our financial blogging Community",
      reset_password: "Reset password",
      reset_password_caption: "Go back for",
      reset_password_subtitle: "Welcome to our financial blogging Community",
      new_password: "New Password",
      confirm_new_password: "Confirm New Password",
      sign_in_desc_1: "New member?",
      sign_in_desc_2: "Become a member",
      continue: "Continue",
      continue_with_google: "Continue with Google",
      or: "OR",
      socials: "SOCIALS",
      theme_dark: "Dark theme",
      help: "Help",
      guest_user: "Guest User",
      my_account: "My Account",
      my_posts: "My Posts",
      wishlist: "Wishlist",
      sign_out: "Log Out",
      about_us: "👋 About Us.",
      about_us_heading: `Welcome to <strong>Finance Optimize</strong>, your trusted companion on the journey toward financial empowerment and literacy.`,
      about_us_subheading: `Our mission is to demystify personal finance and provide actionable insights that help individuals and families in India, especially the middle and upper-class households, take control of their financial future. We believe that financial knowledge is the key to unlocking opportunities, achieving life goals, and building lasting security.`,
      email_error_1: "Email is required!",
      email_error_2: "Please enter a valid email address!",
      password_error_1: "Password is required!",
      password_error_2: "Password must be atleast 6-14 characters long!",
      password_error_3: "Password must contain at least one number , lowercase , uppercase and a special character!",
      name_error_1: "Full Name is required!",
      name_error_2: "Full name must be atleast 3 characters long!",
      comment_error_1: "Please enter a valid comment!",
      message_error_1: "Message is required!",
      message_error_2: "Message must be atleast 6-500 characters long!",
      Success: "Success",
      Error: "Error",
      authors_data_fetched_successfully: "Authors data fetched successfully!",
      authors_data_fetched_error_message: "Failed to fetch authors. Please try again later.",
      posts_data_fetched_successfully: "Posts data fetched successfully!",
      posts_data_fetched_error_message: "Failed to fetch posts. Please try again later.",
      posts_data_like_successfully: "Posts liked successfully!",
      posts_data_like_error_message: "Failed to like the post. Please try again later.",
      posts_bookmarked_successfully: "Posts bookmarked successfully!",
      posts_bookmarked_error_message: "Failed to bookmark the post. Please try again later.",
      comments_fetched_successfully: "All comments for the current post fetched successfully!",
      comments_fetched_error_message: "Failed to fetch post comments. Please try again later.",
      comments_add_successfully: "Comment added to post successfully!",
      comments_add_error_message: "Failed to add comment to the post. Please try again later.",
      comments_update_successfully: "Comment updated successfully!",
      comments_update_error_message: "Failed to update comment. Please try again later.",
      comments_delete_successfully: "Comment deleted successfully!",
      comments_delete_error_message: "Failed to delete comment. Please try again later.",
      comments_reply_add_successfully: "Reply to post comment added successfully!",
      comments_reply_add_error_message: "Failed to add reply to the post comment. Please try again later.",
      comments_reply_update_successfully: "Reply to post comment updated successfully!",
      comments_reply_update_error_message: "Failed to update reply to the post comment. Please try again later.",
      comment_like_successfully: "You have liked the comment!",
      comment_like_error_message: "Failed to like the comment. Please try again later.",
      newsletter_subscribed_successfully: "You have subscribed to our newsletter successfully!",
      newsletter_subscribed_error_message: "Failed to subscribe you to our newsletter subscription. Please try again later.",
      newsletter_unsubscribed_successfully: "You have unsubscribed to our newsletter successfully!",
      newsletter_unsubscribed_error_message: "Failed to unsubscribe you from our newsletter subscription. Please try again later.",
      member_login_success: "Login is successful",
      member_login_error: "Please try again later.",
      member_logout_success: "You have logged out successfully",
      member_logout_error: "Please try again later.",
      member_register_success: "Congratulations, your account has been successfully created",
      member_register_error: "We are unable to register your accounts. Please try again later.",
      enquiry_submit_success: "Your enquiry is submitted successfully!",
      enquiry_submit_error: "Failed to submit your enquiry. Please try again later.",
      cayegory_fetched_success: "Category data fetched successfully!",
      cayegory_fetched_error: "Failed to fetch category.Please try again later.",
      delete_comment: "Delete comment",
      delete_comment_message: "Are you sure you want to delete this comment? You cannot undo this action.",
      editing_comment: "Editing comment",
      report_abuse: "Report Abuse",
      Violence: "Violence",
      Trouble: "Trouble",
      Spam: "Spam",
      Other: "Other",
      Message: "Message",
      message_caption: "Please provide any additional information or context that will help us understand and handle the situation.",
      most_bookmarked: "Most Bookmarked",
      Response: "Response",
      no_comments_available: "No comments Available",
      Budgeting: "Budgeting",
      Investing: "Investing",
      Personal_Finance: "Personal Finance",
      Money_Saving_Tips: "Money-Saving Tips",
      Retirement_Planning: "Retirement Planning",
      Debt_Management: "Debt Management",
      Financial_Independence: "Financial Independence",
      Tax_Planning: "Tax Planning",
      Compound_Interest: "Compound Interest",
      Side_Hustles: "Side Hustles",
      Financial_Planning_Basics: "Financial Planning Basics",
      Insurance: "Insurance",
      Categories_Title: "Discover other categories",
      other_categories: "Other Categories",
      replies: "replies",
      no_article_found_message: "We found no articles in this category for this author.",
      min_read: "min read",
      Read_more: "Read more",
      Facebook: "Facebook",
      Twitter: "Twitter",
      Linkedin: "Linkedin",
      Instagram: "Instagram",
      Whatsapp: "Whatsapp",
      Threads: "Threads",
      YouTube: "YouTube",
      author_main_desk: "Finance Optimize Desk",
      Editorial_Desk: "Editorial Desk",
      author_desc: "Finance Optimize is a blogging platform where we provide easy-to-understand and engaging content about financial literacy and money management. Our goal is to help Indian families achieve financial freedom and understand smart money moves through our blogs. In every post we cover important topics like budgeting, investments, and financial growth in detail, so that you can manage your money intelligently. Empower your financial journey with Finance Optimize and make your dreams a reality.",
      a: "a",
      no_data_found: "Nothing we found! Check back later.",
      Hide_comments: "Hide comments",
      View_all_comments: "View all comments",
      WRITTEN_BY: "WRITTEN BY",
      Add_to_discussion : "Add to discussion",
      copylink: "Copy Link",
      reportThisArticle: "Report this article",
      hideThisAuthor: "hideThisAuthor",
      commentThisArticle: "commentThisArticle",
      disclaimer_subtext: "This content is for informational purposes only and not professional financial advice. Consult an expert before making financial decisions.",
      illustration_content: "Freelance Writing and Content Creation Illustration:",
    },
  },
  hi: {
    translation: {
      language: "भाषा",
      home: "होम",
      about: "विषय",
      contact: "संपर्क",
      demo: "डेमो",
      disclaimer: "अस्वीकरण",
      disclaimer_list_1: `<strong>केवल शैक्षिक उद्देश्य:</strong> <strong>फाइनेंस ऑप्टिमाइज़</strong> पर प्रदान की गई जानकारी केवल शैक्षिक और सूचनात्मक उद्देश्यों के लिए है और इसे वित्तीय, निवेश या कानूनी सलाह के रूप में नहीं माना जाना चाहिए।`,
      disclaimer_list_2: `<strong>पेशेवर सलाहकार सेवा नहीं:</strong> हम प्रमाणित वित्तीय योजनाकार, सलाहकार या निवेश दलाल नहीं हैं। इस वेबसाइट की सामग्री के आधार पर किए गए कोई भी निर्णय पूरी तरह से उपयोगकर्ता के विवेक और जोखिम पर हैं। व्यक्तिगत वित्तीय सलाह के लिए, कृपया किसी लाइसेंस प्राप्त पेशेवर से परामर्श लें।`,
      disclaimer_list_3: `<strong>निवेश जोखिम:</strong> सभी निवेशों में जोखिम होता है, जिसमें मूलधन की संभावित हानि भी शामिल है। पिछला प्रदर्शन भविष्य के परिणामों का संकेत नहीं है। पाठकों को कोई भी निवेश निर्णय लेने से पहले अपना स्वयं का शोध करना चाहिए या किसी पेशेवर से सलाह लेनी चाहिए।`,
      disclaimer_list_4: `<strong>सूचना की सटीकता:</strong> जबकि हम सटीकता सुनिश्चित करने का प्रयास करते हैं, हम प्रदान की गई जानकारी की पूर्णता, विश्वसनीयता या उपयुक्तता की गारंटी नहीं देते हैं। फाइनेंस ऑप्टिमाइज़ प्रदान की गई जानकारी में किसी भी त्रुटि, चूक या देरी के लिए उत्तरदायी नहीं होगा।`,
      disclaimer_list_5: `<strong>सहबद्ध लिंक और विज्ञापन:</strong> इस वेबसाइट में सहबद्ध लिंक, विज्ञापन या प्रायोजित सामग्री शामिल हो सकती है। ये हमारी राय या सिफारिशों को प्रभावित नहीं करते हैं। हम किसी भी विज्ञापित उत्पाद या सेवा से जुड़ने से पहले अपना खुद का शोध करने की सलाह देते हैं।`,
      disclaimer_list_6: `<strong>स्थानीय कानून और विनियम:</strong> वित्तीय प्रथाएँ और कानून क्षेत्र के अनुसार अलग-अलग होते हैं। लागू वित्तीय विनियमों का अनुपालन सुनिश्चित करना उपयोगकर्ता की ज़िम्मेदारी है।`,
      disclaimer_list_7: `इस वेबसाइट का उपयोग करके, आप इस अस्वीकरण की शर्तों से सहमत होते हैं और समझते हैं कि फाइनेंस ऑप्टिमाइज़ इसकी सामग्री के उपयोग से होने वाले किसी भी नुकसान, क्षति या परिणाम के लिए कोई दायित्व नहीं मानता है।`,
      disclaimer_quote: `यह सामग्री केवल सूचनात्मक उद्देश्यों के लिए है और पेशेवर वित्तीय सलाह नहीं है। वित्तीय निर्णय लेने से पहले किसी विशेषज्ञ से सलाह लें।`,
      listen_to_audio_blogs: "ऑडियो ब्लॉग सुनें",
      click_on_music_icon_and_enjoy_music_or_podcast: "संगीत आइकन पर क्लिक करें और ब्लॉग या पॉडकास्ट का आनंद लें",
      more_articles: "अधिक लेख",
      latest_articles: "नवीनतम लेख",
      discover_the_most_outstanding_articles_in_all_topics_of_finance: "वित्त के सभी विषयों में सबसे उत्कृष्ट लेख खोजें।",
      more_tags: "अधिक टैग",
      trending_topic: "प्रवृत्ति विषयें",
      discover_authors: "लेखकों की खोज करें",
      popular_posts: "लोकप्रिय लेख",
      all_rights_reserved: "सर्वाधिकार सुरक्षित।",
      submit: "सबमिट करें",
      cancel: "रद्द करें",
      reply: "उत्तर दें",
      edit: "संपादन करें",
      report: "प्रतिवेदन करें",
      delete: "मिटाना",
      processing: "प्रक्रिया की जा रही",
      related_posts: "संबंधित पोस्ट",
      more_from_author: "लेखक के और लेख",
      join_our_newsletter: "हमारे न्यूज़लेटर से जुड़ें 🎉",
      newsletter_desc: "किसी भी विषय पर नए दृष्टिकोण पढ़ें और साझा करें। सभी का स्वागत है।",
      newsletter_list_1: "ब्लॉग तक शीघ्र पहुंच",
      newsletter_list_2: "उपकरण एवं संसाधन",
      contact_us: "हमसे संपर्क करें",
      contact_us_desk: "हमें संदेश छोड़ो और हम आपसे संपर्क करेंगे।",
      full_name: "पूरा नाम",
      email: "मेल",
      address: "पता",
      email_address: "मेल पता",
      message: "संदेश",
      send_message: "संदेश भेजें",
      follow: "अनुसरण करें",
      articles: "सामग्री",
      favorites: "पसंदीदा",
      saved: "सुरक्षित किया हुआ",
      most_recent: "सबसे हाल का",
      curated_by_admin: "व्यवस्थापक द्वारा क्यूरेट किया गया",
      most_appreciated: "सर्वाधिक प्रशंसित",
      most_discussed: "सर्वाधिक चर्चित",
      most_viewed: "सबसे ज्यादा देखा गया",
      show_me_more: "अधिक देखे",
      top_trending_topics: "टॉप ट्रेंडिंग टॉपिक",
      top_trending_topics_desc: "100 से अधिक विषयों की खोज करें",
      top_elite_authors: "शीर्ष संभ्रांत लेखक",
      top_elite_authors_desc: "हमारे विशिष्ट लेखकों की खोज करें",
      sign_up: "साइन अप करें",
      sign_up_desc: "हमारे ब्लॉग पत्रिका समुदाय में आपका स्वागत है",
      Password: "पासवर्ड",
      sign_up_caption: "क्या आपके पास पहले से एक खाता मौजूद है?",
      sign_in: "दाखिल करें",
      forgot_password: "पासवर्ड भूल गए",
      forgot_password_caption: "वापिस जायें",
      forgot_password_subtitle: "हमारे वित्तीय ब्लॉगिंग समुदाय में आपका स्वागत है",
      reset_password: "पासवर्ड भूल गए",
      reset_password_caption: "वापिस जायें",
      reset_password_subtitle: "हमारे वित्तीय ब्लॉगिंग समुदाय में आपका स्वागत है",
      sign_in_desc_1: "नया सदस्य?",
      sign_in_desc_2: "सदस्य बनें",
      continue: "जारी रखें",
      continue_with_google: "Google के साथ जारी रखें",
      or: "या",
      socials: "सामाजिक",
      theme_dark: "डार्क थीम",
      help: "मदद",
      guest_user: "अतिथि उपयोगकर्ता",
      my_account: "मेरा खाता",
      my_posts: "मेरी पोस्ट",
      wishlist: "इच्छा-सूची",
      sign_out: "लॉग आउट",
      about_us: "👋 हमारे बारे में|",
      about_us_heading:`वित्तीय सशक्तिकरण और साक्षरता की ओर यात्रा में आपके विश्वसनीय साथी, <strong>फाइनेंस ऑप्टिमाइज़</strong> में आपका स्वागत है।`,
      about_us_subheading: `हमारा मिशन व्यक्तिगत वित्त को रहस्य से मुक्त करना और ऐसी व्यावहारिक जानकारी प्रदान करना है जो भारत में व्यक्तियों और परिवारों, विशेष रूप से मध्यम और उच्च वर्ग के परिवारों को अपने वित्तीय भविष्य पर नियंत्रण रखने में मदद करे। हमारा मानना ​​है कि वित्तीय ज्ञान अवसरों को अनलॉक करने, जीवन के लक्ष्यों को प्राप्त करने और स्थायी सुरक्षा का निर्माण करने की कुंजी है।`,
      email_error_1: "ईमेल आवश्यक है!",
      email_error_2: "कृपया एक मान्य ईमेल पता प्रविष्ट करें!",
      password_error_1: "पासवर्ड आवश्यक है!",
      password_error_2: "पासवर्ड कम से कम 6-14 अक्षर लंबा होना चाहिए!",
      password_error_3: "पासवर्ड में कम से कम एक नंबर, निचला अक्षर, बड़ा अक्षर और एक विशेष अक्षर होना चाहिए!",
      name_error_1: "पूरा नाम आवश्यक है!",
      name_error_2: "पूरा नाम कम से कम 3 से 15 अक्षर लंबा होना चाहिए!",
      comment_error_1: "कृपया एक वैध टिप्पणी दर्ज करें!",
      message_error_1: "संदेश आवश्यक है!",
      message_error_2: "संदेश कम से कम 6-500 अक्षर लंबा होना चाहिए!",
      Success: "सफलता",
      Error: "गलती",
      authors_data_fetched_successfully: "लेखकों का डेटा सफलतापूर्वक लाया गया!",
      authors_data_fetched_error_message: "लेखक लाने में विफल! कृपया बाद में पुन: प्रयास करें।",
      posts_data_fetched_successfully: "पोस्ट डेटा सफलतापूर्वक प्राप्त हुआ!",
      posts_data_fetched_error_message: "पोस्ट लाने में विफल. कृपया बाद में पुन: प्रयास करें।",
      posts_data_like_successfully: "पोस्ट सफलतापूर्वक पसंद की गईं!",
      posts_data_like_error_message: "पोस्ट लाइक करने में विफल| कृपया बाद में पुन: प्रयास करें।",
      posts_bookmarked_successfully: "पोस्ट सफलतापूर्वक बुकमार्क की गईं!",
      posts_bookmarked_error_message: "पोस्ट को बुकमार्क करने में विफल. कृपया बाद में पुन: प्रयास करें।",
      comments_fetched_successfully: "वर्तमान पोस्ट के लिए सभी टिप्पणियाँ सफलतापूर्वक प्राप्त हो गईं!",
      comments_fetched_error_message: "पोस्ट टिप्पणियाँ लाने में विफल| कृपया बाद में पुन: प्रयास करें।",
      comments_add_successfully: "टिप्पणी सफलतापूर्वक पोस्ट में जोड़ी गई!",
      comments_add_error_message: "पोस्ट में टिप्पणी जोड़ने में विफल. कृपया बाद में पुन: प्रयास करें।",
      comments_update_successfully: "टिप्पणी सफलतापूर्वक अपडेट की गई!",
      comments_update_error_message: "टिप्पणी अपडेट करने में विफल. कृपया बाद में पुन: प्रयास करें।",
      comments_delete_successfully: "टिप्पणी सफलतापूर्वक हटा दी गई!",
      comments_delete_error_message: "टिप्पणी हटाने में विफल| कृपया बाद में पुन: प्रयास करें।",
      comments_reply_add_successfully: "पोस्ट टिप्पणी का उत्तर सफलतापूर्वक जोड़ा गया!",
      comments_reply_add_error_message: "पोस्ट टिप्पणी में उत्तर जोड़ने में विफल| कृपया बाद में पुन: प्रयास करें।",
      comments_reply_update_successfully: "पोस्ट टिप्पणी का उत्तर सफलतापूर्वक अपडेट किया गया!",
      comments_reply_update_error_message: "पोस्ट टिप्पणी का उत्तर अपडेट करने में विफल। कृपया बाद में पुन: प्रयास करें।",
      comment_like_successfully: "आपने टिप्पणी पसंद की|",
      comment_like_error_message: "टिप्पणी पसंद नहीं की जा सकी। कृपया बाद में पुनः प्रयास करें।",
      newsletter_subscribed_successfully: "आपने हमारे न्यूज़लेटर की सफलतापूर्वक सदस्यता ले ली है!",
      newsletter_subscribed_error_message: "आपको हमारे न्यूज़लेटर की सदस्यता देने में विफल। कृपया बाद में पुनः प्रयास करें।",
      newsletter_unsubscribed_successfully: "आपने हमारे न्यूज़लेटर की सदस्यता सफलतापूर्वक रद्द कर दी है!",
      newsletter_unsubscribed_error_message: "हमारे न्यूज़लेटर की सदस्यता समाप्त करने में विफल। कृपया बाद में पुनः प्रयास करें।",
      member_login_success: "सफलतापूर्वक लॉग इन किया गया|",
      member_login_error: "कृपया बाद में पुन: प्रयास करें।.",
      member_logout_success: "आप सफलतापूर्वक लॉग आउट हो गए हैं",
      member_logout_error: "कृपया बाद में पुन: प्रयास करें।.",
      member_register_success: "बधाई हो, आपका खाता सफलतापूर्वक बना दिया गया है",
      member_register_error: "हम आपके खाते पंजीकृत करने में असमर्थ हैं। कृपया बाद में पुनः प्रयास करें।",
      enquiry_submit_success: "आपकी पूछताछ सफलतापूर्वक सबमिट की गई है!",
      enquiry_submit_error: "आपकी पूछताछ सबमिट करने में विफल। कृपया बाद में पुनः प्रयास करें।",
      cayegory_fetched_success: "श्रेणी डेटा सफलतापूर्वक प्राप्त किया गया!",
      cayegory_fetched_error: "श्रेणी प्राप्त करने में विफल. कृपया बाद में पुन: प्रयास करें.",
      delete_comment: "टिप्पणी हटाएँ",
      delete_comment_message: "क्या आप वाकई इस टिप्पणी को हटाना चाहते हैं? आप इस कार्रवाई को पूर्ववत नहीं कर सकते।",
      editing_comment: "टिप्पणी संपादित करें.",
      report_abuse: "दुरुपयोग होने की सूचना दें",
      Violence: "हिंसा",
      Trouble: "मुश्किल",
      Spam: "अवांछित ईमेल",
      Other: "अन्य",
      Message: "संदेश",
      message_caption: "कृपया कोई अतिरिक्त जानकारी या संदर्भ प्रदान करें जो हमें स्थिति को समझने और उससे निपटने में मदद करेगी।",
      most_bookmarked: "सर्वाधिक बुकमार्क किया गया",
      Response: "प्रतिक्रिया",
      no_comments_available: "कोई टिप्पणी उपलब्ध नहीं",
      Budgeting: "बजट",
      Investing: "निवेश",
      Personal_Finance: "व्यक्तिगत वित्त",
      Money_Saving_Tips: "पैसे बचाने के सुझाव",
      Retirement_Planning: "सेवानिवृत्ति योजना",
      Debt_Management: "ऋण प्रबंधन",
      Financial_Independence: "वित्तीय स्वतंत्रता",
      Tax_Planning: "कर नियोजन",
      Compound_Interest: "चक्रवृद्धि ब्याज",
      Side_Hustles: "साइड हसल्स",
      Financial_Planning_Basics: "वित्तीय योजना की मूल बातें",
      Insurance: "बीमा",
      Categories_Title: "अन्य श्रेणियां खोजें",
      other_categories: "अन्य श्रेणियाँ",
      replies: "जवाब",
      no_article_found_message: "हमें इस श्रेणी में इस लेखक के लिए कोई लेख नहीं मिला।",
      min_read: "मिनट पढ़े",
      Read_more: " और पढ़े",
      Facebook: "फेसबुक",
      Twitter: "ट्विटर",
      Linkedin: "लिंक्डइन",
      Instagram: "इंस्टाग्राम",
      Whatsapp: "व्हाट्सएप",
      Threads: "थ्रेड्स",
      YouTube: "यूट्यूब",
      author_main_desk: "फाइनेंस ऑप्टिमाइज़ डेस्क",
      Editorial_Desk: "संपादकीय डेस्क",
      author_desc: "फाइनेंस ऑप्टिमाइज़ एक ब्लॉगिंग प्लेटफ़ॉर्म है जहाँ हम वित्तीय साक्षरता और धन प्रबंधन के बारे में आसानी से समझने योग्य और आकर्षक सामग्री प्रदान करते हैं। हमारा लक्ष्य भारतीय परिवारों को वित्तीय स्वतंत्रता प्राप्त करने और हमारे ब्लॉग के माध्यम से स्मार्ट मनी चालों को समझने में मदद करना है। प्रत्येक पोस्ट में हम बजट, निवेश और वित्तीय वृद्धि जैसे महत्वपूर्ण विषयों को विस्तार से कवर करते हैं, ताकि आप अपने पैसे का बुद्धिमानी से प्रबंधन कर सकें। फाइनेंस ऑप्टिमाइज़ के साथ अपनी वित्तीय यात्रा को सशक्त बनाएं और अपने सपनों को साकार करें।",
      a: "अ",
      no_data_found: "हमें कुछ नहीं मिला! बाद में फिर से जाँचें।",
      Hide_comments: "टिप्पणियाँ छिपाएँ",
      View_all_comments: "सभी टिप्पणियाँ देखें",
      WRITTEN_BY: "द्वारा लिखित",
      Add_to_discussion : "चर्चा में जोड़ें",
      copylink: "लिंक की प्रतिलिपि करें",
      reportThisArticle: "इस आलेख की रिपोर्ट करें",
      hideThisAuthor: "इस लेखक को छिपाएँ",
      commentThisArticle: "इस लेख पर टिप्पणी करें",
      disclaimer_subtext: "यह सामग्री केवल सूचनात्मक उद्देश्यों के लिए है और पेशेवर वित्तीय सलाह नहीं है। वित्तीय निर्णय लेने से पहले किसी विशेषज्ञ से सलाह लें।",
      illustration_content: "स्वतंत्र लेखन और सामग्री निर्माण चित्रण:",
    },
  },
  hinglish: {
    translation: {
      language: "Bhasha",
      home: "Home",
      about: "Parichay",
      contact: "Sampark Karein",
      demo: "Demo",
      disclaimer: "Disclaimer",
      disclaimer_list_1: `<strong>Keval shaikshik uddeshy:</strong> <strong>Finance optimize</strong> par pradaan kee gaee jaanakaaree keval shaikshik aur soochanaatmak uddeshyon ke lie hai aur ise vitteey, nivesh ya kaanoonee salaah ke roop mein nahin maana jaana chaahiye.`,
      disclaimer_list_2: `<strong>Peshevar salaahakaar seva nahin:</strong> Ham pramaanit vitteey yojanaakaar, salaahakaar ya nivesh dalaal nahin hain. is website kee saamagree ke aadhaar par kie gae koee bhee nirnay pooree tarah se upayogakarta ke vivek aur jokhim par hain. vyaktigat vitteey salaah ke lie, krpaya kisee laisens praapt peshevar se paraamarsh len.`,
      disclaimer_list_3: `<strong>Nivesh jokhim:</strong> Sabhee niveshon mein jokhim hota hai, jisamen mooladhan kee sambhaavit haani bhee shaamil hai. pichhala pradarshan bhavishy ke parinaamon ka sanket nahin hai. paathakon ko koee bhee nivesh nirnay lene se pahale apana svayan ka shodh karana chaahie ya kisee peshevar se salaah lenee chaahie.`,
      disclaimer_list_4: `<strong>Soochana ki sateekata:</strong> Jabaki ham sateekata sunishchit karane ka prayaas karate hain, ham pradaan kee gaee jaanakaaree kee poornata, vishvasaneeyata ya upayuktata kee gaarantee nahin dete hain. phainens optimaiz pradaan kee gaee jaanakaaree mein kisee bhee truti, chook ya deree ke lie uttaradaayee nahin hoga.`,
      disclaimer_list_5: `<strong>Sahabaddh link aur vigyaapan:</strong> Is website mein sahabaddh link, vigyaapan ya praayojit saamagree shaamil ho sakatee hai. ye hamaaree raay ya siphaarishon ko prabhaavit nahin karate hain. ham kisee bhee vigyaapit utpaad ya seva se judane se pahale apana khud ka shodh karane kee salaah dete hain.`,
      disclaimer_list_6: `<strong>Sthaaneey kaanoon aur viniyam:</strong> Vitteey prathaen aur kaanoon kshetr ke anusaar alag-alag hote hain. laagoo vitteey viniyamon ka anupaalan sunishchit karana upayogakarta kee zimmedaaree hai.`,
      disclaimer_list_7: `Is website ka upayog karake, aap is asveekaran kee sharton se sahamat hote hain aur samajhate hain ki phainens optimaiz isakee saamagree ke upayog se hone vaale kisee bhee nukasaan, kshati ya parinaam ke lie koee daayitv nahin maanata hai.`,
      disclaimer_quote: `Yah saamagree keval soochanaatmak uddeshyon ke lie hai aur peshevar vitteey salaah nahin hai. vitteey nirnay lene se pahale kisee visheshagy se salaah len.`,
      listen_to_audio_blogs: "Audio blogs sunein",
      click_on_music_icon_and_enjoy_music_or_podcast: "Sangeet icon par click karein aur blog ya podcast ka aanand lein",
      more_articles: "Adhik lekh",
      latest_articles: "Naveenatam lekh",
      discover_the_most_outstanding_articles_in_all_topics_of_finance: "Jeevan ke sabhee vishayon mein sabse utkrsht lekh khojen.",
      more_tags: "Adhik tag",
      trending_topic: "Pravrtti vishayen.",
      discover_authors: "Lekhakon ki khoj karen",
      popular_posts: "Lokapriy lekh",
      all_rights_reserved: "Sarvaadhikaar Surakshit.",
      submit: "Submit karen",
      cancel: "Cancel karen",
      reply: "Uttar den",
      edit: "Sampaadan karen",
      report: "Prativedan karen",
      delete: "Mitaana",
      processing: "Prakriya kee ja rahee",
      related_posts: "Sambandhit post",
      more_from_author: "Lekhak ke aur lekh",
      join_our_newsletter: "hamaare newsletter se juden 🎉",
      newsletter_desc: "Kisi bhi vishay ke bare me naye drishtikon se padhen aur share karein. Sabhi ka swagat hai.",
      newsletter_list_1: "Blog ko jaldi access karein",
      newsletter_list_2: "Tools & Resources",
      contact_us: "Hamse sampark karein",
      contact_us_desk: "Humen message bhejen aur hum aapse sampark karenge.",
      full_name: "Full name",
      email: "Email",
      address: "Address",
      email_address: "Email address",
      message: "Message",
      send_message: "Message Bhejein",
      follow: "Follow",
      articles: "Articles",
      favorites: "Pasandeeda",
      saved: "Saved",
      most_recent: "Haal hi ka",
      curated_by_admin: "Admin dwara nirmit",
      most_appreciated: "Adhikatr prashanshit",
      most_discussed: "Sarvadhik charchit",
      most_viewed: "Sarvadhik dekha gya",
      show_me_more: "Mujhe aur dikhaen",
      top_trending_topics: "Top trending vishay",
      top_trending_topics_desc: "100 se adhik vishayon ke bare me jaanein",
      top_elite_authors: "Top elite lekhak",
      top_elite_authors_desc: "Humare elite writers ke bare me jaanein ",
      sign_up: "Sign up",
      sign_up_desc: "Humare blog magazine Community me aapaka swagat hai",
      Password: "Password",
      sign_up_caption: "Pehle se hi account hai?",
      sign_in: "Sign In karein",
      forgot_password: "Password bhool gye",
      forgot_password_caption: "Wapas jaen",
      forgot_password_subtitle: "Humare financial blogging Community me aapaka swagat hai",
      reset_password: "Password reset karein",
      reset_password_caption: "Wapas jaen",
      reset_password_subtitle: "Humare financial blogging Community me aapaka swagat hai",
      new_password: "Naya Password",
      confirm_new_password: "Naya Password Confirm karein",
      sign_in_desc_1: "Naya Sadasy?",
      sign_in_desc_2: "Sadasy banen",
      continue: "Jari rakhein",
      continue_with_google: "Google ke sath jari rakhen",
      or: "Ya",
      socials: "SOCIALS",
      theme_dark: "Dark theme",
      help: "Madad",
      guest_user: "Guest User",
      my_account: "My Account",
      my_posts: "My Posts",
      wishlist: "Ichcha soochi",
      sign_out: "Log Out",
      about_us: "👋 Humare bare me.",
      about_us_heading:`Vittiy sashaktikaran aur saaksharata kee or yaatra mein aapake vishvasaneey saathee, <strong>Finance optimize</strong> mein aapaka swaagat hai.`,
      about_us_subheading: `Hamaara mishan vyaktigat vitt ko rahasy se mukt karana aur aisee vyaavahaarik jaanakaaree pradaan karana hai jo bhaarat mein vyaktiyon aur parivaaron, vishesh roop se madhyam aur uchch varg ke parivaaron ko apane vitteey bhavishy par niyantran rakhane mein madad kare. hamaara maanana hai ki vitteey gyaan avasaron ko analok karane, jeevan ke lakshyon ko praapt karane aur sthaayee suraksha ka nirmaan karane kee kunjee hai.`,
      email_error_1: "Email aavashyk hai!",
      email_error_2: "Kripya ek sahi email address likhein!",
      password_error_1: "Password aavashyk hai!",
      password_error_2: "Password kam se kam 6-14 characters ka hona chahiye!",
      password_error_3: "Password me kam se kam ek number, lowercase, upppercase aur ek vishesh akshar hona chahiye!",
      name_error_1: "Full Name aavashyk hai!",
      name_error_2: "Full name kam se kam 3 characters ka hona chahiye!",
      comment_error_1: "Kripaya many comment darj karein!",
      message_error_1: "Message aavashyk hai!",
      message_error_2: "Message kam se kam 6 se 500 characters ka hona chahiye!",
      Success: "Success",
      Error: "Error",
      authors_data_fetched_successfully: "Lekhak ka Data prapta ho gya hai!",
      authors_data_fetched_error_message: "Lekhak prapta karane me viphal. Kripya baad me dobara koshish karein.",
      posts_data_fetched_successfully: "Post Data prapta ho gya hai!",
      posts_data_fetched_error_message: "Post prapta karane me viphal. Kripya baad me dobara koshish karein.",
      posts_data_like_successfully: "Post pasand ho gayi hai!",
      posts_data_like_error_message: "Post pasand nahi kiyaa ja saka. Kripya baad me dobara koshish karein.",
      posts_bookmarked_successfully: "Posts bookmarked ho gayi hai!",
      posts_bookmarked_error_message: "Posts bookmark karane me viphal. Kripya baad me dobara koshish karein.",
      comments_fetched_successfully: "Vartman post ke liye sabhi comments saphalatapoorvak prapta kiye gye!",
      comments_fetched_error_message: "Post comments prapta karane me viphal. Kripya baad me dobara koshish karein.",
      comments_add_successfully: "Post me comment jod diyaa gya hai!",
      comments_add_error_message: "Post me comment jodne me viphal. Kripya baad me dobara koshish karein.",
      comments_update_successfully: "Comment update ho gya hai!",
      comments_update_error_message: "Comment update nahi ho saka. Kripya baad me dobara koshish karein.",
      comments_delete_successfully: "Comment deleted successfully!",
      comments_delete_error_message: "Comment htane me viphal. Kripya baad me dobara koshish karein.",
      comments_reply_add_successfully: "Post comment ka jawab jod diyaa gya hai!",
      comments_reply_add_error_message: "Post comment me jawab jodane me viphal raha. Kripya baad me dobara koshish karein.",
      comments_reply_update_successfully: "Post comment ka jawab update ho gya hai!",
      comments_reply_update_error_message: "Post comment ka jawab update nahi ho saka. Kripya baad me dobara koshish karein.",
      comment_like_successfully: "Aapane comment pasand ki hai!",
      comment_like_error_message: "Comment pasand karane me viphal. Kripya baad me dobara koshish karein.",
      newsletter_subscribed_successfully: "Aapane hmare newsletter ko subscribe kar liya hai!",
      newsletter_subscribed_error_message: "Aapako humare newsletter subscription par subscribe nahi kiyaa ja saka. Kripya baad me dobara koshish karein.",
      newsletter_unsubscribed_successfully: "Aapane humare newsletter ko unsubscribe kar diya hai!",
      newsletter_unsubscribed_error_message: "Humare newsletter subscription se aapako unsubscribe nahi kiyaa ja saka. Kripya baad me dobara koshish karein.",
      member_login_success: "Login ho gya hai",
      member_login_error: "Kripya baad me dobara koshish karein.",
      member_logout_success: "Aapane log out kar liya hai",
      member_logout_error: "Kripya baad me dobara koshish karein.",
      member_register_success: "Badhaai Ho, aapaka account bana diyaa gya hai",
      member_register_error: "Ham aapke account ko register nahi kar pa rahe hain. Kripya baad me dobara koshish karein.",
      enquiry_submit_success: "Aapaki poochatach Submit ho gyi hai!",
      enquiry_submit_error: "Aapaki poochatach Submit nahi ho saki. Kripya baad me dobara koshish karein.",
      cayegory_fetched_success: "Category Data prapta ho gya hai!",
      cayegory_fetched_error: "Category prapta nahi ho saki. Kripaya baad me dobara koshish karein.",
      delete_comment: "Comment mitaye",
      delete_comment_message: "kya aap wakee is comment ko hatana chahte hain? Aap is kriyaa ko undo nahi kar sakate hain.",
      editing_comment: "Comment sampadit kiya ja raha hai",
      report_abuse: "Durupayog hone ki suchana den",
      Violence: "Hinsa",
      Trouble: "Pareshani",
      Spam: "Spam",
      Other: "Other",
      Message: "Message",
      message_caption: "Kripaya koi atirikt jankari ya sandharbha pradaan karein jo hmen Sthiti ko samajhne aur sambhalane me madad karega.",
      most_bookmarked: "Sabase adhik bookmark",
      Response: "Pratikriya",
      no_comments_available: "Koi comment available nhi hai.",
      Budgeting: "Budget banana",
      Investing: "Nivesh",
      Personal_Finance: "Personal Finance",
      Money_Saving_Tips: "Money-Saving Tips",
      Retirement_Planning: "Retirement Planning",
      Debt_Management: "Karj prabandhan",
      Financial_Independence: "Financial Independence",
      Tax_Planning: "Tax Planning",
      Compound_Interest: "Compound byaj",
      Side_Hustles: "Side Hustles",
      Financial_Planning_Basics: "Financial Planning Basics",
      Insurance: "Insurance",
      Categories_Title: "Anya categories khoje",
      other_categories: "Anya Categories",
      replies: "replies",
      no_article_found_message: "Hume is lekhak ke liye is category me koi article nahi mila.",
      min_read: "min padhe",
      Read_more: "Aur padhe",
      Facebook: "Facebook",
      Twitter: "Twitter",
      Linkedin: "Linkedin",
      Instagram: "Instagram",
      Whatsapp: "Whatsapp",
      Threads: "Threads",
      YouTube: "YouTube",
      author_main_desk: "Finance Optimize Desk",
      Editorial_Desk: "Sampadakiy desk",
      author_desc: "Finance Optimize ek blogging platform hai jahan hum financial literacy aur money management ke baare mein easy-to-understand aur engaging content provide karte hain. Humara goal hai ki humari blogs ke zariye Indian families ko financial freedom aur smart money moves samajhne mein madad mile. Har post mein hum budgeting, investments, aur financial growth jaise important topics ko detail mein cover karte hain, taaki aap apne paise ko intelligently manage kar sakein. Finance Optimize ke saath apne financial journey ko empower karein aur apne sapne ko reality banayein.",
      a: "a",
      no_data_found: "Hamen kuchh nahin mila! Baad mein phir se jaanchen.",
      Hide_comments: "Comments chhipayen",
      View_all_comments: "Sabhee comments dekhen",
      WRITTEN_BY: "Dwara likhit",
      Add_to_discussion : "Charcha mein joden",
      copylink: "Link copy karen",
      reportThisArticle: "Is post ko report karen",
      hideThisAuthor: "Lekhak ko chhipayen",
      commentThisArticle: "Artical par comment karein",
      disclaimer_subtext: "Yah saamagree keval soochanaatmak uddeshyon ke lie hai aur peshevar vitteey salaah nahin hai. Vittiy nirnay lene se pahale kisee visheshagy se salaah len.",
      illustration_content: "Svatantr lekhan aur saamagree nirmaan chitran:",
    },
  },
};

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // Initialize React bindings
  .init({
    resources,
    fallbackLng: 'en', // default language
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'], // Store language in localStorage
    },
  });

export default i18next;
