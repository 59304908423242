import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

//project imports
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Heading2 from "components/Heading/Heading2";
import Layout from "../layout";
import { useMemberContext } from "context/memberContext";
import { LoginRequest } from "api/api";
import LoginSkeleton from "components/Skeleton/LoginSkeleton";
import { GoogleAnalyticsEnum, pageView, triggerFunction } from "utils/googleAnalytics";
import NcLink from "components/NcLink/NcLink";
import { MANUAL } from "constants/utils";

// third-party
import { useTranslation } from "react-i18next";

const PageLogin = ({ hideSubscribe , onClose = () => {} }: { hideSubscribe?: boolean; onClose?: () => void }) => {

  const { t } = useTranslation();
  // const loginSocials = [
  //   {
  //     name: t("continue_with_google"),
  //     href: "#",
  //     icon: googleSvg,
  //   },
  // ];

  //stat variables
  const { loginMember, isLoading } = useMemberContext();
  const [newMember, setNewMember] = useState<LoginRequest>({ email: "", password: "" });
  const [valdiationError, setValidationError] = useState<LoginRequest>({ email: "", password: "" });

  const navigate = useNavigate();

  // Track page view for Login Page
  useEffect(() => {
    pageView(window.location.pathname, GoogleAnalyticsEnum.LOGIN_PAGE_VIEW);

  }, []);

  useEffect(() => {
    try {
      if (!hideSubscribe && localStorage.getItem(process.env.REACT_APP_USER_LOCALSTORAGE_KEY as string)) {
        const previousPage = document.referrer;
    
        if (window.history?.length && window.history.length > 1) {
          if (!previousPage) {
            navigate('/', { replace: true });
          } else {
            navigate(-1);
          }
        } else {
          navigate('/', { replace: true });
        }
      }
    } catch (error) {
      console.log("Error while updating the login status => ",error);
      throw error;
    }
  }, [isLoading]);
  

  // handle change function
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name: String = "";
    try {
      let value = e.target.value;
      name = e.target.name;
      let member: LoginRequest = newMember;
      // if (name === "fullName") {
      //   member.fullName = value;
      // } else 
      if (name === "email") {
        member.email = value;
      } else if (name === "password") {
        member.password = value;
      }
      setValidationError(checkValidation(member, name));
      setNewMember(member);
    } catch (error: any) {
      console.log(`Member registeration ${name} error:`, error.message);
      throw error;
    }
  }

  const checkValidation = (member: LoginRequest, name: String) => {
    try {
      let validationErrorObj: LoginRequest = { email: "", password: "" };

      if (name === "all" || name === "email") {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (member && !member.email) {
          validationErrorObj.email = t("email_error_1");
        }
        else if (member && member.email && !emailRegex.test(member.email)) {
          validationErrorObj.email = t("email_error_2");
        } else {
          validationErrorObj.email = "";
        }
      }

      if (name === "all" || name === "password") {
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
        if (member && !member.password) {
          validationErrorObj.password = t("password_error_1");
        }
        else if (member && member.password && (member.password.length < 6 || member.password.length > 14)) {
          validationErrorObj.password = t("password_error_2");
        }
        else if (member && member.password && !(strongPasswordRegex.test(member.password))) {
          validationErrorObj.password = t("password_error_3");
        } else {
          validationErrorObj.password = "";
        }
      }

      setValidationError(validationErrorObj);
      return validationErrorObj;
    } catch (error: any) {
      console.log('Member Validation Error:', error.message);
      throw error;
    }
  }

  const handleLogin = () => {
    try {
      // Validation check for all fields
      let formIsValid = true;
      const validationErrros = checkValidation(newMember, "all");

      if (validationErrros.email || validationErrros.password) {
        formIsValid = false;
      }

      if (formIsValid) {
        const newMemberClone: LoginRequest = newMember
        // Call registerMember action
        loginMember(newMemberClone, MANUAL);
        onClose && onClose();
      }
    } catch (error: any) {
      console.log('Member Login Error:', error.message);
      throw error;
    }
  }

  // Handle tracking button click with descriptive names
  const handleClickAnalytics = (type: String, name: String) => {
    try {
      if (type === GoogleAnalyticsEnum.BUTTON) {
        // Tracking button click events in Google Analytics
        triggerFunction(
          GoogleAnalyticsEnum.BUTTON,
          `${GoogleAnalyticsEnum.LOGIN_PAGE_BUTTON}_${(name || "").toUpperCase()}`
        );
      } else if (type === GoogleAnalyticsEnum.LINK) {
        // Tracking the Google login link click in Google Analytics
        triggerFunction(
          GoogleAnalyticsEnum.LINK, // Event category (link click)
          `${GoogleAnalyticsEnum.LOGIN_PAGE_LINK}_${(name || "").toUpperCase()}` // Event action (formatted)
        );
      }
    } catch (error: any) {
      console.log('Google Analytic error while login:', error.message);
      throw error;
    }
  };

  const loginComponent = () => {
    try {
      return isLoading ? <LoginSkeleton /> :
        <>
          <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20 ">
            <Heading2>{t("sign_in")}</Heading2>
            <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
              {t("sign_up_desc")}
            </span>
          </header>
          <div className="max-w-md mx-auto space-y-6">
            {/* FORM */}
            <div className="grid grid-cols-1 gap-6">
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("email_address")}
                </span>
                {/* Skeleton Loader for Email */}
                {isLoading ? (
                  <div className="h-10 w-full bg-gray-200 dark:bg-neutral-700 animate-pulse rounded-md"></div>
                ) : (
                  <Input
                    type="email"
                    name="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    disabled={isLoading}
                    onChange={handleChange}
                    value={newMember.email}
                  />
                )}
                {valdiationError && valdiationError.email && (
                  <p className="text-red-500 text-xs mt-1">{valdiationError.email}</p>
                )}
              </label>
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  {t("Password")}
                  <NcLink href="/forgot-pass" className="text-sm underline"
                  >
                    {t("forgot_password")}?
                  </NcLink>
                </span>

                <Input type="password" name="password" value={newMember.password} className="mt-1" disabled={isLoading} onChange={handleChange} />
                {valdiationError && valdiationError.password && (
                  <p className="text-red-500 text-xs mt-1">{valdiationError.password}</p>
                )}
              </label>
              <ButtonPrimary type="button" onClick={() => {
                handleClickAnalytics(GoogleAnalyticsEnum.BUTTON, GoogleAnalyticsEnum.LOGIN_PAGE_BUTTON);
                handleLogin();
              }}>
                {isLoading ? <span> <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                </svg>
                  <span> {`${t("processing")}...`}</span></span> :
                  t("continue")
                }
              </ButtonPrimary>
            </div>

            {/* ==== */}
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              {t("sign_in_desc_1")} {` `}
              <NcLink href="/signup">{t("sign_in_desc_2")}</NcLink>
            </span>
          </div>
        </>
    } catch (error: any) {
      console.log('Login component Error:', error.message);
      throw error;
    }
  }

  return (
    hideSubscribe ?
      <>
        {loginComponent()}
      </>
      :
      <Layout>
        {loginComponent()}
      </Layout>
  );
};

export default PageLogin;
