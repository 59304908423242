import React, { FC, useEffect, useState } from "react";

//project-import 
import { GoogleAnalyticsEnum, pageView, triggerFunction } from "utils/googleAnalytics";
import { useMemberContext } from "context/memberContext";
import { usePostContext } from "context/postContext";
import { PostBookmarkDataType, PostDataType } from "data/types";
import ModalLogin from "components/ModalLogin/ModalLogin";

export interface NcBookmarkProps {
  containerClassName?: string;
  bookmarked?: boolean;
  post?: PostDataType;
}

const NcBookmark: FC<NcBookmarkProps> = ({
  containerClassName = "h-8 w-8 bg-neutral-50 hover:bg-neutral-100 dark:bg-neutral-800 dark:hover:bg-neutral-700",
  bookmarked = false,
  post
}) => {

  // read state from store
  const { addPostBookmark, isLoading } = usePostContext();
  const { currentMember } = useMemberContext();
  const member = localStorage.getItem(process.env.REACT_APP_USER_LOCALSTORAGE_KEY as string) ? currentMember : null;
  // let isBookmarkDisabled = isLoading || (currentMember && currentMember.id ? false : true);
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  // Construct bookmark data
  const currentUserId = currentMember ? currentMember.id || "" : "";
  const openModalLogin = () => setIsLoggedOut(true);
  const closeModalLogin = () => setIsLoggedOut(false);

  const handleBookmarkBtnClick = (value: boolean) => {
    try {
      let bookmarks: PostBookmarkDataType[] = post ? post.bookmark || [] : [];
      if (!member) {
        return openModalLogin();
      }
      const index = bookmarks.findIndex((item) => item.userId === currentUserId);
      if (index !== -1) {
        bookmarks = bookmarks.map((bookmark, i) =>
          i === index
            ? {
              ...bookmark,
              userId: currentUserId,
              count: value ? 1 : 0,
              isBookmarked: value
            }
            : bookmark
        );
      } else {
        bookmarks = [
          ...bookmarks,
          {
            userId: currentUserId,
            count: value ? 1 : 0,
            isBookmarked: value
          },
        ];
      }

      if (bookmarks && bookmarks.length) {
        addPostBookmark(
          currentUserId,
          (post ? post.id || "" : "").toString(),
          bookmarks
        );
      }
      triggerFunction(
        GoogleAnalyticsEnum.BUTTON,
        isBookmarked
          ? GoogleAnalyticsEnum.NC_BOOKMARK_REMOVE_BUTTON_CLICK
          : GoogleAnalyticsEnum.NC_BOOKMARK_ADD_BUTTON_CLICK
      );
    } catch (error) {
      console.log("36=>PostCardLikeAndComment=>handleLikeBtnClick=>", error);
    }
  };

  // Check if current user has bookmarked a post
  let isBookmarked: boolean = false;

  if (!isLoading && post) {
    (post ? post.bookmark || [] : []).forEach((item: PostBookmarkDataType) => {
      if (item.userId === currentUserId && item.isBookmarked) {
        isBookmarked = true;
      }
    });
  }

  // Page view tracking for NcBookmark component
  useEffect(() => {
    pageView(window.location.pathname, GoogleAnalyticsEnum.NC_BOOKMARK_PAGE_VIEW);
  }, []);

  return (
    <>
      <button
        className={`nc-NcBookmark relative rounded-full flex items-center justify-center ${containerClassName}`}
        title="Save to reading list"
        // onClick={() => setIsBookmarked(!isBookmarked)} //  original code 
        onClick={() => handleBookmarkBtnClick(!isBookmarked)}
      // disabled={isBookmarkDisabled}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          fill={isBookmarked ? "currentColor" : "none"}
          stroke="currentColor"
          className="w-[18px] h-[18px]"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
          />
        </svg>
        {post?.bookmark && post.bookmark.filter((obj) => obj.userId !== null && obj.isBookmarked).length}
      </button>
      <ModalLogin
        show={isLoggedOut}
        onCloseModalLogin={closeModalLogin}
      />
    </>
  );
};

export default NcBookmark;
