const STORE_LOCALSTORAGE_KEY: string | undefined = process.env.REACT_APP_STORE_LOCALSTORAGE_KEY; // Reading data from environment variable

// Define the state type (adjust according to your state structure)
interface AppState {
  [key: string]: any; // Replace 'any' with a more specific type if possible
}

// getting data from local storage
export const loadState = (): AppState | undefined => {
  try {
    const serialState = localStorage.getItem(STORE_LOCALSTORAGE_KEY || '');
    if (serialState === null) {
      return undefined;
    }
    return JSON.parse(serialState);
  } catch (err) {
    console.error('Error loading state from localStorage:', err);
    return undefined;
  }
};

// setting data inside the local storage
export const saveState = (state: AppState): void => {
  try {
    const serialState = JSON.stringify(state);
    localStorage.setItem(STORE_LOCALSTORAGE_KEY || '', serialState);
  } catch (err) {
    console.error('Error saving state to localStorage:', err);
  }
};
