import React, { FC, useState } from "react";

// project-imports
import { PostCommentDataType } from "data/types";
import CommentCard from "./CommentCard";

// third-party
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

interface CommentReplyDropdownProps {
    comment?: PostCommentDataType;
}
const CommentReplyDropdown: FC<CommentReplyDropdownProps> = ({ comment = {} as PostCommentDataType, }) => {
    const {t} = useTranslation();
    const [display, setDisplay] = useState<boolean>(false);
    const onClick = () => {
        if (display) {
            setDisplay(false);
        } else {
            setDisplay(true);
        }
    }
    return (
        <>
            <button
                type="button"
                className={`h-4 w-full sm:h-12 px-3 py-1.5 inline-flex items-center text-sm text-blue-800 dark:text-neutral-200 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                onClick={onClick}>
                <span className="ml-2">{comment && comment.replies && comment.replies.length > 1 ? `${comment && comment.replies && comment.replies.length} ${t("replies")}` : `1 ${t("replies")}`}</span>
                <ChevronDownIcon
                    className={`ml-1 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                    aria-hidden="true"
                />
            </button>
            {display && comment && comment.replies && comment.replies.map((item, index) => {
                return <div className="pt-2">
                    <CommentCard key={index} comment={item} />
                </div>;
            })}
        </>
    );
};

export default CommentReplyDropdown;
