import React, { useContext, createContext, ReactNode } from 'react';

// third-party
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

// project-imports
import { ActionType } from '../constants/actions';
import { subscribeNewsletter } from 'api/api';
import { showToastAlert } from 'utils/sweetAlert';


// Define the shape of the newsletter state
interface NewsletterState {
    isLoading: boolean;
    subscription: any; // Replace `any[]` with your actual newsletter type
    error: string | null;
}

// Define the interface of the PostBookmark schema
export interface NewsletterSubscription {
    email: string;
}

// Define the shape of the context value
interface NewsletterContextType extends NewsletterState {
    subscribe: (subscription: NewsletterSubscription) => Promise<void>;
    unsubscribe: (subscription: NewsletterSubscription) => Promise<void>;
}

// Define props for the provider
interface NewsletterProviderProps {
    children: ReactNode;
    store: any
}

// Initialize the context
export const NewsletterContext = createContext<NewsletterContextType | undefined>(undefined);

export const NewsletterProvider: React.FC<NewsletterProviderProps> = ({ children }) => {
    const { t } = useTranslation();
    const { SUBSCRIBE_NEWSLETTER_BEGIN, SUBSCRIBE_NEWSLETTER_SUCCESS, SUBSCRIBE_NEWSLETTER_FAILURE, UNSUBSCRIBE_NEWSLETTER_BEGIN, UNSUBSCRIBE_NEWSLETTER_SUCCESS, UNSUBSCRIBE_NEWSLETTER_FAILURE } = ActionType;
    // Reading state from store
    const state = useSelector((state: { enquiry: NewsletterState }) => state.enquiry);

    // Reading dispatch from Store
    const dispatch = useDispatch();

    const subscribe = async (subscription: NewsletterSubscription): Promise<void> => {
        dispatch({ type: SUBSCRIBE_NEWSLETTER_BEGIN, payload: true });

        try {
            let response: any = {}; // Replace with the actual API response type
            response = await subscribeNewsletter("", subscription) || {};
            dispatch({ type: SUBSCRIBE_NEWSLETTER_SUCCESS, payload: response });
            showToastAlert(`${t("Success")}`, `${t("newsletter_subscribed_successfully")}`, { icon: "success" });
        } catch (error: any) {
            console.error("Error fetching authors:", error);
            dispatch({ type: SUBSCRIBE_NEWSLETTER_FAILURE, payload: error?.message });
            showToastAlert(`${t("Error")}`, `${t("newsletter_subscribed_error_message")}`, { icon: "error" });
        }
    };

    const unsubscribe = async (subscription: NewsletterSubscription): Promise<void> => {
        dispatch({ type: UNSUBSCRIBE_NEWSLETTER_BEGIN, payload: true });

        try {
            let response: any = {}; // Replace with the actual API response type
            response = await subscribeNewsletter("", subscription) || {};
            dispatch({ type: UNSUBSCRIBE_NEWSLETTER_SUCCESS, payload: response });
            showToastAlert(`${t("Success")}`, `${t("newsletter_unsubscribed_successfully")}`, { icon: "success" });
        } catch (error: any) {
            console.error("Error fetching authors:", error);
            dispatch({ type: UNSUBSCRIBE_NEWSLETTER_FAILURE, payload: error?.message });
            showToastAlert(`${t("Error")}`, `${t("newsletter_unsubscribed_error_message")}`, { icon: "error" });
        }
    };

    return (
        <NewsletterContext.Provider value={{ ...state, subscribe, unsubscribe }
        }>
            {children}
        </NewsletterContext.Provider>
    );
};

// Custom hook for using the context
export const useNewsletterContext = (): NewsletterContextType => {
    const context = useContext(NewsletterContext);
    if (!context) {
        throw new Error("useNewsletterContext must be used within a NewsletterProvider");
    }
    return context;
};
