import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//project imports 
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Heading2 from "components/Heading/Heading2";
import Layout from "../layout";
import { useMemberContext } from "context/memberContext";
import { RegisterRequest } from "api/api";
import SignupSkeleton from "components/Skeleton/SignupSkeleton";
import { GoogleAnalyticsEnum, pageView, triggerFunction } from "utils/googleAnalytics";
import NcLink from "components/NcLink/NcLink";
import Image from "components/Image";

//third party 
import { useTranslation } from "react-i18next";


const PageSignUp = () => {
  const { t } = useTranslation();
  // const loginSocials = [
  //   {
  //     name: t("continue_with_google"),
  //     href: "#",
  //     icon: googleSvg,
  //   },
  // ];
  
  //stat variables
  const { registerMember, isLoading, newMember: newMemberStore } = useMemberContext();
  const [newMember, setNewMember] = useState<RegisterRequest>({ fullName: "", email: "", password: "" });
  const [valdiationError, setValidationError] = useState<RegisterRequest>({ fullName: "", email: "", password: "" });

  // Track page view when the page is loaded
  useEffect(() => {
    pageView(window.location.pathname, GoogleAnalyticsEnum.SIGNUP_PAGE_VIEW);
  }, []);

  // handle change function
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name: String = "";
    try {
      let value = e.target.value;
      name = e.target.name;
      let member: RegisterRequest = newMember;
      if (name === "fullName") {
        member.fullName = value;
      } else if (name === "email") {
        member.email = value;
      } else if (name === "password") {
        member.password = value;
      }
      setValidationError(checkValidation(member, name));
      setNewMember(member);
    } catch (error: any) {
      console.log(`Member registeration ${name} error:`, error.message);
      throw error;
    }
  }

  const checkValidation = (member: RegisterRequest, name: String) => {
    try {
      let validationErrorObj: RegisterRequest = { fullName: "", email: "", password: "" };
      if (name === "all" || name === "fullName") {
        if (member && !member.fullName) {
          validationErrorObj.fullName = t("name_error_1");
        }
        else if (member && member.fullName && (member.fullName.length < 3 || member.fullName.length > 16)) {
          validationErrorObj.fullName = t("name_error_2");
        } else {
          validationErrorObj.fullName = "";
        }
      }

      if (name === "all" || name === "email") {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (member && !member.email) {
          validationErrorObj.email = t("email_error_1");
        }
        else if (member && member.email && !emailRegex.test(member.email)) {
          validationErrorObj.email = t("email_error_2");
        } else {
          validationErrorObj.email = "";
        }
      }

      if (name === "all" || name === "password") {
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
        if (member && !member.password) {
          validationErrorObj.password = t("password_error_1");
        }
        else if (member && member.password && (member.password.length < 6 || member.password.length > 14)) {
          validationErrorObj.password = t("password_error_2");
        }

        else if (member && member.password && !(strongPasswordRegex.test(member.password))) {
          validationErrorObj.password = t("password_error_3");
        } else {
          validationErrorObj.password = "";
        }
      }

      setValidationError(validationErrorObj);
      return validationErrorObj;
    } catch (error: any) {
      console.log('Member Validation Error:', error.message);
      throw error;
    }
  }

  const handleRegister = () => {
    try {
      // Validation check for all fields
      let formIsValid = true;
      const validationErrros = checkValidation(newMember, "all");

      if (validationErrros.fullName || validationErrros.email || validationErrros.password) {
        formIsValid = false;
      }

      if (formIsValid) {
        const newMemberClone: RegisterRequest = newMember
        // Call registerMember action
        registerMember(newMemberClone);
      }
    } catch (error: any) {
      console.log('Member Registration Error:', error.message);
      throw error;
    }
  }

  const navigate = useNavigate()

  if (localStorage.getItem(process.env.REACT_APP_USER_LOCALSTORAGE_KEY as string)) {
    navigate('/')
  } else if (newMemberStore && Object.keys(newMemberStore).length) {
    navigate('/login')
  }

  // Handle social link click
  const handleClickAnalytics = (type: String, name: String) => {
    if (type === GoogleAnalyticsEnum.BUTTON) {
      // Tracking button click events in Google Analytics
      triggerFunction(
        GoogleAnalyticsEnum.BUTTON,
        `${GoogleAnalyticsEnum.SIGNUP_PAGE_BUTTON_REGISTER}_${(name || "").toUpperCase()}`
      );
    } else if (type === GoogleAnalyticsEnum.LINK) {
      // Tracking the Google login link click in Google Analytics
      triggerFunction(
        GoogleAnalyticsEnum.LINK, // Event category (link click)
        `${GoogleAnalyticsEnum.SIGNUP_PAGE_LINK}_${(name || "").toUpperCase()}` // Event action (formatted)
      );
    }
  };

  return (
    <Layout>
      {isLoading ? <SignupSkeleton /> :
        <>
          <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
            <Heading2>{t("sign_up")}</Heading2>
            <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
              {t("sign_up_desc")}
            </span>
          </header>
          <div className="max-w-md mx-auto space-y-6">
            {/* <div className="grid gap-3">
              {loginSocials.map((item, index) => (
                <a
                  key={index}
                  href={item.href}
                  className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                  onClick={() => handleClickAnalytics(GoogleAnalyticsEnum.LINK, "google-login")} // Track Google login link click
                >
                  <Image
                    className="flex-shrink-0"
                    src={item.icon}
                    alt={item.name}
                  />
                  <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                    {item.name}
                  </h3>
                </a>
              ))}
            </div>
             OR 
            <div className="relative text-center">
              <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                {t("or")}
              </span>
              <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
            </div> */}
            {/* FORM */}
            <div className="grid grid-cols-1 gap-6">
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("full_name")}
                </span>
                <Input
                  type="text"
                  name="fullName"
                  placeholder="FO Member"
                  className="mt-1"
                  disabled={isLoading}
                  onChange={handleChange}
                  value={newMember.fullName}
                />
                {valdiationError && valdiationError.fullName && (
                  <p className="text-red-500 text-xs mt-1">{valdiationError.fullName}</p>
                )}
              </label>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("email_address")}
                </span>
                <Input
                  type="email"
                  name="email"
                  placeholder="member@financeoptimize.com"
                  className="mt-1"
                  disabled={isLoading}
                  onChange={handleChange}
                  value={newMember.email}
                />
                {valdiationError && valdiationError.email && (
                  <p className="text-red-500 text-xs mt-1">{valdiationError.email}</p>
                )}
              </label>
              <label className="input-group">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  {t("Password")}
                </span>
                <Input type="password" name="password" className="mt-1" value={newMember.password} disabled={isLoading} onChange={handleChange} />
                {valdiationError && valdiationError.password && (
                  <p className="text-red-500 text-xs mt-1">{valdiationError.password}</p>
                )}
              </label>
              <ButtonPrimary type="button" onClick={() => {
                handleClickAnalytics(GoogleAnalyticsEnum.BUTTON, GoogleAnalyticsEnum.SIGNUP_PAGE_BUTTON_REGISTER); // Track Sign-Up button click
                handleRegister();
              }} className="text-white">
                {isLoading ? <span> <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                </svg>
                  <span> {`${t("processing")}...`}</span></span> :
                  t("continue")
                }
              </ButtonPrimary>
            </div>
            {/* ==== */}
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              {t("sign_up_caption")} {` `}
              <NcLink href="/login" >{t("sign_in")}</NcLink>
            </span>
          </div>
        </>}
    </Layout>
  );
};

export default PageSignUp;
