import React, { FC } from "react";

export interface SkeletonProps {
  className?: string;
}

const SingleAuthorSkeleton: FC<SkeletonProps> = ({ className = "" }) => {
  return (
    <div className="nc-SingleAuthor flex bg-gray-100 p-2 rounded-2 animate-pulse">
      <div>
        <div
          className="h-12 w-12 rounded-full bg-slate-200 text-lg sm:text-xl sm:h-24 sm:w-24"
        ></div>
      </div>
      <div className="flex flex-col ml-3 max-w-lg sm:ml-5">
        <span className="h-4 w-32 bg-slate-200 tracking-wider rounded-2">
        </span>
        <h2 className="h-8 w-72 mt-2 bg-slate-200 rounded-2">
        </h2>
        <span className="block mt-1">
          <div
            className="bg-slate-200 h-8 w-96 ml-1 rounded-2"
          >
          </div>
        </span>
      </div>
    </div>
  );
};

export default SingleAuthorSkeleton;
