import React, { FC } from "react";

export interface SkeletonProps {
  className?: string;
}

const Card4Skeleton: FC<SkeletonProps> = ({ className = "" }) => {
  return (
    <div
    className={`nc-Card4 relative flex flex-col group bg-white dark:bg-neutral-900 rounded-3xl animate-pulse ${className}`}
  >
    <span className="block flex-shrink-0 relative w-full aspect-w-16 aspect-h-9 rounded-t-xl overflow-hidden">
      {/* <Image
        fill
        className="object-cover"
        alt=""
        sizes="(max-width: 600px) 480px, 800px"
        src={featuredImage}
      /> */}
      <div className="object-cover w-480px h-800px max-w-600px bg-slate-300"></div>
    </span>

    <div className="p-4 flex flex-col flex-grow">
      <div className="space-y-2.5 mb-4">
        {/* <CategoryBadgeList categories={categories} /> */}
        <div className="w-20 h-4 bg-slate-300"></div>
        <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100 ">
          {/* <Link href={href} className="line-clamp-2" title={title}>
            {title}
          </Link> */}
          <div className="w-32 h-4 bg-slate-300"></div>
        </h2>
      </div>
      <div className="flex items-end justify-between mt-auto">
        {/* <CardAuthor2 readingTime={readingTime} date={date} author={author} /> */}
        {/* <PostCardSaveAction hidenReadingTime /> */}
        <div className="w-20 h-8 bg-slate-300"></div>
        <div className="w-20 h-8 bg-slate-300"></div>
      </div>
    </div>
  </div>
  );
};

export default Card4Skeleton;
