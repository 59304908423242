import React, { FC } from "react";

//project imports
import TagsSkeleton from "./TagsSkeleton";

export interface SkeletonProps {
    className?: string;
}

const Card9Skeleton: FC<SkeletonProps> = ({ className = "h-full animate-pulse" }) => {
    return (
        <div
            className={`nc-Card9 relative flex flex-col group rounded-3xl overflow-hidden z-0 ${className}`}
        >
            <div className="absolute inset-x-0 top-0 p-3 flex flex-row space-x-5 items-center justify-between">
                <div className=" h-8 w-8 bg-slate-200 rounded-md"></div>
                <div className="w-1 h-8 border-r border-neutral-200"></div>
                <div className=" h-8 w-8 bg-slate-200 rounded-md"></div>
                <div className=" h-8 w-8 bg-slate-200 rounded-md"></div>
                <div className=" h-8 w-8 bg-slate-200 rounded-md"></div>
            </div>
            <div className={`flex items-start relative w-full aspect-w-3 aspect-h-3 sm:aspect-h-4`}></div>
            <div>
                <div
                    className="object-cover w-full h-full bg-slate-200 rounded-3xl max-w-[600px] h-[480px] w-[500px]"
                ></div>
                <div
                    className={`nc-PostTypeFeaturedIcon ${className}`}
                    data-nc-id="PostTypeFeaturedIcon"
                >
                    <span
                        className={`rounded-full flex  items-center justify-center border border-white w-4 h-4 bg-slate-200`}
                    >
                    </span>
                </div>
                <span className="absolute inset-0 bg-slate-200 "></span>
            </div>
            <div
                className="absolute bottom-0 inset-x-0 h-1/2 from-black opacity-50"
            ></div>
            <div className="absolute bottom-0 inset-x-0 p-4 flex flex-col flex-grow">
                <div className="absolute inset-0 bg-slate-200 h-4 w-20"></div>
                <div className="mb-3">
                    <TagsSkeleton />
                </div>
                <div className="inline-flex items-center text-xs text-neutral-300">
                    <div className="block ">
                        <h2 className="block text-base sm:text-lg font-semibold text-white ">
                            <span className="line-clamp-2 bg-slate-200 h-6 w-64">
                            </span>
                        </h2>
                        <div className="flex mt-2.5 relative ">
                            <span className="block text-neutral-200 hover:text-white font-medium truncate bg-slate-200 h-4 w-20">
                            </span>
                            <span className="mx-[6px] font-medium bg-slate-200 ">·</span>
                            <span className="font-normal truncate bg-slate-200 h-4 w-20"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card9Skeleton;
