import React, { FC } from "react";

export interface SkeletonProps {
  className?: string;
}

const SingleCommentFormSkeleton: FC<SkeletonProps> = ({ className = "mt-5 animate-pulse" }) => {
  return (
    <div className={`nc-SingleCommentForm ${className}`}>
      <div className="bg-slate-200 w-full h-20 rounded-lg"></div>
      <div className="flex flex-row mt-2 space-x-3">
        <span className="bg-slate-200 w-32 h-10 rounded-md"></span>
        <span className="bg-slate-200 w-32 h-10 rounded-md"></span>
      </div>
    </div>
  );
};

export default SingleCommentFormSkeleton;
