import React, { useEffect } from "react";

// project-import
import SectionHero from "components/SectionHero/SectionHero";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { GoogleAnalyticsEnum, pageView } from "utils/googleAnalytics";

// third-party
import { useTranslation } from "react-i18next";

// assets
import rightImg from "images/about-hero-right.png";

const PageAbout = () => {

  const { t } = useTranslation();

  useEffect(() => {
    // Tracking page view for ABOUT page on Google Analytics
    pageView(window.location.pathname, GoogleAnalyticsEnum.ABOUT_US);
  }, [])

  const content = () => {
    return <>
      <p className="mt-2 text-left">
        <div dangerouslySetInnerHTML={{ __html: t(`about_us_heading`).replace(/(<? *script)/gi, 'illegalscript') }} ></div>
      </p>
      <p className="mt-2 text-left">
        {t("about_us_subheading")}
      </p>
      {/* <p className="mt-2">At <strong>Finance Optimize</strong>, we focus on:</p>
      <ol className="mt-2">
        <li className="mt-2"><strong>Simplifying Complex Concepts:</strong> Breaking down intricate financial topics into easy-to-understand advice, so you can make informed decisions.</li>
        <li className="mt-2"><strong>Practical Solutions:</strong> Providing tools and techniques, from budgeting tips to investment strategies, that you can apply in real life.</li>
        <li className="mt-2"><strong>Inspiring Growth:</strong> Designing content that motivates you to dream big, set financial goals, and work toward them confidently.</li>
      </ol> */}
      {/* <p className="mt-2">
        Whether you’re planning your dream vacation, saving for your child’s education, or investing for retirement, <strong>Finance Optimize</strong> is here to guide you with credible information and empowering ideas.
      </p> */}
      {/* <p className="mt-2">
        We are more than a blog — we’re a community. Join us as we explore the possibilities of financial freedom together. Let’s optimize your finances and unlock the life you’ve always envisioned!
      </p> */}
    </>
  }

  return (
    <div className={`container`}>
      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container px-2 pt-16 lg:pt-28 ">
        <SectionHero
          rightImg={rightImg}
          heading={t("about_us")}
          btnText=""
          subHeading={content()}
        />

        {/* <SectionFounder /> */}

        <div className="relative">
          <SectionSubscribe2 />
        </div>
      </div>
    </div>
  );
};

export default PageAbout;
