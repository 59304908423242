import React, { FC } from "react";

// third-party
import { PostAuthorType } from "data/types";
import { useTranslation } from "react-i18next";

// project-imports
import CardAuthor from "components/CardAuthor/CardAuthor";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import LoadingVideo from "components/LoadingVideo/LoadingVideo";
import { useAuthorContext } from "context/authorContext";
import { GoogleAnalyticsEnum, triggerFunction } from "utils/googleAnalytics";

export interface WidgetAuthorsProps {
  className?: string;
  authors?: PostAuthorType[];
}

const WidgetAuthors: FC<WidgetAuthorsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  authors = [],
}) => {

  const { t } = useTranslation();
  // Read state from store
  const { isLoading: isAuthorLoading, authors: authorsList } = useAuthorContext();

  authors = authorsList.filter((_, i) => i < 5);

  const handleAuthorClick = (name: string) => {
    triggerFunction(GoogleAnalyticsEnum.HOME, GoogleAnalyticsEnum.SECTION_LATEST_POST_PAGE + "-" + GoogleAnalyticsEnum.WIDGET_AUTHOR_CARD_CLICK + "-" + name)
  }

  return (
    <div
      className={`nc-WidgetAuthors rounded-3xl overflow-hidden ${className}`}
    >
      <WidgetHeading1
        title={`🎭 ${t("discover_authors")}`}
        viewAll={{ label: "", href: "" }}
      />
      <div className="flow-root">

        {/* LoadingVideo if isAuthorLoading is true */}
        {isAuthorLoading && (
          <LoadingVideo />
        )}

        {/* Conditionally render authors only when not loading */}
        {!isAuthorLoading && (
          <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
            {authors && authors.length ?
              (authors || []).map((author) => (
                <CardAuthor
                  className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                  key={author.id}
                  author={author}
                  onClick={handleAuthorClick}
                />
              ))
              :
              <span className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700">{t("no_data_found")}</span>
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default WidgetAuthors;
