import React, { FC } from "react";

export interface SkeletonProps {
  className?: string;
}

const TagsSkeleton: FC<SkeletonProps> = ({ className = "mr-2 mb-2" }) => {
  return (
    <div
      className={`nc-Tag inline-block w-20 h-6 bg-slate-200 rounded-lg md:py-2.5 md:px-4 ${className}`}
    >
    </div>
  );
};

export default TagsSkeleton;
