import React, { FC } from "react";

export interface SkeletonProps {
  className?: string;
  ratio?: string;
}

const Card18Skeleton: FC<SkeletonProps> = ({ className = "animate-pulse" , ratio = "" }) => {
  return (
    <div className={`nc-Card18 relative flex flex-col group rounded-xl overflow-hidden bg-slate-200 h-full animate-pulse ${className}`}>
      {/* Skeleton for top icons */}
      <div className="absolute inset-x-0 top-0 p-3 flex items-center justify-between opacity-100 z-10">
        <div className="relative bg-slate-200 w-4 h-4 rounded-full"></div>
        <div className="relative bg-slate-200 w-4 h-4 rounded-full"></div>
      </div>

      {/* Skeleton for image */}
      <div className={`relative w-full aspect-w-4 sm:aspect-w-3 aspect-h-3 ${ratio}`}>
        <div className="w-full h-48 bg-slate-200 rounded-xl"></div>
        {/* Skeleton for PostTypeFeaturedIcon */}
        <div className="absolute top-3 left-3 bg-slate-200 w-7 h-7 rounded-full"></div>
        <span className="absolute inset-0 bg-black bg-opacity-10 opacity-0"></span>
      </div>

      {/* Skeleton for link overlay */}
      <p className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black opacity-80"></p>

      <div className="absolute bottom-0 inset-x-0 p-6 flex flex-col flex-grow">
        <p className="absolute inset-0"></p>

        {/* Skeleton for category badge */}
        <div className="mb-3">
          <div className="w-24 h-4 bg-slate-200 rounded-md"></div>
        </div>

        {/* Skeleton for title */}
        <div className="inline-flex items-center text-xs text-neutral-300">
          <div className="w-3/4 h-6 bg-slate-200 rounded-md"></div>
        </div>
      </div>
    </div>
  );
};

export default Card18Skeleton;
