import React, { FC } from "react";

export interface SkeletonProps {
  className?: string;
}

const CardCategory1Skeleton: FC<SkeletonProps> = ({ className = "animate-pulse" }) => {
  return (
    <div
      className={`nc-CardCategory1 flex items-center ${className}`}
    >
      <div className={`m-4 lg:w-16 lg:h-16 w-10 h-10 bg-slate-100 rounded-lg overflow-hidden`}></div>
      <div>
        <div
          className={`nc-card-title w-20 h-6 bg-slate-100 text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold`}
        >
        </div>
        <span
          className={`block w-20 h-3 bg-slate-100 mt-[2px] text-neutral-500 dark:text-neutral-400`}
        >
        </span>
      </div>
    </div>
  );
};

export default CardCategory1Skeleton;
