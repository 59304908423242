import React, { FC } from "react";

export interface SkeletonProps {
  className?: string;
  isCenter?: boolean;
}

const HeadingSkeleton: FC<SkeletonProps> = ({  className = "mb-10 md:mb-12 text-neutral-900 dark:text-neutral-50", isCenter = false, }) => {
  return (
    <div
      className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between ${className}`}
    >
      <div
        className={
          isCenter ? "text-center w-full max-w-2xl mx-auto " : "max-w-2xl"
        }
      >
        <div
          className={`text-2xl md:text-3xl lg:text-4xl bg-slate-200 h-12 w-full rounded-1 font-semibold rounded-lg`}
        >
        </div>
          <span className="mt-2 md:mt-3 font-normal h-8 w-full rounded-lg block  bg-slate-200 text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
          </span>
      </div>
    </div>
  );
};

export default HeadingSkeleton;
