import React, { useContext, useEffect, createContext, ReactNode } from 'react';

// third-party
import { useDispatch, useSelector } from "react-redux";

// project-imports
import { ActionType } from '../constants/actions';
import { fetchAuthors } from 'api/api';
import { showToastAlert } from 'utils/sweetAlert';


// Define the shape of the author state
interface AuthorState {
    isLoading: boolean;
    authors: any[]; // Replace `any[]` with your actual author type
    error: string | null;
}

// Define the shape of the context value
interface AuthorContextType extends AuthorState {
    getAllAuthors: () => Promise<void>;
}

// Define props for the provider
interface AuthorProviderProps {
    children: ReactNode;
    store: any
}

// Initialize the context
export const AuthorContext = createContext<AuthorContextType | undefined>(undefined);

export const AuthorProvider: React.FC<AuthorProviderProps> = ({ children }) => {
    const { GET_AUTHOR_BEGIN, GET_AUTHOR_SUCCESS, GET_AUTHOR_FAILURE } = ActionType;
    // Reading state from store
    const state = useSelector((state: { author: AuthorState }) => state.author);

    // Reading dispatch from Store
    const dispatch = useDispatch();

    const getAllAuthors = async (domain?: string): Promise<void> => {
        dispatch({ type: GET_AUTHOR_BEGIN, payload: true });

        try {
            let response: any[] = []; // Replace with the actual API response type
            response = await fetchAuthors("") || [];
            dispatch({ type: GET_AUTHOR_SUCCESS, payload: response });
            // showToastAlert('Success', 'Authors data fetched successfully!', { icon: "success" });
        } catch (error: any) {
            console.error("Error fetching authors:", error);
            dispatch({ type: GET_AUTHOR_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Failed to fetch authors. Please try again later.', { icon: "error" });
        }
    };

    useEffect(() => {
        getAllAuthors();
    }, []);

    return (
        <AuthorContext.Provider value={{ ...state, getAllAuthors }
        }>
            {children}
        </AuthorContext.Provider>
    );
};

// Custom hook for using the context
export const useAuthorContext = (): AuthorContextType => {
    const context = useContext(AuthorContext);
    if (!context) {
        throw new Error("useAuthorContext must be used within a AuthorProvider");
    }
    return context;
};
