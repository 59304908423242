import React, { FC } from "react";

export interface SkeletonProps {
    className?: string;
}

const SingleContentDemoSkeleton: FC<SkeletonProps> = ({ className = "animate-pulse" }) => {
    return (
        <div className={`${className}`}>
            {/* THIS IS THE DEMP CONTENT - YOU CAN USE MARKDOWN, JSON ...*/}
            <p className="w-full h-10 rounded-md bg-slate-200"></p>

            <section>
                <div className="w-full h-32 rounded-md bg-slate-200"></div>
                <p className="w-full h-10 rounded-md bg-slate-200"></p>
                <p className="w-full h-10 rounded-md bg-slate-200"></p>
                <ul>
                    <li className="w-full h-10 rounded-md bg-slate-200"></li>
                    <li className="w-full h-10 rounded-md bg-slate-200"></li>
                    <li className="w-full h-10 rounded-md bg-slate-200"></li>
                </ul>
                <p className="w-full h-10 rounded-md bg-slate-200"></p>
            </section>
            <figure>
                <div
                    className="container rounded-2xl max-w-[1024px] h-[774px], w-full bg-slate-200"
                />
                <div className="w-full h-10 rounded-md bg-slate-200">
                </div>
            </figure>
            {[1, 2, 3, 4, 5, 10, 7].map((_, index) => (
                <section key={index}>
                    <div className="w-full h-32 rounded-md bg-slate-200"></div>
                    <p className="w-full h-10 rounded-md bg-slate-200"></p>
                    <p className="w-full h-10 rounded-md bg-slate-200"></p>
                    <ul>
                        <li className="w-full h-10 rounded-md bg-slate-200"></li>
                        <li className="w-full h-10 rounded-md bg-slate-200"></li>
                        <li className="w-full h-10 rounded-md bg-slate-200"></li>
                    </ul>
                    <p className="w-full h-10 rounded-md bg-slate-200"></p>
                </section>
            ))}

            <section>
                <div className="w-full h-32 rounded-md bg-slate-200"></div>
                <p className="w-full h-10 rounded-md bg-slate-200"></p>
                <p className="w-full h-10 rounded-md bg-slate-200"></p>
                <p className="w-full h-10 rounded-md bg-slate-200"></p>
                <p className="w-full h-32 rounded-md bg-slate-200"></p>
            </section>


        </div>
    );
};

export default SingleContentDemoSkeleton;
