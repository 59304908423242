import React, { FC } from "react";

// project-imports
import { SocialType, SOCIALS_DATA } from "components/SocialsShare/SocialsShare";
import { GoogleAnalyticsEnum, triggerFunction } from "utils/googleAnalytics";

// third-party
import { useTranslation } from "react-i18next";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsDemo: SocialType[] = SOCIALS_DATA;

export const SOCIALS_2 = socialsDemo;

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block",
  socials = socialsDemo,
}) => {

  const getHref = (name: string) => {
    try {
      let href = "";
      if (name === 'Instagram') {
        return href = process.env.REACT_APP_INSTAGRAM_LINK as string;
      } else if (name === 'Linkedin') {
        return href = process.env.REACT_APP_LINKEDIN_LINK as string;
      } else if (name === 'Threads') {
        return href = process.env.REACT_APP_THREADS_LINK as string;
      } else if (name === 'YouTube') {
        return href = process.env.REACT_APP_YOUTUBE_LINK as string;
      } else if (name === 'Whatsapp') {
        return href = process.env.REACT_APP_WHATSAPP_LINK as string;
      }
    } catch (error) {
      console.log("35=> Error while fetching url", error);
      throw error;
    }
  }
  const { t } = useTranslation();
  return (
    <nav
      className={`nc-SocialsList flex space-x-3 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
    >
      {socials.filter((icon: SocialType) => icon.id !== "Messenger").map((item, i) => (
        <a
          key={i}
          className={`${itemClass}`}
          href={getHref(item.name)}
          target="_blank"
          rel="noopener noreferrer"
          title={t(item.name || "")}
          onClick={() => triggerFunction(GoogleAnalyticsEnum.LINK, GoogleAnalyticsEnum.FEATURED_MEDIA_BUTTON)}
        >
          <div dangerouslySetInnerHTML={{ __html: item.icon || "" }}></div>
        </a>
      ))}
    </nav>
  );
};

export default SocialsList;
