import React, { FC } from "react";

// project-imports
import PostActionDropdown from "components/PostActionDropdown/PostActionDropdown";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import { SOCIALS_DATA } from "components/SocialsShare/SocialsShare";
import NcDropDown, { NcDropDownItem } from "components/NcDropDown/NcDropDown";
import NcBookmark from "components/NcBookmark/NcBookmark";
import { PostDataType } from "data/types";

export interface SingleMetaAction2Props {
  className?: string;
  post?: PostDataType
}

const SingleMetaAction2: FC<SingleMetaAction2Props> = ({ className = "", post }) => {

  let text = post?.title || "";

  // const currentURL = url ? `${window.location.protocol} //${window.location.host}${url.pathname}${url.search}${url.hash}` : "";
  const currentURL = window.location.href;

  const onClikShareIcon = (socialData: NcDropDownItem) => {
    try {
      if (!socialData || !socialData.id) return;
      const encodedURL = encodeURIComponent(currentURL || window.location.href);
      const encodedText = encodeURIComponent(text || "");
      let shareURL = "";
      switch (socialData.id) {
        case "Linkedin":
          shareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedURL}`;
          break;
        case "Twitter":
          shareURL = `https://twitter.com/intent/tweet?text=${encodedText}%20${encodedURL}`;
          break;
        case "Facebook":
          shareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`;
          break;
        case "Whatsapp":
          shareURL = `https://wa.me/?text=${encodedText}%20${encodedURL}`;
          break;
        case "Instagram":
          alert("Instagram does not support direct link sharing. Please share manually.");
          return;
        case "Threads":
          shareURL = `https://www.threads.net/share?url=${encodedURL}`;
          break;
        case "YouTube":
          alert("YouTube does not support direct link sharing. Redirecting to YouTube.");
          shareURL = `https://www.youtube.com/`;
          break;
        default:
          console.error("Unsupported social media platform:", socialData.id);
          return;
      }
      window.open(shareURL, "_blank", "width=600,height=600");
    } catch (error) {
      console.error("Error sharing link:", error);
    }
  };

  return (
    <div className={`nc-SingleMetaAction2 ${className}`}>
      <div className="flex flex-row space-x-2.5 items-center">
        <PostCardLikeAndComment
          itemClass="px-4 h-9 text-sm"
          hiddenCommentOnMobile
          useOnSinglePage
          className="!space-x-2.5"
          post={post}
        />
        <div className="px-1">
          <div className="border-l border-neutral-200 dark:border-neutral-700 h-6" />
        </div>

        <NcBookmark containerClassName="h-9 w-9 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-200" post={post} />
        <NcDropDown
          className="flex-shrink-0 flex items-center justify-center focus:outline-none h-9 w-9 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 text-neutral-700 dark:text-neutral-200 rounded-full"
          renderTrigger={() => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
              />
            </svg>
          )}
          onClick={onClikShareIcon}
          data={SOCIALS_DATA}
        />
        <PostActionDropdown
          containerClassName="h-9 w-9 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700"
          iconClass="h-5 w-5"
          postId={post && post.id}
          href={post && post.href}
        />
      </div>
    </div>
  );
};

export default SingleMetaAction2;
