import React, { FC } from "react";

export interface SkeletonProps {
  className?: string;
}

const LoginSkeleton: FC<SkeletonProps> = ({ className = "" }) => {
  return (
    <>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20 ">
        <div className="animate-pulse space-y-2">
          <div className="h-8 bg-slate-200 rounded-md w-1/3 mx-auto"></div>
          <div className="h-4 bg-slate-200 rounded-md w-2/3 mx-auto"></div>
        </div>
      </header>
      <div className="max-w-md mx-auto space-y-6">
        <div className="animate-pulse space-y-4">
          {/* Social Buttons Skeleton */}
          <div className="grid gap-3">
            {/* {[1, 2].map((_, index) => ( */}
              <div
                className="flex items-center w-full px-4 py-3 bg-slate-200 rounded-md"
              >
                <div className="h-8 w-8 bg-slate-100 rounded-full"></div>
                <div className="flex-grow h-4 bg-slate-100 rounded-md mx-4"></div>
              </div>
            {/* ))} */}
          </div>
          {/* Divider Skeleton */}
          <div className="relative text-center">
            <div className="h-4 bg-slate-100 rounded-md w-16 mx-auto"></div>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border-t border-slate-100"></div>
          </div>
          {/* Form Skeleton */}
          <div className="space-y-4">
            {/* Email Skeleton */}
            <div>
              <div className="h-4 bg-slate-200 rounded-md w-1/4 mb-2"></div>
              <div className="h-10 bg-slate-200 rounded-md"></div>
            </div>
            {/* Password Skeleton */}
            <div>
              <div className="h-4 bg-slate-200 rounded-md w-1/4 mb-2"></div>
              <div className="h-10 bg-slate-200 rounded-md"></div>
            </div>
            {/* Button Skeleton */}
            <div className="h-10 bg-slate-200 rounded-md"></div>
          </div>
          {/* Footer Skeleton */}
          <div className="h-4 bg-slate-200 rounded-md w-1/3 mx-auto"></div>
        </div>
      </div>
    </>
  );
};

export default LoginSkeleton;
