import React, { FC } from "react";

// project-imports
import Card3Small from "components/Card3Small/Card3Small";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import { GoogleAnalyticsEnum, triggerFunction } from "utils/googleAnalytics";

// third-party
import { useTranslation } from "react-i18next";

const widgetPostsDemo: PostDataType[] = DEMO_POSTS.filter(
  (_, i) => i > 0 && i < 6
);

export interface WidgetPostsProps {
  className?: string;
  posts?: PostDataType[];
}

const WidgetPosts: FC<WidgetPostsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  posts = widgetPostsDemo,
}) => {
  const { t } = useTranslation();

  const handleWidgetPostClick = (title: string) => {
    triggerFunction(GoogleAnalyticsEnum.HOME, GoogleAnalyticsEnum.SECTION_LATEST_POST_PAGE + "-" + GoogleAnalyticsEnum.WIDGET_POST_CARD_CLICK + "-" + GoogleAnalyticsEnum.POST_CLICK + "-" + title)
  }

  return (
    <div className={`nc-WidgetPosts rounded-3xl overflow-hidden ${className}`}>
      <WidgetHeading1
        title={`🎯 ${t("popular_posts")}`}
        viewAll={{ label: "", href: "" }}
      />
      <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {posts && posts.length ?
          posts.sort((a, b) =>
            (b.like?.filter((obj) => obj.userId !== null && obj.isLiked).length || 0) -
            (a.like?.filter((obj) => obj.userId !== null && obj.isLiked).length || 0)
          ).filter((_, i) => i < 5).map((post) => (
            <Card3Small
              className="p-4 xl:px-5 xl:py-6 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              key={post.id}
              post={post}
              onClik={handleWidgetPostClick}
            />
          ))
          :
          <span className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700">{t("no_data_found")}</span>
        }
      </div>
    </div>
  );
};

export default WidgetPosts;
