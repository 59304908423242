import React, { FC } from "react";

export interface SkeletonProps {
    className?: string;
}

const Card11Skeleton: FC<SkeletonProps> = ({ className = "h-full animate-pulse" }) => {
    return (
        <div
            className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 ${className}`}
        >
            <div
                className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 aspect-w-4 aspect-h-3`}
            >
                <div className="h-[480px] w-[800px] max-w-[600px] rounded-lg bg-slate-200">
                </div>
            </div>
            <div className="absolute inset-0 h-4 w-20 rounded-md bg-slate-200"></div>
            <span className="absolute top-3 inset-x-3 z-10 h-4 w-20 rounded-md bg-slate-200">
            </span>

            <div className="p-4 flex flex-col space-y-3">
                <span className="h-4 w-20 rounded-lg bg-slate-200"></span>
                <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
                    <span className="line-clamp-2 h-4 w-32 rounded-lg bg-slate-200">
                    </span>
                </h3>
                <div className="flex flex-row space-x-5 items-end justify-between mt-auto">
                    <div className=" h-8 w-8 bg-slate-200 rounded-md"></div>
                    <div className="w-1 h-8 border-r border-neutral-200"></div>
                    <div className=" h-8 w-8 bg-slate-200 rounded-md"></div>
                    <div className=" h-8 w-8 bg-slate-200 rounded-md"></div>
                    <div className=" h-8 w-8 bg-slate-200 rounded-md"></div>
                </div>
            </div>
        </div>
    );
};

export default Card11Skeleton;
