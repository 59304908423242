import { Route } from "routers/types";
import __taxonomies from "./jsons/__taxonomies.json";
import __categories from "./jsons/__categories.json";
import { TaxonomyType } from "./types";

const DEMO_CATEGORIES: TaxonomyType[] = __taxonomies.map((item) => ({
  ...item,
  taxonomy: "category",
  href: item.href as Route,
}));

const DEMO_CATEGORIES_V1: TaxonomyType[] = __categories.map((item) => ({
  ...item,
  taxonomy: "category",
  href: item.href as Route,
}));

const DEMO_TAGS: TaxonomyType[] = __taxonomies.map((item) => ({
  ...item,
  taxonomy: "tag",
  href: item.href as Route,
}));

const DEMO_TAGS_MAIN = (categories: TaxonomyType[]) => {
  try {
    return (categories || []).map((item) => ({
      ...item,
      taxonomy: "tag",
      href: item.href as Route,
    })) as TaxonomyType[];
  } catch (error) {
    throw error;
  }
}

export { DEMO_CATEGORIES, DEMO_TAGS, DEMO_CATEGORIES_V1, DEMO_TAGS_MAIN };
