import React, { FC } from "react";

export interface SkeletonProps {
    className?: string;
}

const SectionSubscribe2Skeleton: FC<SkeletonProps> = ({ className = "" }) => {
    return (
        <div className="nc-SectionSubscribe2 relative flex flex-col lg:flex-row items-center animate-pulse">
            <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
                <div className="h-10 bg-slate-200 rounded-md w-3/4 mb-6"></div>
                <div className="h-4 bg-slate-200 rounded-md w-full mb-6"></div>
                <ul className="space-y-5 mt-10">
                    <li className="flex items-center space-x-4">
                        <div className="w-6 h-6 bg-slate-200 rounded-full"></div>
                        <div className="h-4 bg-slate-200 rounded-md w-3/4"></div>
                    </li>
                    <li className="flex items-center space-x-4">
                        <div className="w-6 h-6 bg-slate-200 rounded-full"></div>
                        <div className="h-4 bg-slate-200 rounded-md w-3/4"></div>
                    </li>
                </ul>
                <div className="mt-10 relative max-w-sm">
                    <div className="w-full h-10 bg-slate-200 rounded-md"></div>
                    <div className="absolute top-1/2 transform -translate-y-1/2 right-1 w-10 h-10 bg-slate-200 rounded-full"></div>
                </div>
            </div>
            <div className="flex-grow">
                <div className="w-full h-64 bg-slate-200 rounded-md"></div>
            </div>
        </div>
    );
};

export default SectionSubscribe2Skeleton;
