import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// project-imports
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import Heading2 from "components/Heading/Heading2";
import Layout from "../layout";
import { ResetPasswordRequest, ResetPasswordValidationRequest } from "api/api";
import { useMemberContext } from "context/memberContext";
import { FAILURE, SUCCESS } from "constants/utils";
import { GoogleAnalyticsEnum, pageView, triggerFunction } from "utils/googleAnalytics";

//third-party
import { useTranslation } from "react-i18next";

const PageResetPass = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  // local state
  const [resetPassPayload, setResetPassPayload] = useState<ResetPasswordValidationRequest>({ newPassword: "", confirmNewPassword: "" });
  const [valdiationError, setValidationError] = useState<ResetPasswordValidationRequest>({ newPassword: "", confirmNewPassword: "" });

  // read state from store
  const { resetPassword, status, error, isLoading } = useMemberContext();

  const { t } = useTranslation();

  // handle change function
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name: String = "";
    try {
      let value = e.target.value;
      name = e.target.name;
      let obj: ResetPasswordValidationRequest = resetPassPayload;
      // if (name === "fullName") {
      //   member.fullName = value;
      // } else 
      if (name === "newPassword") {
        obj.newPassword = value;
      }
      if (name === "confirmNewPassword") {
        obj.confirmNewPassword = value;
      }
      setValidationError(checkValidation(obj, name));
      setResetPassPayload(obj);
    } catch (error: any) {
      console.log(`Member Reset Password ${name} error:`, error.message);
      throw error;
    }
  }

  const checkValidation = (payload: ResetPasswordValidationRequest, name: String) => {
    try {
      let validationErrorObj: ResetPasswordValidationRequest = { newPassword: "", confirmNewPassword: "" };

      if (name === "all" || name === "newPassword") {
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
        if (payload && !payload.newPassword) {
          validationErrorObj.newPassword = "Password is required!";
        }
        else if (payload && payload.newPassword && (payload.newPassword.length < 6 || payload.newPassword.length > 14)) {
          validationErrorObj.newPassword = "Password must be atleast 6-14 characters long!";
        }
        else if (payload && payload.newPassword && !(strongPasswordRegex.test(payload.newPassword))) {
          validationErrorObj.newPassword = "Password must contain at least one number , lowercase , uppercase and a special character!";
        } else {
          validationErrorObj.newPassword = "";
        }
      }

      if (name === "all" || name === "confirmNewPassword") {
        if (payload && !payload.confirmNewPassword) {
          validationErrorObj.newPassword = "Confirm Password is required!";
        }
        else if (payload && (payload.newPassword !== payload.confirmNewPassword)) {
          validationErrorObj.confirmNewPassword = "Passwords do not match!";
        }
      }

      setValidationError(validationErrorObj);
      return validationErrorObj;
    } catch (error: any) {
      console.log('Member Reset Password Validation Error:', error.message);
      throw error;
    }
  }

  const handleResetPassword = () => {
    try {
      // Validation check for all fields
      let formIsValid = true;
      const validationErrros = checkValidation(resetPassPayload, "all");

      if (validationErrros.newPassword) {
        formIsValid = false;
      }

      if (validationErrros.confirmNewPassword) {
        formIsValid = false;
      }

      if (formIsValid) {
        const obj: ResetPasswordRequest = {
          token: token || "",
          newPassword: resetPassPayload && resetPassPayload.newPassword
        };

        // Call resetPassword action
        resetPassword(obj);
      }
    } catch (error: any) {
      console.log('Member Reset Password Error:', error.message);
      throw error;
    }
  }

  useEffect(() => {
    pageView(window.location.pathname, GoogleAnalyticsEnum.RESET_PASSWORD_PAGE_VIEW);
    if (status === SUCCESS) {
      let obj: ResetPasswordValidationRequest = { newPassword: "", confirmNewPassword: "" };
      setResetPassPayload(obj);
      navigate("/login");
    } else if (status === FAILURE && error === "JWT token expired") {
      // navigate("/login");
    }
  }, [status]);

  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
        <Heading2>{t("reset_password")}</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          {t("reset_password_subtitle")}
        </span>
      </header>

      <div className="max-w-md mx-auto space-y-6">
        {/* FORM */}
        <div className="grid grid-cols-1 gap-6">
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              {t("new_password")}
            </span>
            <Input
              type="password"
              name="newPassword"
              placeholder={t("Enter New Password")}
              className="mt-1"
              disabled={isLoading}
              value={resetPassPayload.newPassword}
              onChange={handleChange}
            />
            {valdiationError && valdiationError.newPassword && (
              <p className="text-red-500 text-xs mt-1">{valdiationError.newPassword}</p>
            )}
          </label>

          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              {t("confirm_new_password")}
            </span>
            <Input
              type="password"
              name="confirmNewPassword"
              placeholder={t("Confirm New Password")}
              className="mt-1"
              disabled={isLoading}
              value={resetPassPayload.confirmNewPassword}
              onChange={handleChange}
            />
            {valdiationError && valdiationError.confirmNewPassword && (
              <p className="text-red-500 text-xs mt-1">{valdiationError.confirmNewPassword}</p>
            )}
          </label>

          <ButtonPrimary type="submit" disabled={isLoading} onClick={() => {
            triggerFunction(
              GoogleAnalyticsEnum.BUTTON,
              GoogleAnalyticsEnum.RESET_PASSWORD_PAGE_BUTTON
            );
            handleResetPassword();
          }}>
            {isLoading ? <span> <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
            </svg>
              <span> {t("Processing...")}</span></span> :
              t("continue")
            }
          </ButtonPrimary>
        </div>


        {/* ==== */}
        <span className="block text-center text-neutral-700 dark:text-neutral-300">
          {t("reset_password_caption")}{` `}
          <NcLink href="/login">{t("sign_in")}</NcLink>
          {` / `}
          <NcLink href="/signup">{t("sign_up")}</NcLink>
        </span>
      </div>
    </Layout>
  );
};

export default PageResetPass;
