import React, { useEffect, useState } from "react";

// project-import
import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import SocialsList from "components/SocialsList/SocialsList";
import Textarea from "components/Textarea/Textarea";
import Heading2 from "components/Heading/Heading2";
import Layout from "../layout";
import { GoogleAnalyticsEnum, pageView, triggerFunction } from "utils/googleAnalytics";
import { ContactEnquiry, useEnquiryContext } from "context/enquiryContext";

// third-party
import { useTranslation } from "react-i18next";
import ContactUsSkeleton from "components/Skeleton/ContactUsSkeleton";


const PageContact = () => {

  const { t } = useTranslation();
  const info = [
    {
      title: `🗺 ${t("address")}`,
      desc: "Gujarat,India.",
    },
    {
      title: `💌 ${t("email")}`,
      desc: "team.financeoptimize@gmail.com",
    }
  ];
  //stat variables
  const { submitEnquiry, isLoading } = useEnquiryContext();
  const [newEnquiry, setNewEnquiry] = useState<ContactEnquiry>({ name: "", email: "", message: "" });
  const [valdiationError, setValidationError] = useState<ContactEnquiry>({ name: "", email: "", message: "" });

  useEffect(() => {
    // Tracking page view for CONTACT page on Google Analytics
    pageView(window.location.pathname, GoogleAnalyticsEnum.CONTACT_US);
  }, []);

  // handle change function
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name: string = "";
    try {
      let value = e.target.value;
      name = e.target.name;
      let enquiry: ContactEnquiry = newEnquiry;
      if (name === "name") {
        enquiry.name = value;
      } else if (name === "email") {
        enquiry.email = value;
      }
      setValidationError(checkValidation(enquiry, name));
      setNewEnquiry(enquiry);
    } catch (error: any) {
      console.log(`Member registeration ${name} error:`, error.message);
      throw error;
    }
  }

  // handle change function
  const handleChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let name: string = "";
    try {
      let value = e.target.value;
      name = e.target.name;
      let enquiry: ContactEnquiry = newEnquiry;
      if (name === "message") {
        enquiry.message = value;
      }
      setValidationError(checkValidation(enquiry, name));
      setNewEnquiry(enquiry);
    } catch (error: any) {
      console.log(`Member registeration ${name} error:`, error.message);
      throw error;
    }
  }

  const checkValidation = (enquiry: ContactEnquiry, name: string) => {
    try {
      let validationErrorObj: ContactEnquiry = { name: "", email: "", message: "" };
      if (name === "all" || name === "name") {
        if (enquiry && !enquiry.name) {
          validationErrorObj.name = t("name_error_1");
        }
        else if (enquiry && enquiry.name && (enquiry.name.length < 3 || enquiry.name.length > 16)) {
          validationErrorObj.name = t("name_error_2");
        } else {
          validationErrorObj.name = "";
        }
      }

      if (name === "all" || name === "email") {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (enquiry && !enquiry.email) {
          validationErrorObj.email = t("email_error_1");
        }
        else if (enquiry && enquiry.email && !emailRegex.test(enquiry.email)) {
          validationErrorObj.email = t("email_error_2");
        } else {
          validationErrorObj.email = "";
        }
      }

      if (name === "all" || name === "message") {
        if (enquiry && !enquiry.message) {
          validationErrorObj.message = t("message_error_1");
        }
        else if (enquiry && enquiry.message && (enquiry.message.length < 6 || enquiry.message.length > 500)) {
          validationErrorObj.message = t("message_error_2");
        } else {
          validationErrorObj.message = "";
        }
      }

      setValidationError(validationErrorObj);
      return validationErrorObj;
    } catch (error: any) {
      console.log('Enquiry Validation Error:', error.message);
      throw error;
    }
  }

  const handleSubmitEnquiry = () => {
    try {
      // Validation check for all fields
      let formIsValid = true;
      const validationErrros = checkValidation(newEnquiry, "all");

      if (validationErrros.name || validationErrros.email || validationErrros.message) {
        formIsValid = false;
      }

      if (formIsValid) {
        const newEnquiryClone: ContactEnquiry = newEnquiry
        // Call registerMember action
        submitEnquiry(newEnquiryClone);
        setNewEnquiry({ name: "", email: "", message: "" });
      }
    } catch (error: any) {
      console.log('Member Registration Error:', error.message);
      throw error;
    }
  }

  return (
    <Layout>
      {isLoading ? <ContactUsSkeleton /> :
        <>
          <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-28 ">
            <Heading2>{t("contact_us")}</Heading2>
            <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
              {t("contact_us_desk")}
            </span>
          </header>

          <div className="grid gap-8 lg:grid-cols-2">
            <div className="max-w-sm space-y-6">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 {t("socials")}
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div className="border border-neutral-100 dark:border-neutral-700 lg:hidden"></div>
            <div>
              <div className="grid grid-cols-1 gap-6">
                <label className="block">
                  <Label>{t("full_name")}</Label>

                  <Input type="text" name="name" placeholder="Example Doe" className="mt-1" onChange={handleChange} value={newEnquiry.name} disabled={isLoading} />
                  {valdiationError && valdiationError.name && (
                    <p className="text-red-500 text-xs mt-1">{valdiationError.name}</p>
                  )}
                </label>
                <label className="block">
                  <Label>{t("email_address")}</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    name="email"
                    className="mt-1"
                    onChange={handleChange}
                    value={newEnquiry.email}
                    disabled={isLoading}
                  />
                  {valdiationError && valdiationError.email && (
                    <p className="text-red-500 text-xs mt-1">{valdiationError.email}</p>
                  )}
                </label>
                <label className="block">
                  <Label>{t("message")}</Label>

                  <Textarea name="message" className="mt-1" rows={6} onChange={handleChangeMessage} value={newEnquiry.message} disabled={isLoading} />
                  {valdiationError && valdiationError.message && (
                    <p className="text-red-500 text-xs mt-1">{valdiationError.message}</p>
                  )}
                </label>
                <ButtonPrimary type="button" disabled={isLoading} onClick={() => {
                  triggerFunction(
                    GoogleAnalyticsEnum.BUTTON,
                    GoogleAnalyticsEnum.CONTACT_US_BUTTON
                  );
                  handleSubmitEnquiry();
                }
                }>{isLoading ? <span> <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                </svg>
                  <span> {`${t("processing")}...`}</span></span> :
                  t("send_message")
                  }</ButtonPrimary>
              </div>
            </div>
          </div>
        </>}
    </Layout>
  );
};

export default PageContact;
