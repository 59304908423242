import React, { FC, useEffect } from "react";

// project-imports 
import Avatar from "components/Avatar/Avatar";
import { PostDataType } from "data/types";
import Link from "components/Link";
import { GoogleAnalyticsEnum, pageView } from "utils/googleAnalytics";

export interface PostCardMetaProps {
  className?: string;
  meta: Pick<PostDataType, "date" | "author">;
  hiddenAvatar?: boolean;
  avatarSize?: string;
  onClick?: (name: string) => void;
}

const PostCardMeta: FC<PostCardMetaProps> = ({
  className = "leading-none text-xs",
  meta,
  hiddenAvatar = false,
  avatarSize = "h-7 w-7 text-sm",
  onClick = () => { }
}) => {

  const { date, author } = meta;

  // Tracking page view for PostCardMeta page on Google Analytics
  useEffect(() => {
    try {
      pageView(window.location.pathname, GoogleAnalyticsEnum.POST_CARD_META_PAGE_VIEW);
    } catch (error) {
      console.log("28::PostCardMeta:: Error tracking for page view.", error);
    }
  }, []);

  return (
    <div
      className={`nc-PostCardMeta inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${className}`}
    >
      <Link href={author.href} className="relative flex items-center space-x-2" onClick={() => { onClick(author.displayName) }}>
        {!hiddenAvatar && (
          <Avatar
            radius="rounded-full"
            sizeClass={avatarSize}
            imgUrl={author.avatar}
            userName={author.displayName}
          />
        )}
        <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
          {author.displayName}
        </span>
      </Link>
      <>
        <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
          ·
        </span>
        <span className="text-neutral-500 dark:text-neutral-400 font-normal">
          {date}
        </span>
      </>
    </div>
  );
};

export default PostCardMeta;
