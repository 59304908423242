import {
  ActionType
} from "../constants/actions";

export const initialState = {
  isLoading: false,
  error: null,
  enquiry: {},
};

const enquiryReducer = (state = initialState, action: { type: ActionType; payload: any; }) => {
  if (action.type === ActionType.SUBMIT_ENQUIRY_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
    };
  }
  if (action.type === ActionType.SUBMIT_ENQUIRY_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      enquiry: action.payload,
      error: null,
    };
  }
  if (action.type === ActionType.SUBMIT_ENQUIRY_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  }

  return state;
};

export default enquiryReducer;
