//third party 
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Route } from "routers/types";

// assets
import Image from "../Image";

// project-imports 
import { variants } from "utils/animationVariants";
import Link from "components/Link";
import { GoogleAnalyticsEnum, pageView, triggerFunction, } from "utils/googleAnalytics";

export interface GallerySliderProps {
  className?: string;
  galleryImgs: string[];
  ratioClass?: string;
  href?: Route<string>;
  imageClass?: string;
  galleryClass?: string;
  navigation?: boolean;
}

export default function GallerySlider({
  className = "relative z-10",
  galleryImgs,
  ratioClass = "relative aspect-w-4 aspect-h-3",
  imageClass = "",
  galleryClass = "rounded-xl",
  href,
  navigation = true,
}: GallerySliderProps) {

  const [loaded, setLoaded] = useState(false);
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const images = galleryImgs;

  // Tracking page view for GallerySlider  page on Google Analytics
  useEffect(() => {
    pageView(window.location.pathname, GoogleAnalyticsEnum.GALLERY_PAGE_VIEW);
  }, []);

  function changePhotoId(newVal: number) {
    try {
      if (newVal > index) {
        setDirection(1);
      } else {
        setDirection(-1);
      }
      setIndex(newVal);
    } catch (error) {
      console.log("54:: Error changing  photo ID:::", error);
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (index < images?.length - 1) {
        try {
          changePhotoId(index + 1);

        } catch (error) {
          console.log("61::GallerySlider::: Error on swipe left", error);
        }
      }
    },

    onSwipedRight: () => {
      if (index > 0) {
        try {
          changePhotoId(index - 1);

        } catch (error) {
          console.log(" 71::GallerySlider:: Error on swipe right ");

        }
      }
    },
    trackMouse: true,
  });

  // Handle left and right button clicks for gallery navigation
  const handleGalleryNavigationClick = (direction: "left" | "right") => {
    try {
      triggerFunction(
        GoogleAnalyticsEnum.BUTTON,
        direction === "left"
          ? GoogleAnalyticsEnum.GALLERY_NAVIGATION_LEFT
          : GoogleAnalyticsEnum.GALLERY_NAVIGATION_RIGHT
      );
    } catch (error) {
      console.log(`Error tracking ${direction} navigation button click:`, error);
    }
  };

  // Handle bottom navigation button click for selecting a specific image
  const handleBottomNavClick = (i: number) => {
    try {
      triggerFunction(
        GoogleAnalyticsEnum.BUTTON,
        GoogleAnalyticsEnum.GALLERY_NAVIGATION_SELECT
      );
      changePhotoId(i);
    } catch (error) {
      console.log("91::Error handling bottom nav button click", error);

    }
  };

  let currentImage = images[index];

  return (
    <MotionConfig
      transition={{
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      }}
    >
      <div
        className={` group group/cardGallerySlider ${className}`}
        {...handlers}
      >
        {/* Main image */}
        <div className={`w-full overflow-hidden ${galleryClass}`}>
          <Link
            href={href || "/"}
            className={`flex items-center justify-center ${ratioClass}`}
          >
            <AnimatePresence initial={false} custom={direction}>
              <motion.div
                key={index}
                custom={direction}
                variants={variants(300, 1)}
                initial="enter"
                animate="center"
                exit="exit"
                className="absolute inset-0"
              >
                <Image
                  src={currentImage || ""}
                  fill
                  alt="listing card gallery"
                  className={`object-cover ${imageClass}`}
                  onLoadingComplete={() => setLoaded(true)}
                  sizes="(max-width: 1025px) 100vw, 300px"
                />
              </motion.div>
            </AnimatePresence>
          </Link>
        </div>

        <>
          {/* Buttons */}
          {loaded && navigation && (
            <div className="opacity-0 group-hover/cardGallerySlider:opacity-100 transition-opacity ">
              {index > 0 && (
                <button
                  className="absolute w-8 h-8 left-3 top-[calc(50%-16px)] bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 focus:outline-none z-10"
                  style={{ transform: "translate3d(0, 0, 0)" }}
                  // onClick={() => changePhotoId(index - 1)} //commented original code
                  onClick={() => {
                    changePhotoId(index - 1);
                    handleGalleryNavigationClick("left"); // Trigger GA event for left button
                  }}
                >
                  <ChevronLeftIcon className="h-4 w-4" />
                </button>
              )}
              {index + 1 < images.length && (
                <button
                  className="absolute w-8 h-8 right-3 top-[calc(50%-16px)] bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 focus:outline-none z-10"
                  style={{ transform: "translate3d(0, 0, 0)" }}
                  // onClick={() => changePhotoId(index + 1)} //commented original code
                  onClick={() => {
                    changePhotoId(index + 1);
                    handleGalleryNavigationClick("right"); // trigger GA Event for right button
                  }}
                >
                  <ChevronRightIcon className="h-4 w-4" />
                </button>
              )}
            </div>
          )}

          {/* Bottom Nav bar */}
          <div className="absolute bottom-0 inset-x-0 h-10 bg-gradient-to-t from-neutral-900 opacity-50 rounded-b-lg"></div>
          <div className="flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5">
            {images.map((_, i) => (
              <button
                className={`w-1.5 h-1.5 rounded-full ${i === index ? "bg-white" : "bg-white/60 "
                  }`}
                // onClick={() => changePhotoId(i)} //commented original code
                onClick={() => handleBottomNavClick(i)} // Track click on bottom nav button
                key={i}
              />
            ))}
          </div>
        </>
      </div>
    </MotionConfig>
  );
}
