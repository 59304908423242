import React, { FC, useEffect, useRef, useState } from "react";

//Project imports
import Tag from "components/Tag/Tag";
import SingleAuthor from "./SingleAuthor";
import SingleCommentForm from "./SingleCommentForm";
import SingleCommentLists from "./SingleCommentLists";
import SingleContentDemo from "./SingleContentDemo";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import PostCardLikeAction from "components/PostCardLikeAction/PostCardLikeAction";
import PostCardCommentBtn from "components/PostCardCommentBtn/PostCardCommentBtn";
import { PostDataType, PostLikeDataType } from "data/types";
import { usePostContext } from "context/postContext";
import { useMemberContext } from "context/memberContext";
import { useCategoryContext } from "context/categoryContext";
import SingleContentSkeleton from "components/Skeleton/SingleContentSkeleton";
import { CommentAction } from "constants/comment";
import ModalLogin from "components/ModalLogin/ModalLogin";
import { GoogleAnalyticsEnum, triggerFunction } from "utils/googleAnalytics";

//third party
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

// const demoTags = DEMO_TAGS.filter((_, i) => i < 9);

export interface SingleContentProps {
  post?: PostDataType;
  isLoading?: boolean;
}

const SingleContent: FC<SingleContentProps> = ({
  post,
  isLoading: pageLoading = false,
}) => {
  const { t } = useTranslation();
  const endedAnchorRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLButtonElement>(null);
  //
  const [isShowScrollToTop, setIsShowScrollToTop] = useState<boolean>(false);

  // read state from store
  const { addPostLike, isLoading, comments } = usePostContext();
  const { categories } = useCategoryContext();
  const { currentMember } = useMemberContext();
  const member = localStorage.getItem(process.env.REACT_APP_USER_LOCALSTORAGE_KEY as string) ? currentMember : null;
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const endedAnchorEntry = useIntersectionObserver(endedAnchorRef, {
    threshold: 0,
    root: null,
    rootMargin: "0%",
    freezeOnceVisible: false,
  });

  const demoTags = (categories || []).filter((_, i) => i < 9);

  const handleCategoryTagClick = (name: string) => {
    triggerFunction(GoogleAnalyticsEnum.POST_DETAIL_PAGE_VIEW, GoogleAnalyticsEnum.CATEGORY_TAG_LINK_CLICK + "-" + name)
  }

  const handleAuthorClick = (name: string) => {
    triggerFunction(GoogleAnalyticsEnum.POST_DETAIL_PAGE_VIEW, GoogleAnalyticsEnum.AUTHOR_PROFILE_LINK + "-" + name)
  }

  useEffect(() => {

    try {
      const handleProgressIndicator = () => {
        const entryContent = contentRef.current;
        const progressBarContent = progressRef.current;

        if (!entryContent || !progressBarContent) {
          return;
        }

        const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
        let winScroll =
          document.body.scrollTop || document.documentElement.scrollTop;
        let scrolled = (winScroll / totalEntryH) * 100;

        progressBarContent.innerText = scrolled.toFixed(0) + "%";

        if (scrolled >= 100) {
          setIsShowScrollToTop(true);
        } else {
          setIsShowScrollToTop(false);
        }
      };

      const handleProgressIndicatorHeadeEvent = () => {
        window?.requestAnimationFrame(handleProgressIndicator);
      };
      handleProgressIndicator();
      window?.addEventListener("scroll", handleProgressIndicatorHeadeEvent);
      return () => {
        window?.removeEventListener("scroll", handleProgressIndicatorHeadeEvent);
      };
    } catch (error: any) {
      console.error("Error while updating single post :", error?.message);
      throw error;
    }
  }, []);

  const showLikeAndCommentSticky =
    !endedAnchorEntry?.intersectionRatio &&
    (endedAnchorEntry?.boundingClientRect.top || 0) > 0;

  // Construct post like data
  let isLikeDisabled = !post || isLoading || (currentMember && currentMember.id ? false : true);

  // Construct like data
  let totalLikes: number = 0;
  const currentUserId = currentMember ? currentMember.id || "" : "";
  let isLiked: boolean = false;

  if (!isLoading && post) {
    (post ? post.like || [] : []).forEach((item: PostLikeDataType) => {
      if (item.userId === currentUserId && item.isLiked) {
        isLiked = true;
      }

      if (item.userId) {
        totalLikes += item.count;
      }
    });
  }
  const openModalLogin = () => setIsLoggedOut(true);
  const closeModalLogin = () => setIsLoggedOut(false);

  const handleLikeBtnClick = (value: boolean) => {
    try {

      let likes: PostLikeDataType[] = post && post.like ? JSON.parse(JSON.stringify(post.like)) || [] : [];
      // Check if the user is logged in
      if (!member) {
        return openModalLogin();
      }

      // Find the index of the current user's like
      const index = likes.findIndex((item) => item.userId === currentUserId);
      if (index !== -1) {
        likes = likes.map((like, i) =>
          i === index
            ? {
              ...like,
              count: value ? 1 : 0,
              isLiked: value,
            }
            : like
        );
      } else {
        likes = [
          ...likes,
          {
            userId: currentUserId,
            count: value ? 1 : 0,
            isLiked: value,
          },
        ];
      }

      if (likes && likes.length) {
        addPostLike(currentUserId, (post ? post.id || "" : "").toString(), likes);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const title = post?.title || "No Post Title";

  return (
    <>
      {isLoading ? <SingleContentSkeleton /> :
        <div className="relative">
          <div className="nc-SingleContent space-y-10">
            {/* ENTRY CONTENT */}
            <div
              id="single-entry-content"
              className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
              ref={contentRef}
            >
              <SingleContentDemo post={post} isLoading={pageLoading} />
            </div>

            {/* TAGS */}
            <div className="max-w-screen-md mx-auto flex flex-wrap">
              {demoTags.map((item) => (
                <Tag hideCount key={item.id} tag={item} className="mr-2 mb-2" onClick={handleCategoryTagClick} />
              ))}
            </div>

            {/* AUTHOR */}
            <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
            <div className="max-w-screen-md mx-auto ">
              <SingleAuthor author={post && post.author} onClick={handleAuthorClick} />
            </div>

            {/* COMMENT FORM */}
            <div
              id="comments"
              className="scroll-mt-20 max-w-screen-md mx-auto pt-5"
            >
              <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                {post?.commentCount ? `${t('Response')}(${post?.commentCount})` : t("no_comments_available")}
              </h3>
              <SingleCommentForm postId={post && post.id} defaultValue="" action={CommentAction.ADD_COMMENT} />
            </div>

            {/* COMMENTS LIST */}
            <div className="max-w-screen-md mx-auto">
              <SingleCommentLists postId={post && post.id} />
              <div ref={endedAnchorRef}></div>
            </div>
          </div>
          {/* scroll top */}
          <div
            className={`sticky mt-8 bottom-8 z-40 justify-center ${showLikeAndCommentSticky ? "flex" : "hidden"
              }`}
          >
            <div className="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1.5 flex items-center justify-center space-x-2 text-xs">
              <PostCardLikeAction
                className="px-3 h-9 text-xs"
                likeCount={totalLikes}
                liked={isLiked}
                isLoading={isLoading}
                isDisabled={isLikeDisabled}
                postTitle={title}
                handleLikeClick={handleLikeBtnClick} />

              <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>
              <PostCardCommentBtn
                isATagOnSingle
                className={` flex px-3 h-9 text-xs`}
                count={post?.commentCount}
              />
              <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>

              <button
                className={`w-9 h-9 items-center justify-center bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-100 rounded-full ${isShowScrollToTop ? "flex" : "hidden"
                  }`}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <ArrowUpIcon className="w-4 h-4" />
              </button>

              <button
                ref={progressRef}
                className={`w-9 h-9 items-center justify-center ${isShowScrollToTop ? "hidden" : "flex"
                  }`}
                title="Go to top"
              >
                %
              </button>
            </div>
          </div>
        </div>}
      <ModalLogin
        show={isLoggedOut}
        onCloseModalLogin={closeModalLogin}
      />
    </>
  );
};

export default SingleContent;
