import React, { FC, ReactNode } from "react";

// project-imports
import { Route } from "routers/types";
import Link from "components/Link";
import { GoogleAnalyticsEnum, triggerFunction } from "utils/googleAnalytics";

export interface NcLinkProps {
  className?: string;
  colorClass?: string;
  href: Route;
  children: ReactNode;
}

const NcLink: FC<NcLinkProps> = ({
  className = "font-medium",
  colorClass = "text-primary-6000 hover:text-primary-800 dark:text-primary-500 dark:hover:text-primary-6000",
  children,
  href,
}) => {

  const handleClickLink = () => {
    try {
      let linkName = null;

      // Check if the href is "/login" and assign the corresponding enum
      if (href === "/login") {
        linkName = GoogleAnalyticsEnum.LOGIN_PAGE_LINK;
      }
      // Check if the href is "/signup" and assign the corresponding enum
      else if (href === "/signup") {
        linkName = GoogleAnalyticsEnum.SIGNUP_PAGE_LINK;
      }
      // Check if the href is "/forget-password" and assign the generic LINK enum
      else if (href === "/forget-password") {
        linkName = GoogleAnalyticsEnum.FORGET_PASSWORD_PAGE_LINK;
      }

      // Trigger the analytics function with the appropriate link and href
      triggerFunction(
        GoogleAnalyticsEnum.LINK,
        `${linkName || ""}_${href.toUpperCase()}`
      );

    } catch (error) {
      console.log("38::Error While handling the link:", error);
    }
  }

  return (
    <Link className={`nc-NcLink ${colorClass} ${className}`} href={href} onClick={handleClickLink}>
      {children}
    </Link>
  );
};

export default NcLink;
