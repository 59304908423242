import React, { FC } from "react";

export interface SkeletonProps {
  className?: string;
  ratio?: string;
}

const Card19Skeleton: FC<SkeletonProps> = ({
  className = "h-full",
  ratio = "aspect-w-4 sm:aspect-w-3 aspect-h-3",
}) => {
  return (
    <div
      className={`nc-Card19 relative flex flex-col group rounded-xl overflow-hidden animate-pulse ${className}`}
      data-nc-id="Card19"
    >
      {/* Skeleton for the actions (like and comment) */}
      <div className="absolute inset-x-0 top-0 p-3 flex items-center justify-between transition-all opacity-0 z-[-1] group-hover:opacity-100 group-hover:z-10 duration-200">
        <div className="w-8 h-8 rounded-full bg-slate-200 animate-pulse"></div>
        <div className="w-8 h-8 rounded-full bg-slate-200 animate-pulse"></div>
      </div>

      {/* Skeleton for the image section */}
      <div className={`flex items-start relative w-full ${ratio}`}>
        <div className="w-full h-full bg-slate-200 animate-pulse rounded-lg"></div>
      </div>

      {/* Skeleton for audio post type */}
      {false && (
        <div className="absolute inset-0">
          <div className="w-full h-full bg-slate-200 animate-pulse"></div>
        </div>
      )}

      {/* Skeleton for the overlay with post type icon */}
      <div className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity"></div>

      {/* Skeleton for bottom gradient overlay */}
      <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black opacity-80"></div>

      <div className="absolute bottom-0 inset-x-0 p-5 sm:p-10 flex flex-col flex-grow">
        {/* Skeleton for the category badge list */}
        <div className="mb-3">
          <div className="w-24 h-4 bg-slate-200 animate-pulse rounded-md mb-2"></div>
          <div className="w-24 h-4 bg-slate-200 animate-pulse rounded-md mb-2"></div>
        </div>

        {/* Skeleton for title */}
        <div className="inline-flex items-center text-xs text-neutral-200">
          <h2 className="block font-semibold text-white">
            <div className="w-1/2 h-6 bg-slate-200 animate-pulse rounded"></div>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Card19Skeleton;
