import { configureStore, Store } from '@reduxjs/toolkit';
import { loadState, saveState } from './localStorage';
import rootReducers from '../reducers';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

// Define the shape of the state (you can adjust these types as per your actual state structure)
interface RootState {
    post: any;
    category: any;
    author: any;
    member: any;
    enquiry: any;
    newsletter: any;
}

// reading data of localStorage from loadState
const preloadedState = loadState();

// Configuring the store 
export const store: Store<RootState> = configureStore({
    reducer: rootReducers,
    preloadedState: preloadedState,
    devTools: true, // enable dev tools if in development
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(createStateSyncMiddleware({})) as any, // i
});

// getting data from the reducer on every state update
store.subscribe(() => {
    const state = store.getState();
    saveState({
        post: state.post,
        category: state.category,
        author: state.author,
        member: state.member,
        enquiry: state.enquiry,
        newsletter: state.newsletter
    });
});

// synchronizing the redux state
initMessageListener(store);
