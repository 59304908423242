import ReactGA from 'react-ga4';

// Type definitions
type PageViewFunction = (path: string, title: string) => void;
type TriggerFunction = (triggerCategory: string, triggerLabel: string) => void;

// Enums for Google Analytics
enum GoogleAnalyticsEnum {
  // Pages ENUM
  HOME = "HOME",
  ABOUT_US = "ABOUT_US",
  CONTACT_US = "CONTACT_US",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  DISCLAIMER = "DISCLAIMER",
  PAGE_SEARCH = "PAGE_SEARCH",
  LANGUAGE_CLICK = "LANGUAGE_CLICK",
  SINGLE_AUTOHOR = "SINGLE_AUTOHOR",
  ARCHIVE_FILTER_VIEW = "ARCHIVE_FILTER_VIEW",
  POST_DETAIL_PAGE_VIEW = "POST_DETAIL_PAGE_VIEW",
  FEATURED_MEDIA_PAGE_VIEW = "FEATURED_MEDIA_PAGE_VIEW",
  GALLERY_PAGE_VIEW = "GALLERY_PAGE_VIEW",
  CATEGORY_BADGE_LIST_PAGE = "CATEGORY_BADGE_LIST_PAGE",
  POST_CARD_META_PAGE_VIEW = "POST_CARD_META_PAGE_VIEW",
  POST_CARD_META_PAGE_CLICK = "POST_CARD_META_PAGE_CLICK",
  POST_CARD_LIKE_AND_COMMENT = "POST_CARD_LIKE_AND_COMMENT",
  POST_CARD_LIKE_ACTION_PAGE_VIEW = "POST_CARD_LIKE_ACTION_PAGE_VIEW",
  COMMENT_BUTTON_PAGE_VIEW = "PAGE_COMMENT_BUTTON",
  NC_BOOKMARK_PAGE_VIEW = "NC_BOOKMARK_PAGE_VIEW",
  PAGE_CARD_CATEGORY2 = "PAGE_CARD_CATEGORY2",
  PAGE_AUTHOR_PROFILE_VIEW = "PAGE_AUTHOR_PROFILE_VIEW",
  CATEGORY_DETAIL_PAGE_VIEW = "PAGE_ARCHIVE_VIEW",
  SECTION_GRID_CATEGORY_BOX_PAGE_VIEW = "SECTION_GRID_CATEGORY_BOX_PAGE_VIEW",
  PAGE_SLIDER_NEW_AUTHOR = "PAGE_SLIDER_NEW_AUTHOR",
  LOGIN_PAGE_VIEW = "LOGIN_PAGE_VIEW",
  SIGNUP_PAGE_VIEW = "SIGNUP_PAGE_VIEW",
  RESET_PASSWORD_PAGE_VIEW = "RESET_PASSWORD_PAGE_VIEW",
  EDIT_COMMENT_MODEL_VIEW = "EDIT_COMMENT_MODEL_VIEW",
  SECTION_MAGAZINE_10 = "SECTION_MAGAZINE_10",
  SECTION_MAGAZINE_11 = "SECTION_MAGAZINE_11",
  SECTION_LATEST_POST_PAGE = "SECTION_LATEST_POST_PAGE",

  // Header Section Enum
  HEADER_HOME = "HEADER_HOME",
  HEADER_DISCLAIMER = "HEADER_DISCLAIMER",
  HEADER_PRIVACY_POLICY = "HEADER_PRIVACY_POLICY",
  HEADER_CONTACT_US = "HEADER_CONTACT_US",
  HEADER_ABOUT_US = "HEADER_ABOUT_US",

  // Button ENUM
  BUTTON = "BUTTON",
  HEADER_LOGO = "HEADER_LOGO",
  GLOBAL_SEARCH = "GLOBAL_SEARCH",
  SUBSCRIBE_NEWSLETTER = "SUBSCRIBE_NEWSLETTER",
  CONTACT_US_BUTTON = "CONTACT_US_BUTTON",
  COMMENT_SUBMIT_BUTTON = "COMMENT_SUBMIT_BUTTON",
  AUTHOR_PROFILE_LINK = "AUTHOR_PROFILE_LINK",
  AUTHOR_PROFILE_POST_CLICK = "AUTHOR_PROFILE_POST_CLICK",
  AUTHOR_PROFILE_LINK_CLICKED_FROM_POST = "AUTHOR_PROFILE_LINK_CLICKED_FROM_POST",
  SOCIAL_FACEBOOK_LINK = "SOCIAL_FACEBOOK_LINK",
  SOCIAL_LINKEDIN_LINK = "SOCIAL_LINKEDIN_LINK",
  SOCIAL_INSTAGRAM_LINK = "SOCIAL_INSTAGRAM_LINK",
  SOCIAL_YOUTUBE_LINK = "SOCIAL_YOUTUBE_LINK",
  SEARCH_BUTTON = "SEARCH_BUTTON",
  SELECT_ARCHIVE_FILTER_BUTTON = "SELECT_ARCHIVE_FILTER_BUTTON",
  FEATURED_MEDIA_BUTTON = "FEATURED_MEDIA_BUTTON",
  GALLERY_NAVIGATION_LEFT = "GALLERY_NAVIGATION_LEFT",
  GALLERY_NAVIGATION_RIGHT = "GALLERY_NAVIGATION_RIGHT",
  GALLERY_NAVIGATION_SELECT = "GALLERY_NAVIGATION_SELECT",
  POST_CARD_UNLIKE_BUTTON_CLICK = "POST_CARD_UNLIKE_BUTTON_CLICK",
  POST_CARD_LIKE_BUTTON_CLICK = "POST_CARD_LIKE_BUTTON_CLICK",
  CLICK_COMMENT_BUTTON = "CLICK_COMMENT_BUTTON",
  NC_BOOKMARK_REMOVE_BUTTON_CLICK = "NC_BOOKMARK_REMOVE_BUTTON_CLICK",
  NC_BOOKMARK_ADD_BUTTON_CLICK = "NC_BOOKMARK_ADD_BUTTON_CLICK",
  CARD_AUTHOR_BOX2_ARROW_BUTTON_CLICK = "CARD_AUTHOR_BOX2_ARROW_BUTTON_CLICK",
  LOGIN_PAGE_BUTTON = "LOGIN_PAGE_BUTTON",
  SIGNUP_PAGE_BUTTON_REGISTER = "SIGNUP_PAGE_BUTTON_REGISTER",
  FORGOT_PASSWORD_PAGE_BUTTON = "FORGOT_PASSWORD_PAGE_BUTTON",
  RESET_PASSWORD_PAGE_BUTTON = "RESET_PASSWORD_PAGE_BUTTON",
  CATEGORY_BADGE_LIST_CLICK = "CATEGORY_BADGE_LIST_CLICK",
  CATEGORY_BADGE_LIST_CLICK_FROM_POST = "CATEGORY_BADGE_LIST_CLICK_FROM_POST",
  CATEGORY_TAG_LINK_CLICK = "CATEGORY_TAG_LINK_CLICK",
  WIDGET_CATEGORY_CARD_CLICK = "WIDGET_CATEGORY_CARD_CLICK",
  WIDGET_AUTHOR_CARD_CLICK = "WIDGET_AUTHOR_CARD_CLICK",
  WIDGET_POST_CARD_CLICK = "WIDGET_POST_CARD_CLICK",
  // Menu ENUM
  MENU_ITEM = "MENU_ITEM",

  // Post Enums
  POST_CLICK = "POST_CLICK",

  // LINK ENUM
  LINK = "LINK",
  CARD_AUTHOR_BOX2_LINK_CLICK = "CARD_AUTHOR_BOX2_LINK_CLICK",
  CARD_CATEGORY2_LINK_CLICK = "CARD_CATEGORY2_LINK_CLICK",
  LOGIN_PAGE_LINK = "LOGIN_PAGE_LINK",
  SINGLE_PAGE_LINK_CLICK = "SINGLE_PAGE_LINK_CLICK",
  LINK_CLICK = "LINK_CLICK",
  SIGNUP_PAGE_LINK = "SIGNUP_PAGE_LINK",
  FORGET_PASSWORD_PAGE_LINK = "FORGET_PASSWORD_PAGE_LINK",

  // Category model list enum
  LINK_CATEGORY_MODEL_LIST = "LINK_CATEGORY_MODEL_LIST",
  AUTHOR_NAV_ITEMS= "AUTHOR_NAV_ITEMS",
  // Additional Enums...
  // Add the rest of the enums here as needed.
}

// Function implementations
const pageView: PageViewFunction = (path, title) => {
  ReactGA.send({ hitType: "pageview", page: path, title });
};

const triggerFunction: TriggerFunction = (triggerCategory, triggerLabel) => {
  ReactGA.event({
    category: triggerCategory,
    action: 'Click',
    label: triggerLabel,
  });
};

export { pageView, triggerFunction, GoogleAnalyticsEnum };