import React, { FC } from "react";

// project-imports
import { SectionMagazine1Props } from "./SectionMagazine1";
import Card19 from "components/Card19/Card19";
import Card18 from "components/Card18/Card18";
import { GoogleAnalyticsEnum, triggerFunction } from "utils/googleAnalytics";

// project-imports
import { useTranslation } from "react-i18next";

export interface SectionMagazine10Props extends SectionMagazine1Props { isLoading: boolean; }

const SectionMagazine10: FC<SectionMagazine10Props> = ({
  posts,
  className = "",
  isLoading = false,
}) => {

  const handleCardClick = (name: string) => {
    triggerFunction(GoogleAnalyticsEnum.HOME, GoogleAnalyticsEnum.SECTION_MAGAZINE_10 + "-" + GoogleAnalyticsEnum.POST_CLICK + "-" + name)
  }
  const { t } = useTranslation();

  return (
    <div className={`nc-SectionMagazine10 ${className}`}>
      {posts && posts.length ?
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
          <div className="grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-5 gap-5">
            {posts
              .filter((_, i) => i < 3 && i >= 1).filter((item) => item !== undefined)
              .map((item, index) => (
                <>
                  <Card18
                    showCategories={false}
                    className="sm:row-span-3 col-span-1"
                    key={index}
                    post={item || {}}
                    isLoading={isLoading}
                    onClick={handleCardClick}
                  />
                </>
              ))}

            {posts[3] && (
              <>
                <Card19
                  ratio="aspect-w-4 aspect-h-3 sm:aspect-h-1 sm:aspect-w-16 "
                  className="sm:col-span-2 sm:row-span-2"
                  titleClass="text-xl sm:text-2xl xl:text-2xl"
                  post={posts[3]}
                  showCategories={false}
                  isLoading={isLoading}
                  onClick={handleCardClick}
                />
              </>
            )}
          </div>

          {posts[0] && <Card19 className="" post={posts[0]} isLoading={isLoading} onClick={handleCardClick} />}
        </div>
        :
        <span>{t("no_data_found")}</span>
      }
    </div>
  );
};

export default SectionMagazine10;
