import React, { FC, useState } from "react";

//project-import 
import PostCardCommentBtn from "components/PostCardCommentBtn/PostCardCommentBtn";
import PostCardLikeAction from "components/PostCardLikeAction/PostCardLikeAction";
import { PostDataType, PostLikeDataType } from "data/types";
import { usePostContext } from "context/postContext";
import { useMemberContext } from "context/memberContext";
import ModalLogin from "components/ModalLogin/ModalLogin";

export interface PostCardLikeAndCommentProps {
  className?: string;
  itemClass?: string;
  hiddenCommentOnMobile?: boolean;
  useOnSinglePage?: boolean;
  post?: PostDataType;
  href?: string;
}

const PostCardLikeAndComment: FC<PostCardLikeAndCommentProps> = ({
  className = "",
  itemClass = "px-3 h-8 text-xs",
  hiddenCommentOnMobile = true,
  useOnSinglePage = false,
  post,
  href,
}) => {

  // stat variables 
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  // read state from store
  const { addPostLike, isLoading } = usePostContext();
  const { currentMember } = useMemberContext();
  let isLikeDisabled = isLoading || (currentMember && currentMember.id ? false : true);
  const member = localStorage.getItem(process.env.REACT_APP_USER_LOCALSTORAGE_KEY as string) ? currentMember : null;

  // Construct like data
  let totalLikes: number = 0;
  const currentUserId = currentMember ? currentMember.id || "" : "";
  let isLiked: boolean = false;

  if (!isLoading && post) {
    (post ? post.like || [] : []).forEach((item: PostLikeDataType) => {

      if (item.userId === currentUserId && item.isLiked) {
        isLiked = true;
      }
      if (item.userId) {
        totalLikes += item.count;
      }
    });
  }

  const openModalLogin = () => setIsLoggedOut(true);
  const closeModalLogin = () => setIsLoggedOut(false);

  const handleLikeBtnClick = (value: boolean) => {
    try {
      let likes: PostLikeDataType[] = post ? post.like || [] : [];
      if (!member) {
        return openModalLogin();
      } else {

        // Find the index of the current user's like
        const index = likes.findIndex((item) => item.userId === currentUserId);
        if (index !== -1) {
          likes = likes.map((like, i) =>
            i === index
              ? {
                ...like,
                count: value ? 1 : 0,
                isLiked: value,
              }
              : like
          );
        } else {
          likes = [
            ...likes,
            {
              userId: currentUserId,
              count: value ? 1 : 0,
              isLiked: value,
            },
          ];
        }

        addPostLike(
          currentUserId,
          (post ? post.id || "" : "").toString(),
          likes
        );
      }
    } catch (error) {
      console.log("36=>PostCardLikeAndComment=>handleLikeBtnClick=>", error);
    }
  };

  const title = post?.title || "No Post Title";

  return (
    <div
      className={`nc-PostCardLikeAndComment flex items-center space-x-2 ${className}`}
    >
      <PostCardLikeAction
        className={itemClass}
        likeCount={totalLikes}
        liked={isLiked}
        isLoading={isLoading}
        isDisabled={isLikeDisabled}
        postTitle={title}
        handleLikeClick={handleLikeBtnClick}
      />
      <PostCardCommentBtn
        className={`${hiddenCommentOnMobile
          ? "hidden sm:flex"
          : "flex"}  ${itemClass}`}
        isATagOnSingle={useOnSinglePage}
        count={post?.commentCount}
        href={href}
      />
      <ModalLogin
        show={isLoggedOut}
        onCloseModalLogin={closeModalLogin}
      />
    </div>
  );
};

export default PostCardLikeAndComment;
