import React, { FC, useState } from "react";

// project-imports
import ButtonPrimary from "components/Button/ButtonPrimary";
import CommentCard from "components/CommentCard/CommentCard";
import { usePostContext } from "context/postContext";

// third-party
import { useTranslation } from "react-i18next";

export interface SingleCommentListsProps {
  postId?: string | number;
}

const SingleCommentLists: FC<SingleCommentListsProps> = ({ postId }) => {

  const { t } = useTranslation();
  const { comments } = usePostContext();

  //stat variables
  const [showAllComments, setShowAllComments] = useState(false);

  const handleClickViewAll = () => {
    try {
      setShowAllComments(!showAllComments);
    } catch (error) {
      console.log("27: Error while Viewing all comments", error);
      throw error;
    }
  }

  // sorrting comments in recent date format
  const sortedComments = [...(comments || [])].sort((a, b) => new Date(b.createdAt || "").getTime() - new Date(a.createdAt || "").getTime());

  return (
    <ul className="nc-SingleCommentLists space-y-5">
      {(sortedComments || []).filter((_, i) => showAllComments || i < 5).map((comment, index) => {
        return <CommentCard key={index} comment={comment} />;
      })}
      {comments && comments.length > 5 &&
        <ButtonPrimary className="dark:bg-primary-700 w-full" onClick={handleClickViewAll}>
          {showAllComments ? t("Hide_comments") : t("View_all_comments")}
        </ButtonPrimary>
      }
    </ul>
  );
};

export default SingleCommentLists;
