import React, { useEffect } from "react";

// project-import
import SectionMagazine10 from "components/Sections/SectionMagazine10";
import SectionMagazine11 from "components/Sections/SectionMagazine11";
import SectionLatestPosts from "components/Sections/SectionLatestPosts";
import { GoogleAnalyticsEnum, pageView } from "utils/googleAnalytics";
import LoadingVideo from "components/LoadingVideo/LoadingVideo";
import { DEMO_POSTS_MAIN } from "data/posts";
import { usePostContext } from "context/postContext";
import { useCategoryContext } from "context/categoryContext";
import { useAuthorContext } from "context/authorContext";

// third-party
import { useTranslation } from "react-i18next";
import _ from "lodash";

const PageHomeDemo6: React.FC = () => {
  const { t } = useTranslation();
  // read store state
  const { isLoading: isCatLoading, categories } = useCategoryContext();
  const { isLoading: isAuthorLoading, authors } = useAuthorContext();
  const { isLoading: isPostLoading, posts } = usePostContext();

  const allPosts = DEMO_POSTS_MAIN(posts || [], categories || [], authors || []);
  const isLoading = isCatLoading || isAuthorLoading || isPostLoading;

  // construct-data
  // const MAGAZINE1_POSTS = allPosts.filter((_, i) => i >= 8 && i < 16);
  const MAGAZINE1_POSTS = allPosts.filter((_, i) => i >= 0 && i < 9 && _.postType !== "audio");

  useEffect(() => {

    // Tracking page view for home page on Google Analytics
    pageView(window.location.pathname, GoogleAnalyticsEnum.HOME);
  }, [])

  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">

      <div className="relative overflow-hidden">
        <div className="container relative">
          {isLoading ? (
            <LoadingVideo />
          ) : (
            <>
              <SectionMagazine10 posts={MAGAZINE1_POSTS} isLoading={isLoading} />

              {/* === SECTION 11 === */}
              <SectionMagazine11 className="py-16 lg:py-24" />
            </>
          )
          }
        </div>

        {/* === SECTION 11 === */}
        <div className="dark bg-neutral-900 dark:bg-black dark:bg-opacity-20 text-neutral-100">
          <div className="relative container">
            <SectionLatestPosts
              heading={t("latest_articles")}
              className="py-16 lg:py-24"
              posts={_.orderBy(allPosts, ['date'], ['desc']).filter((_, i) => i > 0 && i < 18)}
              postCardName="card4"
              gridClass="sm:grid-cols-2"
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHomeDemo6;
