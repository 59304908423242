export enum ActionType {
    // post context constants
    GET_POST_BEGIN = "GET_POST_BEGIN",
    GET_POST_SUCCESS = "GET_POST_SUCCESS",
    GET_POST_FAILURE = "GET_POST_FAILURE",
    POST_ADD_LIKE_BEGIN = "POST_ADD_LIKE_BEGIN",
    POST_ADD_LIKE_SUCCESS = "POST_ADD_LIKE_SUCCESS",
    POST_ADD_LIKE_FAILURE = "POST_ADD_LIKE_FAILURE",
    POST_ADD_BOOKMARK_BEGIN = "POST_ADD_BOOKMARK_BEGIN",
    POST_ADD_BOOKMARK_SUCCESS = "POST_ADD_BOOKMARK_SUCCESS",
    POST_ADD_BOOKMARK_FAILURE = "POST_ADD_BOOKMARK_FAILURE",
    GET_ALL_COMMENTS_BY_POST_ID_BEGIN = "GET_ALL_COMMENTS_BY_POST_ID_BEGIN",
    GET_ALL_COMMENTS_BY_POST_ID_SUCCESS = "GET_ALL_COMMENTS_BY_POST_ID_SUCCESS",
    GET_ALL_COMMENTS_BY_POST_ID_FAILURE = "GET_ALL_COMMENTS_BY_POST_ID_FAILURE",
    POST_ADD_COMMENT_BEGIN = "POST_ADD_COMMENT_BEGIN",
    POST_ADD_COMMENT_SUCCESS = "POST_ADD_COMMENT_SUCCESS",
    POST_ADD_COMMENT_FAILURE = "POST_ADD_COMMENT_FAILURE",
    POST_UPDATE_COMMENT_BEGIN = "POST_UPDATE_COMMENT_BEGIN",
    POST_UPDATE_COMMENT_SUCCESS = "POST_UPDATE_COMMENT_SUCCESS",
    POST_UPDATE_COMMENT_FAILURE = "POST_UPDATE_COMMENT_FAILURE",
    POST_DELETE_COMMENT_BEGIN = "POST_DELETE_COMMENT_BEGIN",
    POST_DELETE_COMMENT_SUCCESS = "POST_DELETE_COMMENT_SUCCESS",
    POST_DELETE_COMMENT_FAILURE = "POST_DELETE_COMMENT_FAILURE",
    POST_ADD_COMMENT_REPLY_BEGIN = "POST_ADD_COMMENT_REPLY_BEGIN",
    POST_ADD_COMMENT_REPLY_SUCCESS = "POST_ADD_COMMENT_REPLY_SUCCESS",
    POST_ADD_COMMENT_REPLY_FAILURE = "POST_ADD_COMMENT_REPLY_FAILURE",
    POST_UPDATE_COMMENT_REPLY_BEGIN = "POST_UPDATE_COMMENT_REPLY_BEGIN",
    POST_UPDATE_COMMENT_REPLY_SUCCESS = "POST_UPDATE_COMMENT_REPLY_SUCCESS",
    POST_UPDATE_COMMENT_REPLY_FAILURE = "POST_UPDATE_COMMENT_REPLY_FAILURE",
    POST_ADD_COMMENT_LIKE_BEGIN = "POST_ADD_COMMENT_LIKE_BEGIN",
    POST_ADD_COMMENT_LIKE_SUCCESS = "POST_ADD_COMMENT_LIKE_SUCCESS",
    POST_ADD_COMMENT_LIKE_FAILURE = "POST_ADD_COMMENT_LIKE_FAILURE",
    POST_ADD_REPORT_BEGIN = "POST_ADD_REPORT_BEGIN",
    POST_ADD_REPORT_SUCCESS = "POST_ADD_REPORT_SUCCESS",
    POST_ADD_REPORT_FAILURE = "POST_ADD_REPORT_FAILURE",
    CHANGE_POST_LANGUAGE_BEGIN = "CHANGE_POST_LANGUAGE_BEGIN",
    CHANGE_POST_LANGUAGE_SUCCESS = "CHANGE_POST_LANGUAGE_SUCCESS",
    CHANGE_POST_LANGUAGE_FAILURE = "CHANGE_POST_LANGUAGE_FAILURE",

    // category context constants
    GET_CATEGORY_BEGIN = "GET_CATEGORY_BEGIN",
    GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS",
    GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE",

    // author context constants
    GET_AUTHOR_BEGIN = "GET_AUTHOR_BEGIN",
    GET_AUTHOR_SUCCESS = "GET_AUTHOR_SUCCESS",
    GET_AUTHOR_FAILURE = "GET_AUTHOR_FAILURE",

    //member context constants
    GET_MEMBER_LOGIN_BEGIN = "GET_MEMBER_LOGIN_BEGIN",
    GET_MEMBER_LOGIN_SUCCESS = "GET_MEMBER_LOGIN_SUCCESS",
    GET_MEMBER_LOGIN_FAILURE = "GET_MEMBER_LOGIN_FAILURE",
    GET_MEMBER_LOGOUT_BEGIN = "GET_MEMBER_LOGOUT_BEGIN",
    GET_MEMBER_LOGOUT_SUCCESS = "GET_MEMBER_LOGOUT_SUCCESS",
    GET_MEMBER_LOGOUT_FAILURE = "GET_MEMBER_LOGOUT_FAILURE",
    GET_MEMBER_REGISTER_BEGIN = "GET_MEMBER_REGISTER_BEGIN",
    GET_MEMBER_REGISTER_SUCCESS = "GET_MEMBER_REGISTER_SUCCESS",
    GET_MEMBER_REGISTER_FAILURE = "GET_MEMBER_REGISTER_FAILURE",
    CHECK_MEMBER_LOGIN_STATUS_BEGIN = "CHECK_MEMBER_LOGIN_STATUS_BEGIN",
    CHECK_MEMBER_LOGIN_STATUS_SUCCESS = "CHECK_MEMBER_LOGIN_STATUS_SUCCESS",
    CHECK_MEMBER_LOGIN_STATUS_FAILURE = "CHECK_MEMBER_LOGIN_STATUS_FAILURE",
    MEMBER_FORGOT_PASSWORD_BEGIN = "MEMBER_FORGOT_PASSWORD_BEGIN",
    MEMBER_FORGOT_PASSWORD_SUCCESS = "MEMBER_FORGOT_PASSWORD_SUCCESS",
    MEMBER_FORGOT_PASSWORD_FAILURE = "MEMBER_FORGOT_PASSWORD_FAILURE",
    RESET_FORGOT_PASSWORD_STATUS = "RESET_FORGOT_PASSWORD_STATUS",
    MEMBER_RESET_PASSWORD_BEGIN = "MEMBER_RESET_PASSWORD_BEGIN",
    MEMBER_RESET_PASSWORD_SUCCESS = "MEMBER_RESET_PASSWORD_SUCCESS",
    MEMBER_RESET_PASSWORD_FAILURE = "MEMBER_RESET_PASSWORD_FAILURE",

    // enquiry context constants
    SUBMIT_ENQUIRY_BEGIN = "SUBMIT_ENQUIRY_BEGIN",
    SUBMIT_ENQUIRY_SUCCESS = "SUBMIT_ENQUIRY_SUCCESS",
    SUBMIT_ENQUIRY_FAILURE = "SUBMIT_ENQUIRY_FAILURE",

    // newsletter context constants
    SUBSCRIBE_NEWSLETTER_BEGIN = "SUBSCRIBE_NEWSLETTER_BEGIN",
    SUBSCRIBE_NEWSLETTER_SUCCESS = "SUBSCRIBE_NEWSLETTER_SUCCESS",
    SUBSCRIBE_NEWSLETTER_FAILURE = "SUBSCRIBE_NEWSLETTER_FAILURE",
    UNSUBSCRIBE_NEWSLETTER_BEGIN = "UNSUBSCRIBE_NEWSLETTER_BEGIN",
    UNSUBSCRIBE_NEWSLETTER_SUCCESS = "UNSUBSCRIBE_NEWSLETTER_SUCCESS",
    UNSUBSCRIBE_NEWSLETTER_FAILURE = "UNSUBSCRIBE_NEWSLETTER_FAILURE",
}