import {
  ActionType
} from "../constants/actions";

export const initialState = {
  isLoading: false,
  error: null,
  authors: [],
};

const authorReducer = (state = initialState, action: { type: ActionType; payload: any; }) => {
  if (action.type === ActionType.GET_AUTHOR_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
    };
  }
  if (action.type === ActionType.GET_AUTHOR_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      authors: action.payload,
      error: null,
    };
  }
  if (action.type === ActionType.GET_AUTHOR_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  }

  return state;
};

export default authorReducer;
