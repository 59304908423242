
import { combineReducers } from '@reduxjs/toolkit';
import authorReducer from './authorReducer';
import categoryReducer from './categoryReducer';
import memberReducer from './memberReducer';
import postReducer from './postReducer';
import enquiryReducer from './enquiryReducer';
import newsletterReducer from './newsletterReducer';

// combining the reducers
const rootReducers = combineReducers({
    author: authorReducer,
    category: categoryReducer,
    member: memberReducer,
    post: postReducer,
    enquiry: enquiryReducer,
    newsletter: newsletterReducer
});

export type RootState = ReturnType<typeof rootReducers>;
export default rootReducers;

