import React, { useEffect, useState } from "react";

// project-imports
import ModalCategories from "../ModalCategories";
import { DEMO_POSTS_MAIN } from "data/posts";
import { PostDataType, TaxonomyType } from "data/types";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import Card11 from "components/Card11/Card11";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import Image from "components/Image";
import { useCategoryContext } from "context/categoryContext";
import { useAuthorContext } from "context/authorContext";
import LoadingVideo from "components/LoadingVideo/LoadingVideo";
import { usePostContext } from "context/postContext";
import Message from "components/Message/Message";
import { GoogleAnalyticsEnum, pageView, triggerFunction } from "utils/googleAnalytics";

// third-party
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Tag and category have same data type - we will use one demo data
// const posts: PostDataType[] = DEMO_POSTS.filter((_, i) => i < 16);
const FILTERS = [
  // { name: "" },  
  // { name: "most_viewed" },
  // { name: "curated_by_admin" },
  { name: "most_appreciated" },
  { name: "most_discussed" },
  // { name: "most_viewed" },
];


const PageArchive = () => {
  // read store state
  const { isLoading: isCatLoading, categories } = useCategoryContext();
  const { isLoading: isAuthorLoading, authors } = useAuthorContext();
  const { isLoading: isPostLoading, posts: postsStore } = usePostContext();

  // local constants
  const location = useLocation();
  const { t } = useTranslation();

  // local state variables
  const [tabActive, setTabActive] = useState<string>("");
  const [filterActive, setFilterActive] = useState<string>(FILTERS[0].name);
  const [postsByCategory, setPostsByCategory] = useState<PostDataType[]>();
  const [posts, setPosts] = useState<PostDataType[]>();
  const [category, setCategory] = useState<TaxonomyType>();

  const isLoading = isCatLoading || isAuthorLoading || isPostLoading;
  // const posts: PostDataType[] = allPosts.filter((_, i) => i < 16);

  const handleClickTab = (selectedId: string) => {
    try {
      triggerFunction(GoogleAnalyticsEnum.CATEGORY_DETAIL_PAGE_VIEW, (GoogleAnalyticsEnum.LINK_CLICK) + "-" + (GoogleAnalyticsEnum.LINK_CATEGORY_MODEL_LIST) + "-" + (selectedId));
      setTabActive(selectedId);
      handleFilterClick(filterActive, selectedId);

    } catch (error) {
      throw error;
    }
  };

  const filterByCategory = (posts: PostDataType[], categoryId: string) => {
    try {
      // Filter posts by category
      // const allPosts = DEMO_POSTS_MAIN(postsStore || [], categories || [], authors || []);
      const filteredPosts: PostDataType[] = posts.filter((item) =>
        item.categories && item.categories.some((item) => item.id === categoryId)
      );
      return filteredPosts;
    } catch (error) {
      throw error;
    }
  }

  const handleFilterClick = (filterItem: string, categoryId: string) => {
    try {
      let filteredPosts: PostDataType[] = [...(postsByCategory || [])];
      if (filterItem === 'most_viewed') {
        // TO DO LATER
      } else if (filterItem === 'most_appreciated') {
        filteredPosts.sort((a, b) =>
          (b.like?.filter((obj) => obj.userId !== null && obj.isLiked).length || 0) -
          (a.like?.filter((obj) => obj.userId !== null && obj.isLiked).length || 0)
        );
      } else if (filterItem === 'most_bookmarked') {
        filteredPosts.sort((a, b) =>
          (b.bookmark?.filter((obj) => obj.userId !== null && obj.isBookmarked).length || 0) -
          (a.bookmark?.filter((obj) => obj.userId !== null && obj.isBookmarked).length || 0)
        );
      } else if (filterItem === 'most_discussed') {
        filteredPosts.sort((a, b) =>
          (b?.commentCount || 0) -
          (a?.commentCount || 0)
        );
      }
      // 
      const postsFilteredByCategory = filterByCategory(filteredPosts, categoryId);
      setPosts(postsFilteredByCategory);
      setFilterActive(filterItem);
      setTabActive("");
    } catch (error: any) {
      console.error("Error in handleFilterClick:", error?.message);
      throw error;
    }
  };

  useEffect(() => {
    try {
      const lastElement: string = location && location.pathname && location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
      if (lastElement) {
        pageView(lastElement, GoogleAnalyticsEnum.CATEGORY_DETAIL_PAGE_VIEW);
        const allPosts = DEMO_POSTS_MAIN(postsStore || [], categories || [], authors || []);
        const allCategories = categories || [];

        const filteredCategories: TaxonomyType[] = allCategories.filter(
          (item) => (item.name || "").toLowerCase().replaceAll("?", "").replaceAll(",", " ") === (lastElement || "").replaceAll("-", " ").replaceAll(",", " ").toLowerCase()
        );

        if (filteredCategories && filteredCategories.length) {
          setCategory(filteredCategories[0]);

          // Filter posts by category
          const filteredPosts: PostDataType[] = allPosts.filter((item) =>
            item.categories && item.categories.some((category) => category.id === filteredCategories[0].id)
          );

          // Check if there are posts or not
          if (filteredPosts.length > 0) {
            setPosts(filteredPosts);
            setPostsByCategory(filteredPosts);
          } else {
            // Explicitly set posts to an empty array when no posts are found
            setPosts([]);
          }
        } else {
          // In case no category is found, ensure we set posts to an empty array
          setPosts([]);
        }
      }

    } catch (error: any) {
      console.error("Error while updating the single post:", error?.message);
      throw error;
    }
  }, [location, authors, categories, postsStore]);

  return (
    <div className={`nc-PageArchive`}>
      {/* If loading, show the spinner */}
      {isLoading &&
        <LoadingVideo />
      }
      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
        <div className="relative aspect-w-16 aspect-h-13 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-5 rounded-3xl md:rounded-[40px] overflow-hidden z-0">
          <Image
            alt="archive"
            fill
            src={"https://1drv.ms/i/s!AspeZW4-Xpd1gUrDEzP-25eOaWub?embed=1&width=2250&height=1500"}
            className="object-cover w-full h-full rounded-3xl md:rounded-[40px]"
            sizes="(max-width: 1280px) 100vw, 1536px"
          />
          <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
            <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
              {t(`${(category?.name || "").replaceAll(" ", "_").replaceAll("-", "_")}`)}
            </h2>
            <span className="block mt-4 text-neutral-300">{category?.count} {t("articles")}</span>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container pt-10 pb-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <div>
          <div className="flex flex-col sm:justify-between sm:flex-row">
            <div className="flex space-x-2.5">
              <ModalCategories categories={categories} onClick={(selectedId: string) => handleClickTab(selectedId)} selectedId={tabActive} />
              {/* <ModalTags tags={DEMO_TAGS} /> */}
            </div>
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
            <div className="flex justify-end">
              <ArchiveFilterListBox lists={FILTERS} handleFilterClick={(item: string) => { triggerFunction(GoogleAnalyticsEnum.BUTTON, category?.name || ""); handleFilterClick(item, (category?.id || "").toString()); }} />
            </div>
          </div>

          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
            {(posts || []).map((post) => (
              <Card11 key={post.id} post={post} onClick={(name: string) => { triggerFunction(GoogleAnalyticsEnum.POST_CLICK + "-" + name, GoogleAnalyticsEnum.SECTION_LATEST_POST_PAGE + "-" + GoogleAnalyticsEnum.CATEGORY_TAG_LINK_CLICK + "-" + category?.name) }} />
            ))}
          </div>

          {/* NO DATA FOUND */}
          {(!posts || posts.length === 0) &&
            <div className=" mt-8 lg:mt-10 w-full">
              <Message type="info" children={t("no_article_found_message")} />
            </div>
          }

          {/* PAGINATIONS */}
          {/* <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination />
            <ButtonPrimary>Show me more</ButtonPrimary>
          </div> */}
        </div>

        {/* MORE SECTIONS */}
        {/* === SECTION 5 === */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridCategoryBox
            categories={categories.filter((_, i) => i < 12)}
          />
          {/* <div className="text-center mx-auto mt-10 md:mt-16">
            {categories.filter((_, i) => i > 10).length ?
              <ButtonSecondary>{t("show_me_more")}</ButtonSecondary> : ""
            }
          </div> */}
        </div>

        {/* === SECTION 5 === */}
        {/* <SectionSliderNewAuthors
          heading="Top elite authors"
          subHeading="Discover our elite writers"
          authors={authors.filter((_, i) => i < 10)}
        /> */}

        {/* SUBCRIBES */}
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageArchive;
