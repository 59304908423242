import { TaxonomyType } from "data/types";
import {
  ActionType
} from "../constants/actions";

export const initialState = {
  isLoading: false,
  error: null,
  categories: [],
};

const categoryReducer = (state = initialState, action: { type: ActionType; payload: any; }) => {
  if (action.type === ActionType.GET_CATEGORY_BEGIN) {
    return {
      ...state,
      isLoading: action.payload,
      error: null,
    };
  }
  if (action.type === ActionType.GET_CATEGORY_SUCCESS) {
    const Category: TaxonomyType[] = [...action.payload];
    const sortedCategory = Category.sort((a,b) => (b.count || 0) - (a.count || 0));
    return {
      ...state,
      isLoading: false,
      categories: sortedCategory,
      error: null,
    };
  }
  if (action.type === ActionType.GET_CATEGORY_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  }

  return state;
};

export default categoryReducer;
