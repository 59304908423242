import React, { useEffect } from "react";

// project-import
import { GoogleAnalyticsEnum, pageView } from "utils/googleAnalytics";
import Heading2 from "components/Heading/Heading2";

// third-party
import { useTranslation } from "react-i18next";

const PageDisclaimer = () => {

  const { t } = useTranslation();
  useEffect(() => {
    // Tracking page view for ABOUT page on Google Analytics
    pageView(window.location.pathname, GoogleAnalyticsEnum.DISCLAIMER);
  }, [])

  return (
    <div className={`container`}>
      <div className="container pb-16 px-2 lg:pb-16">
        <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
          <div className="w-screen max-w-full space-y-5 lg:space-y-7 prose lg:prose-lg dark:prose-invert">
            <header className="text-center max-w-2xl mx-auto">
              <Heading2>{t("disclaimer")}</Heading2>
              <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                {t("disclaimer_subtext")}
              </span>
            </header>

            <ol style={{ listStyle: "revert" }} className="block text-base text-md text-neutral-6000 dark:text-neutral-400 text-left">
              <li className="mt-2">
                <div dangerouslySetInnerHTML={{ __html: t(`disclaimer_list_1`).replace(/(<? *script)/gi, 'illegalscript') }} ></div>
              </li>
              <li className="mt-2">
                <div dangerouslySetInnerHTML={{ __html: t(`disclaimer_list_2`).replace(/(<? *script)/gi, 'illegalscript') }} ></div>
              </li>
              <li className="mt-2">
                <div dangerouslySetInnerHTML={{ __html: t(`disclaimer_list_3`).replace(/(<? *script)/gi, 'illegalscript') }} ></div>
              </li>
              <li className="mt-2">
                <div dangerouslySetInnerHTML={{ __html: t(`disclaimer_list_4`).replace(/(<? *script)/gi, 'illegalscript') }} ></div>
              </li>
              <li className="mt-2">
                <div dangerouslySetInnerHTML={{ __html: t(`disclaimer_list_5`).replace(/(<? *script)/gi, 'illegalscript') }} ></div>
              </li>
              <li className="mt-2">
                <div dangerouslySetInnerHTML={{ __html: t(`disclaimer_list_6`).replace(/(<? *script)/gi, 'illegalscript') }} ></div>
              </li>
              <li className="mt-2">
                <div dangerouslySetInnerHTML={{ __html: t(`disclaimer_list_7`).replace(/(<? *script)/gi, 'illegalscript') }} ></div>
              </li>
            </ol>
            <blockquote>
              <i>{t(`disclaimer_quote`)}</i>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageDisclaimer;
