import React from "react";

// project-imports
import Logo from "components/Logo/Logo";
import { CustomLink } from "data/types";

// third-party
import { useTranslation } from "react-i18next";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

// const widgetMenus: WidgetFooterMenu[] = [
//   {
//     id: "5",
//     title: "Getting started",
//     menus: [
//       { href: "/", label: "Installation" },
//       { href: "/", label: "Release Notes" },
//       { href: "/", label: "Upgrade Guide" },
//       { href: "/", label: "Browser Support" },
//       { href: "/", label: "Editor Support" },
//     ],
//   },
//   {
//     id: "1",
//     title: "Explore",
//     menus: [
//       { href: "/", label: "Design features" },
//       { href: "/", label: "Prototyping" },
//       { href: "/", label: "Design systems" },
//       { href: "/", label: "Pricing" },
//       { href: "/", label: "Customers" },
//     ],
//   },
//   {
//     id: "2",
//     title: "Resources",
//     menus: [
//       { href: "/", label: "Best practices" },
//       { href: "/", label: "Support" },
//       { href: "/", label: "Developers" },
//       { href: "/", label: "Learn design" },
//       { href: "/", label: "What's new" },
//     ],
//   },
//   {
//     id: "4",
//     title: "Community",
//     menus: [
//       { href: "/", label: "Discussion Forums" },
//       { href: "/", label: "Code of Conduct" },
//       { href: "/", label: "Community Resources" },
//       { href: "/", label: "Contributing" },
//       { href: "/", label: "Concurrent Mode" },
//     ],
//   },
// ];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  const { t } = useTranslation();
  return (
    <div className="nc-Footer relative border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-1 lg:md:sm:grid-cols-2 gap-y-3 lg:gap-y-0 lg:gap-x-2 items-center">
        {/* Logo Section */}
        <div className="flex items-center justify-center lg:justify-end">
          <Logo />
        </div>
        {/* Copyright Section */}
        <div className="flex items-center justify-center lg:justify-start">
          <p className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
            {`© 2025 Finance Optimize. ${t("all_rights_reserved")} | `}
            <a href={process.env.REACT_APP_LONGSWITCH_LINK} rel="noreferrer" target="_blank" className="text-green-500">Powered By Longswitch</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
