import { useEffect } from "react";
import { Provider } from "react-redux";

// third-party
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
// import * as dotenv from "dotenv";

// project-import
import MyRoutes from "./routers";
import { store } from "store/store";
import { CategoriesProvider } from "context/categoryContext";
import { AuthorProvider } from "context/authorContext";
import { MemberProvider } from "context/memberContext";
import { PostProvider } from "context/postContext";
import { EnquiryProvider } from "context/enquiryContext";
import { NewsletterProvider } from "context/newsletterContext";

function App() {
  // Load environment variables from .env file 
  // dotenv.config();

  const GA_TAGMANAGER_ID = (process.env.REACT_APP_GA_TAGMANAGER_ID || "").toString();
  const GA_MEASURMENT_ID = (process.env.REACT_APP_GA_MEASURMENT_ID || "").toString();

  const tagManagerArgs = { gtmId: GA_TAGMANAGER_ID }
  // initialize Google analytics start
  useEffect(() => {
    ReactGA.initialize(GA_MEASURMENT_ID);
    TagManager.initialize(tagManagerArgs);
  }, [])

  return (
    <Provider store={store}>

      <CategoriesProvider store={store}>
        <AuthorProvider store={store}>
          <MemberProvider store={store}>
            <PostProvider store={store}>
              <EnquiryProvider store={store}>
                <NewsletterProvider store={store}>
                  <div className="bg-[#f8f8f8] text-base dark:bg-neutral-900/95 text-neutral-900 dark:text-neutral-200 font-body">
                    <MyRoutes />
                  </div>
                </NewsletterProvider>
              </EnquiryProvider>
            </PostProvider>
          </MemberProvider>
        </AuthorProvider>
      </CategoriesProvider>
    </Provider >
  );
}

export default App;
