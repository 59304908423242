import React, { FC, useEffect, useRef } from "react";

// project-imports
import NcModal from "components/NcModal/NcModal";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonThird from "../Button/ButtonThird";

// third-party
import { useTranslation } from "react-i18next";

export interface ModalDeleteCommentProps {
  show: boolean;
  onOpenModalDeleteComment?: () => void;
  onCloseModalDeleteComment: () => void;
}

const ModalDeleteComment: FC<ModalDeleteCommentProps> = ({
  show,
  onOpenModalDeleteComment,
  onCloseModalDeleteComment,
}) => {
  const { t } = useTranslation();
  const textareaRef = useRef(null);

  useEffect(() => {
    try {
      if (show) {
        setTimeout(() => {
          const element: HTMLTextAreaElement | null = textareaRef.current;
          if (element) {
            (element as HTMLTextAreaElement).focus();
          }
        }, 400);
      }     
    } catch (error) {
      console.log("36::Error while opening the delete modal=>",error);
      throw error;
    }
  }, [show]);

  const renderContent = () => {
    return (
      <div>
        <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
          {t("delete_comment")}
        </h3>
        <span className="text-sm">
          {t("delete_comment_message")}
        </span>
        <div className="mt-4 space-x-3">
          <ButtonPrimary
            className="!bg-red-500"
            onClick={onOpenModalDeleteComment}
            type="button"
          >
            {`${t("delete")}`}
          </ButtonPrimary>
          <ButtonThird type="button" onClick={onCloseModalDeleteComment}>
            {`${t("cancel")}`}
          </ButtonThird>
        </div>
      </div>
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalDeleteComment}
      contentExtraClass="max-w-screen-sm"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle=""
    />
  );
};

export default ModalDeleteComment;
