import React, { FC } from "react";

// project-imports
import Badge from "components/Badge/Badge";
import Card18 from "components/Card18/Card18";
import Heading from "components/Heading/Heading";
import { DEMO_POSTS_MAIN } from "data/posts";
import { PostDataType, TaxonomyType, TwMainColor } from "data/types";
import Link from "components/Link";
import { useCategoryContext } from "context/categoryContext";
import { useAuthorContext } from "context/authorContext";
import { usePostContext } from "context/postContext";
import SectionMagazine11Skeleton from "components/Skeleton/SectionMagazine11Skeleton";
import { GoogleAnalyticsEnum, triggerFunction } from "utils/googleAnalytics";

// third-party
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

export interface SectionMagazine11Props {
  posts?: PostDataType[][];
  className?: string;
  categories?: TaxonomyType[];
}

const SectionMagazine11: FC<SectionMagazine11Props> = ({
  posts = [],
  className = "",
  categories = [],
}) => {
  const { t } = useTranslation();

  // read store state
  const { isLoading: isCatLoading, categories: storeCategories } = useCategoryContext();
  const { isLoading: isAuthorLoading, authors } = useAuthorContext();
  const { isLoading: isPostLoading, posts: storePosts } = usePostContext();
  const isLoading = isCatLoading || isAuthorLoading || isPostLoading;
  const allPosts = DEMO_POSTS_MAIN((storePosts || []).filter(item => item.postType === "audio"), categories || [], authors || []);

  // CONSTRUCT POST DATA
  const postsDemo1: PostDataType[] = allPosts.filter(
    (_, i) => i > -1 && i < 1
  );
  const postsDemo2: PostDataType[] = allPosts.filter(
    (_, i) => i > 0 && i < 2
  );
  const postsDemo3: PostDataType[] = allPosts.filter(
    (_, i) => i > 1 && i < 3
  );

  let demoRelated: PostDataType[][] = posts;
  if (demoRelated && !demoRelated.length) {
    demoRelated = [postsDemo1, postsDemo2, postsDemo3];
  }

  // CONSTRUCT CATEGORY DATA
  let demoCategories: TaxonomyType[] = categories;

  if (demoCategories && !demoCategories.length) {
    demoCategories = [storeCategories[0], storeCategories[1], storeCategories[2]];
  }

  const renderListByCat = (
    category: TaxonomyType,
    listPosts: PostDataType[]
  ) => {

    const handleCardClick = (name: string) => {
      triggerFunction(GoogleAnalyticsEnum.HOME, GoogleAnalyticsEnum.SECTION_MAGAZINE_11 + "-" + GoogleAnalyticsEnum.POST_CLICK + "-" + name)
    }

    const handleBadgeClick = (name: string) => {
      triggerFunction(GoogleAnalyticsEnum.HOME, GoogleAnalyticsEnum.SECTION_MAGAZINE_11 + "-" + GoogleAnalyticsEnum.CATEGORY_BADGE_LIST_CLICK + "-" + name)
    }

    return (
      <div key={category.id} className={`flex flex-col space-y-4`}>
        <div className="flex items-center justify-between">
          <Badge
            className="uppercase tracking-wide rounded-none px-4 py-1.5"
            name={t(category?.name && (category?.name || "").replaceAll(" ", "_").replaceAll("-", "_"))}
            color={category.color as TwMainColor}
            onClick={handleBadgeClick}
            href={category.href}
          />
          <a href={category.href} className="flex items-center text-xs text-neutral-500">
            <span>{t("more_articles")}</span>
            <ArrowRightIcon className="w-6 h-6 ml-1.5" />
          </a>
        </div>
        {demoRelated[0] && demoRelated[0].length ? (
          <Card18
            ratio="aspect-w-4 aspect-h-3"
            className="flex-shrink-0"
            post={listPosts[0] || {}}
            isLoading={isLoading}
            onClick={handleCardClick}
          />
        ) : (
          <span className={`flex flex-col space-y-4`}>{t("no_data_found")}</span>
        )}
        <ul className="space-y-3">
          {listPosts && listPosts
            .filter((_, i) => i > 0)
            .map((post) => (
              <li key={post.id}>
                <h2 className="nc-card-title flex items-start font-medium space-x-4">
                  <Badge
                    className="w-2.5 h-2.5 !p-0 rounded flex-shrink-0 mt-2"
                    name={""}
                    color={category.color as TwMainColor}
                    onClick={handleBadgeClick}
                  />
                  <Link href={post.href} title={post.title} className="flex" onClick={() => triggerFunction(GoogleAnalyticsEnum.HOME, GoogleAnalyticsEnum.SECTION_MAGAZINE_11 + "-" + GoogleAnalyticsEnum.POST_CLICK + "-" + post.title)}>
                    {post.title}
                  </Link>
                </h2>
              </li>
            ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      {isLoading ? <SectionMagazine11Skeleton className={className} /> :
        <div className={`nc-SectionMagazine11 relative ${className}`}>
          <Heading desc={t("click_on_music_icon_and_enjoy_music_or_podcast")}>
            {t("listen_to_audio_blogs")}
          </Heading>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 sm:gap-4 md:gap-7">
            {(demoCategories || []).map((cate, i) => cate && cate.count !== 0 ? renderListByCat(cate || {}, demoRelated[i]) : <span className={`flex flex-col space-y-4`}>{t("no_data_found")}</span>)}
          </div>
        </div>}
    </>
  );
};

export default SectionMagazine11;
