import React, { CSSProperties, FC, useState } from "react";

// assets
import default_image_error from "../images/finance_optimize_placeholder_image.png";

interface Props extends Partial<Omit<HTMLImageElement, "style">> {
  fill?: boolean;
  style?: CSSProperties;
  onLoadingComplete?: () => void;
  src?: string;
}

const Image: FC<Props> = ({
  fill = false,
  className,
  alt = "",
  style,
  src,
  onLoadingComplete,
  ...args
}) => {

  const [defaultImage, setDefaultImage] = useState(src);

  const onImageError = () => {
    try {
      setDefaultImage(default_image_error);
    }
    catch (error) {
      console.log("25::Error while setting default image", error);
      throw error;
    }
  };
  
  return (
    //   @ts-ignore
    <img
      {...args}
      className={
        className + (fill ? " object-cover absolute inset-0 w-full h-full" : "")
      }
      alt={alt}
      style={style}
      onLoad={onLoadingComplete}
      onError={onImageError}
      src={defaultImage}
    />
  );
};

export default Image;
