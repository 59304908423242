import React, { FC, useEffect, useState } from "react";

// project-imports
import PostCardSaveAction from "components/PostCardSaveAction/PostCardSaveAction";
import { PostDataType } from "data/types";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import PostFeaturedMedia from "components/PostFeaturedMedia/PostFeaturedMedia";
import Link from "components/Link";
import { GoogleAnalyticsEnum, pageView, triggerFunction } from "utils/googleAnalytics";

export interface Card11Props {
  className?: string;
  post: PostDataType;
  ratio?: string;
  hiddenAuthor?: boolean;
  onClick?: (name: string) => void;
}

const Card11: FC<Card11Props> = ({
  className = "h-full",
  post,
  hiddenAuthor = false,
  ratio = "aspect-w-4 aspect-h-3",
  onClick = () => { }
}) => {
  const { title, href, categories, date } = post;

  const [isHover, setIsHover] = useState(false);

  // Tracking page view for POST DETAIL PAGE VIEW page on Google Analytics
  useEffect(() => {
    pageView(window.location.pathname, GoogleAnalyticsEnum.POST_DETAIL_PAGE_VIEW);
  }, []);


  return (
    <div
      className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 ${className}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 ${ratio}`}
      >
        <div>
          <PostFeaturedMedia post={post} isHover={isHover} />
        </div>
      </div>
      <Link href={href} className="absolute inset-0" onClick={() => onClick(title)}></Link>
      <span className="absolute top-3 inset-x-3 z-10">
        <CategoryBadgeList categories={categories} onClick={(name: string) => { triggerFunction(GoogleAnalyticsEnum.CATEGORY_DETAIL_PAGE_VIEW + "-" + title, GoogleAnalyticsEnum.CATEGORY_BADGE_LIST_CLICK_FROM_POST + "-" + name); }} />
      </span>

      <div className="p-4 flex flex-col space-y-3">
        {!hiddenAuthor ? (
          <PostCardMeta meta={post} onClick={(name: string) => { triggerFunction(GoogleAnalyticsEnum.POST_CARD_META_PAGE_CLICK, GoogleAnalyticsEnum.CATEGORY_DETAIL_PAGE_VIEW + "-" + GoogleAnalyticsEnum.AUTHOR_PROFILE_LINK_CLICKED_FROM_POST + "-" + title + "-" + name) }} />
        ) : (
          <span className="text-xs text-neutral-500">{date}</span>
        )}
        <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          <span className="line-clamp-2" title={title}>
            {title}
          </span>
        </h3>
        <div className="flex items-end justify-between mt-auto">
          <PostCardLikeAndComment className="relative" post={post} href={href} />
          <PostCardSaveAction className="relative" post={post} />
        </div>
      </div>
    </div>
  );
};

export default Card11;
