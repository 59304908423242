import React, { FC } from "react";

export interface SkeletonProps {
  className?: string;
}

const WidgetHeading1Skeleton: FC<SkeletonProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-WidgetHeading1 flex items-center justify-between p-4 xl:p-5 border-b border-neutral-200 dark:border-neutral-700 animate-pulse ${className}`}
    >
      <div className="text-lg h-8 w-16 bg-slate-100 text-neutral-900 dark:text-neutral-100 font-semibold flex-grow">
      </div>
        <div
          className="flex-shrink-0 h-6 w-12 block text-primary-700 dark:text-primary-500 font-semibold text-sm"
        >
        </div>
    </div>
  );
};

export default WidgetHeading1Skeleton;
