import React, { FC } from "react";

export interface SkeletonProps {
    className?: string;
}

const CommentCardSkeleton: FC<SkeletonProps> = ({ className = "animate-pulse" }) => {
    return (
        <>
            <div className={`nc-CommentCard flex ${className}`}>
                <div className={`h-6 w-6 bg-slate-200 text-base sm:h-8 sm:w-8 mt-4 rounded-full`}></div>
                <div className="flex-grow flex flex-col p-4 ml-2 text-sm border border-neutral-200 rounded-xl sm:ml-3 sm:text-base dark:border-neutral-700">
                    {/* AUTHOR INFOR */}
                    <div className="relative flex items-center pr-6">
                        <div className="absolute -right-3 -top-3">
                            <div className={`p-2 text-neutral-500 flex items-center justify-center rounded-lg h-4 w-10 bg-slate-200`}></div>
                        </div>
                        <div className="flex-shrink-0 font-semibold text-neutral-800 dark:text-neutral-100 h-4 w-20 bg-slate-200 rounded-md">
                        </div>
                        <span className="mx-2">.</span>
                        <span className="h-4 w-32 bg-slate-200 rounded-md text-neutral-500 dark:text-neutral-400 text-xs line-clamp-1 sm:text-sm">
                        </span>
                    </div>

                    {/* CONTENT */}
                    <span className="block h-20 w-full bg-slate-200 rounded-md mt-2 mb-3 sm:mt-3 sm:mb-4 ">
                    </span>

                    {/* ACTION LIKE REPLY */}
                    <div className="flex flex-row">
                    <button className={`w-20 min-w-[68px] flex items-center rounded-full leading-none px-3 h-8 text-xs bg-slate-200`}>
                    </button>
                    <button className={`flex items-center w-20 min-w-[68px] rounded-full px-3 h-8 bg-slate-200`}>
                    </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CommentCardSkeleton;
