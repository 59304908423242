import React, { FC, useRef, useState } from "react";

// project-imports
import Avatar from "components/Avatar/Avatar";
import NcDropDown, { NcDropDownItem } from "components/NcDropDown/NcDropDown";
import twFocusClass from "utils/twFocusClass";
import ModalEditComment from "./ModalEditComment";
import ModalDeleteComment from "./ModalDeleteComment";
import ModalReportItem from "components/ModalReportItem/ModalReportItem";
import Link from "components/Link";
import SingleCommentForm from "app/(singles)/SingleCommentForm";
import CommentCardLikeReply from "../CommentCardLikeReply/CommentCardLikeReply";
import { usePostContext } from "context/postContext";
import { PostCommentDataType, PostLikeDataType } from "data/types";
import { useMemberContext } from "context/memberContext";
import moment from "moment";
import { CommentAction } from "constants/comment";
import CommentReplyDropdown from "./CommentReplyDropdown";
import ModalLogin from "components/ModalLogin/ModalLogin";

// const DEMO_COMMENTS = [
//   {
//     id: 1,
//     author: null,
//     date: "May 20, 2021",
//     content:
//       "Praesent id massa id nisl venenatis lacinia. Aenean sit amet justo. Morbi ut odio.",
//     like: { count: 96, isLiked: false },
//   },

//   {
//     id: 3,
//     author: null,
//     date: "May 20, 2021",
//     content:
//       "Mauris enim leo, rhoncus sed, vestibulum sit amet, cursus id, turpis. Integer aliquet, massa id lobortis convallis, tortor risus dapibus augue, vel accumsan tellus nisi eu orci. Mauris lacinia sapien quis libero.\n\nNullam sit amet turpis elementum ligula vehicula consequat. Morbi a ipsum. Integer a nibh.",
//     like: { count: 66, isLiked: true },
//   },
//   {
//     id: 4,
//     author: null,
//     date: "May 20, 2021",
//     content:
//       "In sagittis dui vel nisl. Duis ac nibh. Fusce lacus purus, aliquet at, feugiat non, pretium quis, lectus.\n\nSuspendisse potenti. In eleifend quam a odio. In hac habitasse platea dictumst.",
//     like: { count: 45, isLiked: true },
//   },
// ];

// const AUTHOR = DEMO_AUTHORS[0];
export interface CommentType {
  id: string;
  date: string;
  content: string;
  authorName: string;
  postId: string | number;
  like: {
    count: number;
    isLiked: boolean;
  };
}

export interface CommentCardProps {
  className?: string;
  comment?: PostCommentDataType;
  size?: "large" | "normal";
}

const CommentCard: FC<CommentCardProps> = ({
  className = "",
  comment = {} as PostCommentDataType,
  size = "large"
}) => {

  // Read state from store
  const { isLoading, deletePostComment, addPostCommentLike } = usePostContext();
  const { currentMember } = useMemberContext();
  const member = localStorage.getItem(process.env.REACT_APP_USER_LOCALSTORAGE_KEY as string) ? currentMember : null;
  // Construct like data
  let totalLikes: number = 0;
  const currentUserId = currentMember ? currentMember.id || "" : "";
  const isAdmin = comment && currentUserId === comment.userId;
  let isLiked: boolean = false;

  let commentObj: CommentType = {} as CommentType;

  if (comment && !isLoading) {
    (comment ? comment.likes || [] : []).forEach((item: PostLikeDataType) => {
      if (item.userId === currentUserId && item.isLiked) {
        isLiked = true;
      }
      if (item.userId) {
        totalLikes += item.count;
      }
    });
    commentObj.content = comment.content;
    commentObj.id = comment.id || "";
    commentObj.postId = comment.postId || "";
    commentObj.authorName = comment.userName;

    commentObj.like = {
      isLiked: isLiked,
      count: totalLikes
    }

    commentObj.date = (comment.createdAt === comment.updatedAt ? comment.createdAt : comment.updatedAt) || "";
  }

  const { date, content, like, authorName, id, postId } = commentObj;

  const actions: NcDropDownItem[] = [
    ...(isAdmin ? [
      {
        id: "edit",
        name: "Edit",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
  </svg>`,
      },

      //   {
      //     id: "report",
      //     name: "Report abuse",
      //     icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      //   <path stroke-linecap="round" stroke-linejoin="round" d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5" />
      // </svg>
      // `,
      //   },
      {
        id: "delete",
        name: "Delete",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
  </svg>
  `,
      },
      {
        id: "reply",
        name: "Reply",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
  </svg>`,
      },
    ] : [
      {
        id: "reply",
        name: "Reply",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
  </svg>`,
      },
    ])
  ];

  const textareaRef = useRef(null);
  const [isReplying, setIsReplying] = useState(false);
  const [isEditting, setIsEditting] = useState(false);
  const [isReporting, setIsReporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const openReplyForm = () => {
    setIsReplying(true);
    setTimeout(() => {
      textareaRef.current && (textareaRef.current as any).focus();
    }, 100);
  };
  const closeReplyForm = () => {
    setIsReplying(false);
  };

  const openModalEditComment = () => setIsEditting(true);
  const closeModalEditComment = () => setIsEditting(false);

  const openModalReportComment = () => setIsReporting(true);
  const closeModalReportComment = () => setIsReporting(false);

  const openModalDeleteComment = () => deleteComment(member && member.token, id);
  const closeModalDeleteComment = () => setIsDeleting(false);

  const deleteComment = (token: string, commentId: string) => {
    try {
      setIsDeleting(true)
      deletePostComment(token, commentId);
    } catch (error: any) {
      throw error;
    }
  };

  const hanldeClickDropDown = (item: typeof actions[number]) => {
    if (item.id === "reply") {
      return openReplyForm();
    }
    if (item.id === "edit") {
      return openModalEditComment();
    }
    if (item.id === "report") {
      return openModalReportComment();
    }
    if (item.id === "delete") {
      return setIsDeleting(true);
    }
    return;
  };

  const renderCommentForm = (action: string) => {
    return (
      <SingleCommentForm
        textareaRef={textareaRef}
        onClickSubmit={closeReplyForm}
        onClickCancel={closeReplyForm}
        className="flex-grow"
        action={action}
        commentId={id}
        postId={postId}
        authorName={authorName}
      />
    );
  };

  const handleCommentLike = (value: boolean) => {
    try {

      let likes: PostLikeDataType[] = comment && comment.likes ? JSON.parse(JSON.stringify(comment.likes)) || [] : [];
      // Check if the user is logged in
      if (!member) {
        return openModalLogin();
      }

      // Find the index of the current user's like
      const index = likes.findIndex((item) => item.userId === currentUserId);
      if (index !== -1) {
        likes = likes.map((like, i) =>
          i === index
            ? {
              ...like,
              count: value ? 1 : 0,
              isLiked: value,
            }
            : like
        );
      } else {
        likes = [
          ...likes,
          {
            userId: currentUserId,
            count: value ? 1 : 0,
            isLiked: value,
          },
        ];
      }

      if (likes && likes.length) {
        addPostCommentLike(currentUserId, id, likes);
      }
    } catch (error: any) {
      throw error;
    }
  }

  const openModalLogin = () => setIsLoggedOut(true);
  const closeModalLogin = () => setIsLoggedOut(false);

  const showReplies = comment && comment.replies && comment.replies.length ? true : false;

  return (
    <>
      <div className={`nc-CommentCard flex ${className}`}>
        <Avatar
          sizeClass={`h-6 w-6 text-base ${size === "large" ? "sm:text-lg sm:h-8 sm:w-8" : ""
            }`}
          radius="rounded-full"
          containerClassName="mt-4"
          userName={authorName}
        />
        <div className="flex-grow flex flex-col p-4 ml-2 text-sm border border-neutral-200 rounded-xl sm:ml-3 sm:text-base dark:border-neutral-700">
          {/* AUTHOR INFOR */}
          <div className="relative flex items-center pr-6">
            <div className="absolute -right-3 -top-3">
              <NcDropDown
                className={`p-2 text-neutral-500 flex items-center justify-center rounded-lg hover:text-neutral-800 dark:hover:text-neutral-200 sm:hover:bg-neutral-100 dark:hover:bg-neutral-800 ${twFocusClass()}`}
                data={actions}
                onClick={hanldeClickDropDown}
              />
            </div>
            <Link
              className="flex-shrink-0 font-semibold text-neutral-800 dark:text-neutral-100"
              href={""}
            >
              {authorName}
            </Link>
            <span className="mx-2">·</span>
            <span className="text-neutral-500 dark:text-neutral-400 text-xs line-clamp-1 sm:text-sm">
              {moment(date).format('lll')}
            </span>
          </div>

          {/* CONTENT */}
          <span className="block text-neutral-700 mt-2 mb-3 sm:mt-3 sm:mb-4 dark:text-neutral-300">
            {/* <div dangerouslySetInnerHTML={{ __html: content.replace(/(<? *script)/gi, 'illegalscript') }} ></div> */}
            {content}
          </span>

          {/* ACTION LIKE REPLY */}
          {isReplying ? (
            renderCommentForm(CommentAction.ADD_COMMENT_REPLY)
          ) : (
            <CommentCardLikeReply
              className={className}
              isLiked={like.isLiked}
              likeCount={like.count}
              token={currentUserId}
              commentId={id}
              onClickReply={() => setIsReplying(true)}
              onClickLike={handleCommentLike}
            />
          )}
          {showReplies && <CommentReplyDropdown comment={comment} />}

        </div>
      </div>

      <ModalEditComment
        show={isEditting}
        onCloseModalEditComment={closeModalEditComment}
        defaultValue={content}
        commentId={id}
        postId={postId}
        authorName={authorName}
      />
      <ModalReportItem
        show={isReporting}
        onCloseModalReportItem={closeModalReportComment}
      />
      <ModalDeleteComment
        show={isDeleting}
        onOpenModalDeleteComment={openModalDeleteComment}
        onCloseModalDeleteComment={closeModalDeleteComment}
      />
      <ModalLogin
        show={isLoggedOut}
        onCloseModalLogin={closeModalLogin}
      />
    </>
  );
};

export default CommentCard;
