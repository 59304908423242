import React, { useEffect, useState } from "react";
import { ShareIcon } from "@heroicons/react/24/outline";

// project-imports
import Image from "components/Image";
import NcImage from "components/NcImage/NcImage";
import { DEMO_POSTS_MAIN } from "data/posts";
import VerifyIcon from "components/VerifyIcon";
import { PostAuthorType, PostDataType } from "data/types";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import SocialsList from "components/SocialsList/SocialsList";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import Card11 from "components/Card11/Card11";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import NcDropDown, { NcDropDownItem } from "components/NcDropDown/NcDropDown";
import { SOCIALS_DATA } from "components/SocialsShare/SocialsShare";
import AccountActionDropdown from "components/AccountActionDropdown/AccountActionDropdown";
import { useCategoryContext } from "context/categoryContext";
import { useAuthorContext } from "context/authorContext";
import LoadingVideo from "components/LoadingVideo/LoadingVideo";
import PageAuthorSkeleton from "components/Skeleton/PageAuthorSkeleton";
import Message from "components/Message/Message";
import { usePostContext } from "context/postContext";
import { GoogleAnalyticsEnum, pageView, triggerFunction } from "utils/googleAnalytics";

// third-party
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// const posts: PostDataType[] = DEMO_POSTS.filter((_, i) => i < 12);
const FILTERS = [
  // { name: "most_viewed" },
  // { name: "curated_by_admin" },
  { name: "most_appreciated" },
  { name: "most_discussed" },
  { name: "most_bookmarked" },
  // { name: "most_viewed" },
];
const TABS = ["articles", "favorites", "saved"];

const PageAuthor = () => {

  const { t } = useTranslation();
  const location = useLocation();

  // state variables
  const [tabActive, setTabActive] = useState<string>(TABS[0]);
  const [filterActive, setFilterActive] = useState<string>(FILTERS[0].name);
  const [postsByAuthor, setPostsByAuthor] = useState<PostDataType[]>();
  const [posts, setPosts] = useState<PostDataType[]>();
  const [author, setAuthor] = useState<PostAuthorType>();

  // read state from store
  const { isLoading: isCatLoading, categories } = useCategoryContext();
  const { isLoading: isAuthorLoading, authors } = useAuthorContext();
  const { isLoading: isPostLoading, posts: postsStore } = usePostContext();

  // Combine both loading states into one variable
  const isLoading = isCatLoading || isAuthorLoading || isPostLoading;

  let text = author?.displayName || "";
    
    // const currentURL = url ? `${window.location.protocol} //${window.location.host}${url.pathname}${url.search}${url.hash}` : "";
    const currentURL = window.location.href;

    const onClickShareIcon = (socialData: NcDropDownItem) => {
      try {
        if (!socialData || !socialData.id) return;
        const encodedURL = encodeURIComponent(currentURL || window.location.href);
        const encodedText = encodeURIComponent(text || "");
        let shareURL = "";
        switch (socialData.id) {
          case "Linkedin":
            shareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedURL}`;
            break;
          case "Twitter":
            shareURL = `https://twitter.com/intent/tweet?text=${encodedText}%20${encodedURL}`;
            break;
          case "Facebook":
            shareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`;
            break;
          case "Whatsapp":
            shareURL = `https://wa.me/?text=${encodedText}%20${encodedURL}`;
            break;
          case "Instagram":
            shareURL = `${process.env.REACT_APP_INSTAGRAM_LINK as string}`;
            break;
          case "Threads":
            shareURL = `https://www.threads.net/share?url=${encodedURL}`;
            break;
          case "YouTube":
            shareURL = `https://www.youtube.com/`;
            break;
          default:
            console.error("Unsupported social media platform:", socialData.id);
            return;
        }
        window.open(shareURL, "_blank", "width=600,height=600");
      } catch (error) {
        console.error("Error sharing link:", error);
      }
    };

  const handleClickTab = (item: string) => {
    try {
      setTabActive(item);
      handleFilterClick(filterActive, item);

    } catch (error) {
      throw error;
    }
  };

  const filterByTab = (posts: PostDataType[], item: string) => {
    try {
      if (item === 'articles') {
        return (posts || []);
      } else if (item === 'favorites') {
        return posts.filter((item) =>
          item && item.like && item.like.filter((obj) => obj.userId !== null && obj.isLiked).length
        );
      } else if (item === 'saved') {
        return posts.filter((item) =>
          item && item.bookmark && item.bookmark.filter((obj) => obj.userId !== null && obj.isBookmarked).length
        );
      }
      return posts;
    } catch (error) {
      throw error;
    }
  }

  const handleFilterClick = (filterItem: string, tabItem: string) => {
    try {
      let filteredPosts: PostDataType[] = [...(postsByAuthor || [])];
      if (filterItem === 'most_viewed') {
        // TO DO LATER
      } else if (filterItem === 'most_appreciated') {
        filteredPosts.sort((a, b) =>
          (b.like?.filter((obj) => obj.userId !== null && obj.isLiked).length || 0) -
          (a.like?.filter((obj) => obj.userId !== null && obj.isLiked).length || 0)
        );
      } else if (filterItem === 'most_bookmarked') {
        filteredPosts.sort((a, b) =>
          (b.bookmark?.filter((obj) => obj.userId !== null && obj.isBookmarked).length || 0) -
          (a.bookmark?.filter((obj) => obj.userId !== null && obj.isBookmarked).length || 0)
        );
      } else if (filterItem === 'most_discussed') {
        filteredPosts.sort((a, b) =>
          (b.commentCount || 0) -
          (a.commentCount || 0)
        );
      }
      // 
      const tabFilteredByPosts = filterByTab(filteredPosts, tabItem);
      setPosts(tabFilteredByPosts);
      setFilterActive(filterItem);
    } catch (error: any) {
      console.error("Error in handleFilterClick:", error?.message);
      throw error;
    }
  };

  useEffect(() => {
    try {
      const lastElement: string = location && location.pathname && location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

      if (lastElement) {
        pageView(lastElement, GoogleAnalyticsEnum.PAGE_AUTHOR_PROFILE_VIEW);
        const allPosts = DEMO_POSTS_MAIN(postsStore || [], categories || [], authors || []);
        const allAuthors = authors || [];

        const filteredAuthors: PostAuthorType[] = allAuthors.filter((item) => (item.id || "").toLowerCase().replaceAll("?", "") === (lastElement || "").replaceAll("-", " ").replaceAll(",", " ").toLowerCase());

        if (filteredAuthors && filteredAuthors.length) {
          setAuthor(filteredAuthors[0]);
          // Check for post ID & check for user login
          if (filteredAuthors[0].id && allPosts && allPosts.length) {
            const filteredPosts: PostDataType[] = allPosts.filter((item) => (item.author && item.author.id) === filteredAuthors[0].id);
            // const filteredPosts: PostDataType[] = allPosts.filter((item) => item.authorId === filteredAuthors[0].id);
            if (filteredPosts && filteredPosts.length) {
              setPosts(filteredPosts);
              setPostsByAuthor(filteredPosts);
            }
          }
        }
      }
    } catch (error: any) {
      console.log("50:Error while updating the single post :", error?.message)
      throw error;
    }

  }, [location, authors, postsStore]);

  return (
    <div className={`nc-PageAuthor `}>
      {/* Show the loading spinner if data is still being fetched */}
      {isLoading && <LoadingVideo />}

      {/* HEADER */}
      {isLoading ? <PageAuthorSkeleton /> :
        <>
          <div className="w-full">
            <div className="relative w-full h-40 md:h-60 2xl:h-72">
              <NcImage
                alt=""
                containerClassName="absolute inset-0"
                sizes="(max-width: 1280px) 100vw, 1536px"
                src={author?.bgImage}
                className="object-cover w-full h-full"

              />
            </div>
            <div className="container -mt-10 lg:-mt-16">
              <div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row">
                <div className="w-32 lg:w-40 flex-shrink-0 mt-12 sm:mt-0">
                  <div className="wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold rounded-full w-20 h-20 text-xl lg:text-2xl lg:w-36 lg:h-36 ring-4 ring-white dark:ring-0 shadow-2xl z-0">
                    <Image
                      alt="Avatar"
                      src={author?.avatar}
                      fill
                      className="object-cover"
                    />
                  </div>
                </div>

                {/*  */}
                <div className="pt-5 md:pt-1 lg:ml-6 xl:ml-12 flex-grow">
                  <div className="max-w-screen space-y-3.5 ">
                    <h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
                      <span>{t((author?.displayName || "").replace((author?.displayName || ""), "author_main_desk"))}</span>
                      <VerifyIcon
                        className="ml-2"
                        iconClass="w-6 h-6 sm:w-7 sm:h-7 xl:w-8 xl:h-8"
                      />
                    </h2>
                    <span className="block text-sm text-neutral-500 dark:text-neutral-400">{t((author?.desc || "").replace((author?.desc || ""), "author_desc"))}
                    </span>
                    {/* <a
                      href="##"
                      className="flex items-center text-xs font-medium space-x-2.5 cursor-pointer text-neutral-500 dark:text-neutral-400 truncate"
                      onClick={() => triggerFunction(GoogleAnalyticsEnum.LINK, GoogleAnalyticsEnum.AUTHOR_PROFILE_LINK)}
                    >
                      <GlobeAltIcon className="flex-shrink-0 w-4 h-4" />
                      <span className="text-neutral-700 dark:text-neutral-300 truncate">
                        {author?.href}
                      </span>
                    </a> */}
                    <SocialsList itemClass="block w-7 h-7" />
                  </div>
                </div>

                {/*  */}
                <div className="absolute md:static left-5 right-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex justify-end">
                  {/* <FollowButton
                    isFollowing={false}
                    fontSize="text-sm md:text-base font-medium"
                    sizeClass="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8"
                  /> */}

                  <div className="mx-2">
                    <NcDropDown
                      className="flex-shrink-0 flex items-center justify-center focus:outline-none h-10 w-10 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 text-neutral-700 dark:text-neutral-200 rounded-full"
                      renderTrigger={() => <ShareIcon className="h-5 w-5" />}
                      onClick={onClickShareIcon}
                      data={SOCIALS_DATA}
                    />
                  </div>

                  <AccountActionDropdown containerClassName="h-10 w-10 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700" href={author?.href} />
                </div>
              </div>
            </div>
          </div>

          {/* ====================== END HEADER ====================== */}

          <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
            <main>
              {/* TABS FILTER */}
              <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
                <Nav className="sm:space-x-2">
                  {TABS.map((item, index) => (
                    <NavItem
                      key={index}
                      isActive={tabActive === item}
                      onClick={() => { triggerFunction(GoogleAnalyticsEnum.LINK, item + "-" + GoogleAnalyticsEnum.AUTHOR_NAV_ITEMS); handleClickTab(item); }}
                    >
                      {t(item)}
                    </NavItem>
                  ))}
                </Nav>
                <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
                <div className="flex justify-end">
                  <ArchiveFilterListBox lists={FILTERS} handleFilterClick={(item: string) => { handleFilterClick(item, tabActive); }} />
                </div>
              </div>

              {/* LOOP ITEMS */}
              <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
                {(posts || []).map((post) => (
                  <Card11 key={post.id} post={post} onClick={(name: string) => { triggerFunction(GoogleAnalyticsEnum.POST_CLICK, GoogleAnalyticsEnum.AUTHOR_PROFILE_POST_CLICK + "-" + name) }} />
                ))}
              </div>

              {/* NO DATA FOUND */}
              {posts && !posts.length &&
                <div className=" mt-8 lg:mt-10 w-full">
                  <Message type="info" children={t("no_article_found_message")} />
                </div>
              }

              {/* PAGINATION */}
              {/* <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                <Pagination />
                <ButtonPrimary>{t("show_me_more")}</ButtonPrimary>
              </div> */}
            </main>

            {/* === SECTION 5 === */}
            <div className="relative py-16">
              <BackgroundSection />
              <SectionGridCategoryBox
                categories={(categories || []).filter((_, i) => i < 12)}
              />
              {/* <div className="text-center mx-auto mt-10 md:mt-16">
               {categories.filter((_, i) => i > 10).length ?
                  <ButtonSecondary loading>{t("show_me_more")}</ButtonSecondary> : ""
                }
              </div> */}
            </div>

            {/* === SECTION 5 === */}
            {/* <SectionSliderNewAuthors
              heading={t("top_elite_authors")}
              subHeading={t("top_elite_authors_desc")}
              authors={authors.filter((_, i) => i < 10)}
            /> */}

            {/* SUBCRIBES */}
            <SectionSubscribe2 />
          </div>
        </>
      }
    </div>
  );
};

export default PageAuthor;
