import React, { FC, useEffect, useRef, useState } from "react";

// project-import
import NcModal from "components/NcModal/NcModal";
import Textarea from "components/Textarea/Textarea";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { RadioGroup } from "app/headlessui";
import twFocusClass from "utils/twFocusClass";
import ButtonThird from "../Button/ButtonThird";
import ModalLogin from "components/ModalLogin/ModalLogin";
import { useMemberContext } from "context/memberContext";
import { PostReportDataType } from "data/types";
import { usePostContext } from "context/postContext";

// third-party
import { useTranslation } from "react-i18next";

export interface ProblemPlan {
  id: string;
  name: string;
  label: string;
}

export interface ModalReportItemProps {
  show: boolean;
  problemPlans?: ProblemPlan[];
  onCloseModalReportItem: () => void;
  postId?: string | number;
}

const problemPlansDemo = [
  { name: "Violence", id: "Violence", label: "Violence" },
  { name: "Trouble", id: "Trouble", label: "Trouble" },
  { name: "Spam", id: "Spam", label: "Spam" },
  { name: "Other", id: "Other", label: "Other" },
];

const ModalReportItem: FC<ModalReportItemProps> = ({
  problemPlans = problemPlansDemo,
  show,
  onCloseModalReportItem,
  postId = "",
}) => {
  const textareaRef = useRef(null);

  // stat constants
  const { t } = useTranslation();

  // store action readers
  const { addPostReport} = usePostContext();
  const { currentMember } = useMemberContext();

  // local storage reader
  const member = localStorage.getItem(process.env.REACT_APP_USER_LOCALSTORAGE_KEY as string) ? currentMember : null;
  const currentUserId = currentMember ? currentMember.id || "" : "";

  // stat variables 
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [problemSelected, setProblemSelected] = useState<ProblemPlan>();
  const [reportItems, setReportItems] = useState<PostReportDataType>({ userId: currentUserId, postId: postId, reason: "", content: "" });
  const [valdiationError, setValidationError] = useState<PostReportDataType>({ postId: "", userId: "", reason: "", content: "" });

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const element: HTMLTextAreaElement | null = textareaRef.current;
        if (element) {
          (element as HTMLTextAreaElement).focus();
        }
      }, 400);
      // Update `userId` and `postId` when the modal is shown
      setReportItems((prev) => ({
        ...prev,
        userId: currentUserId,
        postId: postId,
      }));
    }
  }, [show, postId, currentUserId]);

  const openModalLogin = () => setIsLoggedOut(true);
  const closeModalLogin = () => setIsLoggedOut(false);
  // handle change function

  const handleChangeReason = (e: { id: string, name: string, label: string }) => {
    try {
      setProblemSelected(e);
      let report: PostReportDataType = reportItems;
      report.reason = e.name;
      setValidationError(checkValidation(report, "reason"));
      setReportItems(report);
    } catch (error: any) {
      console.log(`add report ${e.name} error:`, error.message);
      throw error;
    }
  }

  const handleChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let name: string = "";
    try {
      let value = e.target.value;
      name = e.target.name;
      let report: PostReportDataType = reportItems;
      if (name === "content") {
        report.content = value;
      }
      setValidationError(checkValidation(report, name));
      setReportItems(report);
    } catch (error: any) {
      console.log(`Member registeration ${name} error:`, error.message);
      throw error;
    }
  }

  const checkValidation = (report: PostReportDataType, name: string) => {
    try {
      let validationErrorObj: PostReportDataType = { postId: "", userId: "", reason: "", content: "" };

      if (name === "all" || name === "content") {
        if (report && !report.content) {
          validationErrorObj.content = t("message_error_1");
        }
        else if (report && report.content && (report.content.length < 6 || report.content.length > 500)) {
          validationErrorObj.content = t("message_error_2");
        } else {
          validationErrorObj.content = "";
        }
      }
      if (name === "all" || name === "reason") {
        if (report && !report.reason) {
          validationErrorObj.reason = "Please select any reason!";
        } else {
          validationErrorObj.reason = "";
        }
      }

      setValidationError(validationErrorObj);
      return validationErrorObj;
    } catch (error: any) {
      console.log('Enquiry Validation Error:', error.message);
      throw error;
    }
  }

  const handleClickSubmitForm = () => {
    try {
      if (!member) {
        return openModalLogin();
      }
      // Validation check for all fields
      let formIsValid = true;
      const validationErrros = checkValidation(reportItems, "all");

      if (validationErrros.content || validationErrros.reason) {
        formIsValid = false;
      }

      if (formIsValid) {
        const newReportDataClone: PostReportDataType = reportItems
        // Call add report action
        addPostReport(currentUserId, postId.toString(), newReportDataClone);
        setReportItems({ postId: "", userId: "", reason: "", content: "" });
      }
    } catch (error: any) {
      console.log('add report action Error:', error.message);
      throw error;
    }
  };

  const renderCheckIcon = () => {
    return (
      <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
        <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
        <path
          d="M7 13l3 3 7-7"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div>
          {/* RADIO PROBLEM PLANS */}
          <RadioGroup value={problemSelected} onChange={handleChangeReason} name="reason">
            <RadioGroup.Label className="sr-only">Problem Plans</RadioGroup.Label>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
              {problemPlans.map((plan) => (
                <RadioGroup.Option
                  key={plan.name}
                  value={plan}
                  className={({ checked }) => {
                    return (
                      `${checked
                        ? "bg-primary-6000 text-white dark:bg-primary-700"
                        : "bg-white border-t border-neutral-50 "
                      } relative shadow-lg rounded-lg px-3 py-3 cursor-pointer flex sm:px-5 sm:py-4 focus:outline-none ` +
                      twFocusClass(true)
                    );
                  }}
                >
                  {({ checked }) => (
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium line-clamp-1 ${checked ? "text-white" : "text-neutral-900"
                              }`}
                          >
                            {t(`${plan.label}`)}
                          </RadioGroup.Label>
                        </div>
                      </div>
                      {checked && (
                        <div className="flex-shrink-0 text-white">
                          {renderCheckIcon()}
                        </div>
                      )}
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
            {valdiationError && valdiationError.reason && (
              <p className="text-red-500 text-xs mt-1">{valdiationError.reason}</p>
            )}
          </RadioGroup>

          {/* TEXAREA MESSAGER */}
          <div className="mt-4">
            <h4 className="text-lg font-semibold text-neutral-700 dark:text-neutral-200">
              {t("Message")}
            </h4>
            <span className="text-sm text-neutral-6000 dark:text-neutral-400">
              {t("message_caption")}
            </span>
            <Textarea
              placeholder="..."
              className="mt-3"
              rows={4}
              name="content"
              id="report-message"
              value={reportItems.content}
              onChange={handleChangeMessage}
            />
            {valdiationError && valdiationError.content && (
              <p className="text-red-500 text-xs mt-1">{valdiationError.content}</p>
            )}
          </div>
          <div className="mt-4 space-x-3">
            <ButtonPrimary onClick={handleClickSubmitForm} type="button">
              {`${t("submit")}`}
            </ButtonPrimary>
            <ButtonThird type="button" onClick={onCloseModalReportItem}>
              {`${t("cancel")}`}
            </ButtonThird>
          </div>
        </div>
        <ModalLogin
          show={isLoggedOut}
          onCloseModalLogin={closeModalLogin}
        />
      </>
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalReportItem}
      contentExtraClass="max-w-screen-md"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle={t("report_abuse")}
    />
  );
};

export default ModalReportItem;
