import React, { FC } from "react";

// project-import
import { TaxonomyType } from "data/types";
import Link from "components/Link";
import TagsSkeleton from "components/Skeleton/TagsSkeleton";

// third-party
import { useTranslation } from "react-i18next";

export interface TagProps {
  className?: string;
  tag: TaxonomyType;
  hideCount?: boolean;
  isLoading?: boolean;
  onClick?: (tagName: string) => void;
}

const Tag: FC<TagProps> = ({ className = "", tag, hideCount = false, isLoading = false, onClick = () => { } }) => {
  const { t } = useTranslation();

  return (
    <>
      {isLoading ? <TagsSkeleton /> :
        <Link
          className={`nc-Tag inline-block bg-white hover:bg-neutral-50 text-sm text-neutral-600 dark:text-neutral-300 py-2 px-3 rounded-lg md:py-2.5 md:px-4 dark:bg-neutral-900 ${className}`}
          href={tag.href}
          onClick={() => onClick(tag?.name || "")}
        >
          {`${t((tag?.name || "").replaceAll(" ", "_").replaceAll("-", "_"))}`}
          {!hideCount && (
            <span className="text-xs font-normal"> ({tag.count})</span>
          )}
        </Link>}
    </>
  );
};

export default Tag;
