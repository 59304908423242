import React, { useContext, createContext, ReactNode } from 'react';

// third-party
import { useDispatch, useSelector } from "react-redux";

// project-imports
import { ActionType } from '../constants/actions';
import { login, LoginRequest, LoginResponse, register, RegisterRequest, RegisterResponse, getCheckAuthStatus, postForgotPassword, ForgotPasswordRequest, postResetPassword, ResetPasswordRequest, googleLogin } from 'api/api';
import { showToastAlert } from 'utils/sweetAlert';
import { REAUTH } from 'constants/utils';

// Define the shape of the memmber state
interface MemberState {
    isLoading: boolean;
    currentMember: any; // Replace `any[]` with your actual author type
    newMember: any; // Replace `any[]` with your actual author type
    error: string | null;
    status: string | null;
    loginType: string | null;
}

// Define the shape of the context value
interface MemberContextType extends MemberState {
    loginMember: (member: LoginRequest, type: string) => Promise<void>;
    loginGoogleMember: (payload: any, type: string) => Promise<void>;
    logoutMember: () => Promise<void>;
    registerMember: (member: RegisterRequest) => Promise<void>;
    checkLoginStatus: () => Promise<void>;
    forgotPassword: (payload: ForgotPasswordRequest) => Promise<void>;
    resetPassword: (payload: ResetPasswordRequest) => Promise<void>;
}

// Define props for the provider
interface MemberProviderProps {
    children: ReactNode;
    store: any
}

// Initialize the context
export const MemberContext = createContext<MemberContextType | undefined>(undefined);

export const MemberProvider: React.FC<MemberProviderProps> = ({ children }) => {
    const { GET_MEMBER_LOGIN_BEGIN, GET_MEMBER_LOGIN_SUCCESS, GET_MEMBER_LOGIN_FAILURE, GET_MEMBER_REGISTER_BEGIN, GET_MEMBER_REGISTER_SUCCESS, GET_MEMBER_REGISTER_FAILURE, GET_MEMBER_LOGOUT_BEGIN, GET_MEMBER_LOGOUT_SUCCESS, GET_MEMBER_LOGOUT_FAILURE, CHECK_MEMBER_LOGIN_STATUS_BEGIN, CHECK_MEMBER_LOGIN_STATUS_SUCCESS, CHECK_MEMBER_LOGIN_STATUS_FAILURE, MEMBER_FORGOT_PASSWORD_BEGIN, MEMBER_FORGOT_PASSWORD_SUCCESS, MEMBER_FORGOT_PASSWORD_FAILURE, MEMBER_RESET_PASSWORD_BEGIN, MEMBER_RESET_PASSWORD_SUCCESS, MEMBER_RESET_PASSWORD_FAILURE } = ActionType;
    // Reading state from store
    const state = useSelector((state: { member: MemberState }) => state.member);

    // Reading dispatch from Store
    const dispatch = useDispatch();

    const loginMember = async (member: LoginRequest, type: string): Promise<void> => {
        dispatch({ type: GET_MEMBER_LOGIN_BEGIN, payload: true });

        try {
            let response: LoginResponse = {} as any; // Replace with the actual API response type
            response = await login(member) || {} as any;
            dispatch({ type: GET_MEMBER_LOGIN_SUCCESS, payload: { response, type } });
            showToastAlert('Success', 'Login is successful', { icon: "success" });

        } catch (error: any) {
            console.error("Error Member Login:", error);
            dispatch({ type: GET_MEMBER_LOGIN_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Please try again later.', { icon: "error" });
        }
    };

    const loginGoogleMember = async (payload: any, type: string): Promise<void> => {
        dispatch({ type: GET_MEMBER_LOGIN_BEGIN, payload: true });

        try {
            let response: LoginResponse = {} as any; // Replace with the actual API response type
            response = await googleLogin(payload) || {} as any;
            dispatch({ type: GET_MEMBER_LOGIN_SUCCESS, payload: { response, type } });
            showToastAlert('Success', 'Login through Google is successful', { icon: "success" });

        } catch (error: any) {
            console.error("Error Member Google Login:", error);
            dispatch({ type: GET_MEMBER_LOGIN_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Please try again later.', { icon: "error" });
        }
    };

    const logoutMember = async (): Promise<void> => {
        dispatch({ type: GET_MEMBER_LOGOUT_BEGIN, payload: true });

        try {
            let response: LoginResponse = {} as any; // Replace with the actual API response type
            // response = await login(member) || {} as any;
            dispatch({ type: GET_MEMBER_LOGOUT_SUCCESS, payload: response });
            showToastAlert('Success', 'You have logged out successfully', { icon: "success" });

        } catch (error: any) {
            console.error("Error Member Logout:", error);
            dispatch({ type: GET_MEMBER_LOGOUT_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Please try again later.', { icon: "error" });
        }
    };

    const registerMember = async (member: RegisterRequest): Promise<void> => {
        dispatch({ type: GET_MEMBER_REGISTER_BEGIN, payload: true });

        try {
            let response: RegisterResponse = {} as any; // Replace with the actual API response type
            response = await register(member) || {} as any;
            dispatch({ type: GET_MEMBER_REGISTER_SUCCESS, payload: response });
            showToastAlert('Success', 'Congratulations, your account has been successfully created', { icon: "success" });
        } catch (error: any) {
            console.error("Error Member Register:", error);
            dispatch({ type: GET_MEMBER_REGISTER_FAILURE, payload: error?.message });
            showToastAlert('Error', 'We are unable to register your accounts. Please try again later.', { icon: "error" });
        }
    };

    const checkLoginStatus = async (): Promise<void> => {
        dispatch({ type: CHECK_MEMBER_LOGIN_STATUS_BEGIN, payload: true });

        try {
            let response: LoginResponse = {} as any; // Replace with the actual API response type
            response = await getCheckAuthStatus() || {} as any;
            dispatch({ type: CHECK_MEMBER_LOGIN_STATUS_SUCCESS, payload: { response, type: REAUTH } });
            // showToastAlert('Success', 'Congratulations, your account has been successfully created', { icon: "success" });
        } catch (error: any) {
            console.error("Error Member Login check:", error);
            dispatch({ type: CHECK_MEMBER_LOGIN_STATUS_FAILURE, payload: error?.message });
            showToastAlert('Error', 'Your session has timed out. Please login again!', { icon: "error" });
        }
    };

    const forgotPassword = async (payload: ForgotPasswordRequest): Promise<void> => {
        dispatch({ type: MEMBER_FORGOT_PASSWORD_BEGIN, payload: true });

        try {
            let response: string = "" as any; // Replace with the actual API response type
            response = await postForgotPassword(payload) || "" as any;
            dispatch({ type: MEMBER_FORGOT_PASSWORD_SUCCESS, payload: response });
            showToastAlert('Success', response, { icon: "success" });
        } catch (error: any) {
            console.error("Error Member Forgot password:", error);
            let message = error && error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : error && error.response && error.response.data ? error.response.data : error.message || error;
            dispatch({ type: MEMBER_FORGOT_PASSWORD_FAILURE, payload: message || error?.message });
            showToastAlert('Error', message || 'An error occurred while forgot password. Please try again or contact support.', { icon: "error" });
        }
    };

    const resetPassword = async (payload: ResetPasswordRequest): Promise<void> => {
        dispatch({ type: MEMBER_RESET_PASSWORD_BEGIN, payload: true });

        try {
            let response: string = "" as any; // Replace with the actual API response type
            response = await postResetPassword(payload) || "" as any;
            dispatch({ type: MEMBER_RESET_PASSWORD_SUCCESS, payload: response });
            showToastAlert('Success', response, { icon: "success" });
        } catch (error: any) {
            console.error("Error Member Forgot password:", error);
            let message = error && error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : error && error.response && error.response.data ? error.response.data : error.message || error;
            dispatch({ type: MEMBER_RESET_PASSWORD_FAILURE, payload: message || error?.message });
            showToastAlert('Error', message || 'An error occurred while password reset. Please try again or contact support.', { icon: "error" });
        }
    };

    return (
        <MemberContext.Provider value={{ ...state, loginMember, loginGoogleMember, registerMember, logoutMember, checkLoginStatus, forgotPassword, resetPassword }
        }>
            {children}
        </MemberContext.Provider>
    );
};

// Custom hook for using the context
export const useMemberContext = (): MemberContextType => {
    const context = useContext(MemberContext);
    if (!context) {
        throw new Error("useMemberContext must be used within a MemberProvider");
    }
    return context;
};
