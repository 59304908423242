import React, { useEffect, useState } from "react";

// third-party
import { useLocation } from "react-router-dom";

// project-imports
import NcImage from "components/NcImage/NcImage";
import SingleHeader from "app/(singles)/SingleHeader";
import Layout from "../layout";
import { PostDataType } from "data/types";
import { useCategoryContext } from "context/categoryContext";
import { useAuthorContext } from "context/authorContext";
import { usePostContext } from "context/postContext";
import SingleHeaderSkeleton from "components/Skeleton/SingleHeaderSkeleton";
import LoadingVideo from "components/LoadingVideo/LoadingVideo";
import { DEMO_POSTS_MAIN } from "data/posts";
import { useMemberContext } from "context/memberContext";
import { GoogleAnalyticsEnum, pageView } from "utils/googleAnalytics";

const PageSingle = () => {

  // local stat 
  const [post, setPost] = useState<PostDataType>();

  const location = useLocation();
  const { currentMember } = useMemberContext();
  const currentUserId = currentMember && (currentMember.id || "");

  // read store state
  const { isLoading: isCatLoading, categories } = useCategoryContext();
  const { isLoading: isAuthorLoading, authors } = useAuthorContext();
  const { getAllCommentsByPostId, isLoading: isPostLoading, posts } = usePostContext();
  const isLoading = isCatLoading || isAuthorLoading || isPostLoading;

  useEffect(() => {
    try {
      const pathName = decodeURIComponent(window.location.pathname);
      const lastItem: string = pathName.substring(pathName.lastIndexOf('/') + 1);

      if (lastItem) {
        pageView(lastItem, GoogleAnalyticsEnum.POST_DETAIL_PAGE_VIEW);

        const allPosts = DEMO_POSTS_MAIN(posts || [], categories || [], authors || []);
        const filteredPosts: PostDataType[] = allPosts.filter((item) => (item.title || "").toLowerCase().replaceAll("?", "").replaceAll(",", "").replaceAll("’", " ").replaceAll(":", "").replaceAll(".", "").trim() === (lastItem || "").replaceAll("-", " ").toLowerCase().trim());
        if (filteredPosts && filteredPosts.length) {

          const postCopy = { ...filteredPosts[0] };

          if (postCopy.id) {
            getAllCommentsByPostId(currentUserId, postCopy.id);
          }

          setPost(postCopy);
        }
      }
    } catch (error: any) {
      console.log("57:Error while updating the single post:", error?.message);
      throw error;
    }
  }, [location, posts]);

  return (
    <div id="article">
      <Layout post={post} isLoading={isLoading}>
        <div className={`nc-PageSingle pt-8 lg:pt-16`} id="article">
          {isLoading &&
            <LoadingVideo />
          }
          <header className="container rounded-xl">
            <div className="max-w-screen-md mx-auto">
              {isLoading ? <SingleHeaderSkeleton /> :
                <SingleHeader post={post} />}
            </div>
          </header>

          {/* FEATURED IMAGE */}
          {isLoading ?
            <div className="container w-full max-w-[1024px] my-10 h-[750px] bg-slate-200 rounded-lg animate-pulse"></div> :
            <NcImage
              alt="single"
              containerClassName="container my-10 sm:my-12"
              className="w-full rounded-xl"
              src={post ? post.featuredImage : "https://images.pexels.com/photos/2662116/pexels-photo-2662116.jpeg"}
              width={1260}
              height={750}
              sizes="(max-width: 1024px) 100vw, 1280px"
            />}
        </div>
      </Layout>
    </div>
  );
};

export default PageSingle;
