import React, { FC } from "react";

// project-imports
import CardCategory1 from "components/CardCategory1/CardCategory1";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import { DEMO_CATEGORIES } from "data/taxonomies";
import { GoogleAnalyticsEnum, triggerFunction } from "utils/googleAnalytics";
import { TaxonomyType } from "data/types";

// third-party
import { useTranslation } from "react-i18next";

const categoriesDemo: TaxonomyType[] = DEMO_CATEGORIES.filter(
  (_, i) => i > 4 && i < 10
);
export interface WidgetCategoriesProps {
  className?: string;
  categories?: TaxonomyType[];
  isLoading?: boolean;
}

const WidgetCategories: FC<WidgetCategoriesProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  categories = categoriesDemo,
  isLoading = false,
}) => {
  const { t } = useTranslation();

  const handleWidgetCategoriesClick = (widgetName: string) => {
    triggerFunction(GoogleAnalyticsEnum.HOME, GoogleAnalyticsEnum.SECTION_LATEST_POST_PAGE + "-" + GoogleAnalyticsEnum.WIDGET_CATEGORY_CARD_CLICK + "-" + widgetName)
  }

  return (
    <div
      className={`nc-WidgetCategories rounded-3xl  overflow-hidden ${className}`}
    >
      <WidgetHeading1
        title={`✨ ${t("trending_topic")}`}
        viewAll={{ label: "", href: "" }}
        isLoading={isLoading}
      />
      <div className="flow-root">
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
          {categories && categories.length ?
            [...(categories || [])].sort((a, b) => (b?.count || 0) - (a?.count || 0)).filter((_, i) => i < 5).map((category) => (
              <CardCategory1
                className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                key={category.id}
                taxonomy={category}
                size="normal"
                isLoading={isLoading}
                onClick={handleWidgetCategoriesClick}
              />
            ))
            :
            <span className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700">{t("no_data_found")}</span>
          }
        </div>
      </div>
    </div>
  );
};

export default WidgetCategories;
