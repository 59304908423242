import React, { FC } from "react";

// project-imports
import { TaxonomyType } from "data/types";
import CardCategory1 from "components/CardCategory1/CardCategory1";
import NcModal from "components/NcModal/NcModal";
import Button from "components/Button/Button";

// third-party
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

export interface ModalCategoriesProps {
  categories: TaxonomyType[];
  selectedId?: string;
  onClick?: (id: string) => void;
}

const ModalCategories: FC<ModalCategoriesProps> = ({ categories, onClick = () => { }, selectedId }) => {

  const { t } = useTranslation();
  const renderModalContent = (closeModal?: () => void) => {
    return (
      <div className="grid gap-6 sm:grid-cols-2 sm:py-2 md:gap-8 md:grid-cols-3 lg:grid-cols-4 xl:md:grid-cols-5">
        {categories && [...categories].sort((a,b) => (b.count || 0) - (a.count || 0)).filter((item) => item.count !== 0).map((cat) => (
          <CardCategory1 key={cat.id} taxonomy={cat} size="normal" onClick={() => { closeModal && closeModal(); onClick((cat.id || "").toString()); }} />
        ))}
      </div>
    );
  };

  return (
    <div className="nc-ModalCategories">
      <NcModal
        renderTrigger={(openModal) => (
          <Button
            pattern="third"
            fontSize="text-sm font-medium"
            onClick={openModal}
          >
            <div>
              <span>{t("other_categories")}</span>
            </div>
            <ChevronDownIcon
              className="w-4 h-4 ml-2 -mr-1"
              aria-hidden="true"
            />
          </Button>
        )}
        modalTitle={t("Categories_Title")}
        renderContent={(closeModal?: () => void) => renderModalContent(closeModal)}
        isOpenProp={selectedId ? false : undefined}
      />
    </div>
  );
};

export default ModalCategories;
