import React, { FC } from "react";

// project-imports
import NcImage from "components/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import Link from "components/Link";
import CardCategory1Skeleton from "components/Skeleton/CardCategory1Skeleton";

// third-party
import { useTranslation } from "react-i18next";

export interface CardCategory1Props {
  className?: string;
  taxonomy: TaxonomyType;
  size?: "large" | "normal";
  isLoading?: boolean;
  onClick?: (name: string) => void;
}

const CardCategory1: FC<CardCategory1Props> = ({
  className = "",
  size = "normal",
  taxonomy,
  isLoading = false,
  onClick = () => { },
}) => {
  const { t } = useTranslation();
  const { count, name, href = "/", thumbnail } = taxonomy;
  return (
    <>
      {isLoading ? <CardCategory1Skeleton /> :
        <Link
          href={href}
          className={`nc-CardCategory1 flex items-center ${className}`}
          onClick={() => onClick(name)}
        >
          <NcImage
            alt=""
            containerClassName={`relative flex-shrink-0 ${size === "large" ? "w-20 h-20" : "w-12 h-12"
              } rounded-lg mr-4 overflow-hidden`}
            src={thumbnail || ""}
            fill
            className="object-cover"
            sizes="80px"
          />
          <div>
            <h2
              className={`${size === "large" ? "text-lg" : "text-base"
                } nc-card-title text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold`}
            >
              {name ? t((name || "").replaceAll(" ", "_").replaceAll("-", "_")) : ""}
            </h2>
            <span
              className={`${size === "large" ? "text-sm" : "text-xs"
                } block mt-[2px] text-neutral-500 dark:text-neutral-400`}
            >
              {count} {t("articles")}
            </span>
          </div>
        </Link>
      }
    </>
  );
};

export default CardCategory1;
